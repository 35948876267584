import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledLoader = styled.div`
  position: ${(props) => (props.fullScreen ? "fixed" : "absolute")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.background || "rgba(255, 255, 255, 0.6)"};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
`

const StyledProgress = styled(CircularProgress)`
  svg {
    color: ${({ theme, color }) => color || theme.colors.blueHealth};
  }
`

export default function Loader({ show, fullScreen, background, color }) {
  return show ? (
    <StyledLoader fullScreen={fullScreen} background={background}>
      <StyledProgress color={color} />
    </StyledLoader>
  ) : null
}

Loader.propTypes = {
  /**
   Shows or hides the loader
   */
  show: PropTypes.bool.isRequired,
  /**
   Makes the loader fullscreen
   */
  fullScreen: PropTypes.bool,
  /**
   Sets the loader color
   */
  color: PropTypes.string,
  /**
   Sets the background color
   */
  background: PropTypes.string,
}

Loader.defaultProps = {
  fullScreen: false,
}
