import React from "react"

import { Modal, Text } from "../../../components/atoms"

import StyledConfirmationModal from "./ConfirmationModal.style"

export default function ConfirmationModal({ showConfirmation, handleCloseModal }) {
  return (
    <Modal open={!!showConfirmation} handleClose={handleCloseModal} showClose={false} timed>
      <StyledConfirmationModal>
        <Text color="blueHealth" bold size="xxxl" as="h2">
          {showConfirmation}
        </Text>
      </StyledConfirmationModal>
    </Modal>
  )
}
