import styled from "styled-components"

const StyledAddButton = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.blueHealth};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fonts.xs.size};
  cursor: pointer;
  transition: opacity 0.3s;
  svg {
    fill: ${({ theme }) => theme.colors.blueHealth};
    margin-right: 0.5rem;
  }
  &:hover {
    opacity: 0.8;
  }
`

export default StyledAddButton
