import styled from "styled-components"

const StyledTimeInput = styled.input`
  width: 6rem;
  border: 1px solid ${({ theme }) => theme.colors.brey};
  border-radius: 6px;
  font-size: ${({ theme }) => theme.fonts.m.size};
  padding: 0.75rem 1.5rem;
  transition: all 0.3s;
  &::placeholder {
    font-family: "Founders Grotesk";
    font-weight: ${({ theme }) => theme.fonts.light};
  }
  &.value,
  &:focus,
  &:hover {
    border-color: ${({ theme, error }) => (error ? theme.colors.red : theme.colors.blueHealth)};
    background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
    outline: none;
  }
  &:disabled {
    opacity: 0.2;
    border: 1px solid ${({ theme }) => theme.colors.brey};
    background-color: transparent;
    & + label {
      opacity: 0.2;
      font-weight: ${({ theme }) => theme.fonts.light};
    }
  }
`

export default StyledTimeInput
