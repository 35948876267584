import { rgba } from "polished"
import styled from "styled-components"

import { centered } from "../../styles/utils/mixins"

const StyledNewDoctor = styled.div`
  margin-top: 0.5rem;
  position: relative;
  .form-container-area {
    ${centered("column")}
    .form-container {
      width: 100%;
      max-width: 19.5rem;
      text-align: center;
      .logo {
        margin-bottom: 4.5rem;
      }
      .title {
        margin-bottom: 1.5rem;
        position: relative;
        .back-link {
          position: absolute;
          top: 0;
          left: -4rem;
          svg {
            width: 12px;
          }
        }
      }
      .form-steps {
        display: flex;
        justify-content: space-between;
        margin-top: 2.25rem;
        margin-bottom: 3rem;
        & > div {
          width: 48%;
          padding: 1rem 0;
          cursor: pointer;
          &:focus {
            outline: none;
          }
          .inner-tab {
            height: 0.125rem;
          }
          &:first-child {
            .inner-tab {
              background-color: ${({ theme, step }) => (step === 1 ? theme.colors.blueHealth : rgba(theme.colors.blueHealth, 0.29))};
            }
          }
          &:last-child {
            .inner-tab {
              background-color: ${({ theme, step }) => (step === 2 ? theme.colors.blueHealth : rgba(theme.colors.blueHealth, 0.29))};
            }
          }
        }
      }
      .profile-image {
        width: 8.25rem;
        height: 8.25rem;
        object-fit: cover;
        border-radius: 50%;
        display: block;
        margin: auto;
        margin-bottom: 2.5rem;
      }
      .form-column {
        .gender-options {
          width: 100%;
          .option {
            width: 33.33333%;
            label {
              width: 100%;
              display: block;
            }
          }
        }
        .next-button {
          margin-top: 2.5rem;
        }
      }
    }
  }
`

export default StyledNewDoctor
