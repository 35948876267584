import React from "react"
import PropTypes from "prop-types"

import StyledAddButton from "./AddButton.style"
import { ReactComponent as PlusCircle } from "../../../resources/svg/plus-circle.svg"

export default function AddButton({ label, onClick, className }) {
  return (
    <StyledAddButton onClick={onClick} className={className}>
      <PlusCircle />
      {label}
    </StyledAddButton>
  )
}

AddButton.prototypes = {
  /**
   The button text
   */
  label: PropTypes.string.isRequired,
  /**
   Click handler
   */
  onClick: PropTypes.func,
}
