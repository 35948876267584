import styled, { css } from "styled-components"
import { centered } from "../../../styles/utils/mixins"

const secondaryStyle = css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blueHealth};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blueHealth};
  &:hover {
    opacity: 1;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.blueHealth};
  }
  &:disabled {
    cursor: default;
    opacity: 0.2;
    &:hover {
      opacity: 0.2;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blueHealth};
    }
  }
`

const StyledButton = styled.button`
  font-size: ${({ theme }) => theme.fonts.l.size};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.blueHealth};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.regular};
  cursor: pointer;
  ${centered()};
  height: 2.5rem;
  width: 100%;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    cursor: default;
    opacity: 0.2;
    &:hover {
      opacity: 0.2;
    }
  }
  ${({ secondary }) => secondary && secondaryStyle}
`

export default StyledButton
