import { rgba } from "polished"
import styled from "styled-components"

const StyledNotifications = styled.div`
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 4rem;
  }
  .notifications-container {
    display: flex;
    justify-content: center;
    margin: auto;
    .notification-list {
      width: 20rem;
      margin-right: 1.5rem;
      .notification-item {
        display: flex;
        padding: 1.5rem 2rem;
        border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.blueSky, 0.3)};
        cursor: pointer;
        .name {
          margin-bottom: 0.25rem;
        }
        &:hover,
        &.selected {
          background-color: ${({ theme }) => rgba(theme.colors.blueSky, 0.1)};
        }
        .dot-container {
          margin-left: auto;
          display: flex;
          align-items: center;
          .item-dot {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            &.is-new {
              background-color: ${({ theme }) => theme.colors.red};
            }
          }
        }
        &:first-child {
          border-radius: 2rem 0 0 0;
        }
        &:last-child {
          border-bottom: none;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .notification-detail-container {
    height: 46.5rem;
    max-width: 40rem;
    flex: 1;
    border-radius: 0 2.25rem 2.25rem 0;
    padding: 2rem 1.5rem;
    background-color: ${({ theme }) => rgba(theme.colors.blueSky, 0.26)};
    .select-notification {
      margin-top: 1.5rem;
    }
    .notification-header {
      .notification-item {
        display: flex;
        img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 0.5rem;
        }
        .name {
          margin-bottom: 0.25rem;
        }
      }
      .date-container {
        margin-left: 2rem;
        display: flex;
        align-items: center;
      }
      .delete-appointment {
        margin-left: auto;
        background-color: ${({ theme }) => theme.colors.decline};
        border-radius: 6px;
        font-weight: ${({ theme }) => theme.fonts.regular};
        font-size: ${({ theme }) => theme.fonts.xl.size};
        padding: 0.5rem 3rem;
        cursor: pointer;
      }
    }
    .notification-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      .notification-title {
        margin-bottom: 1.5rem;
        width: 28rem;
        text-align: center;
      }
      .appointment-date {
        width: 13rem;
        text-align: center;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
      }
      .notification-footer {
        width: 20rem;
        text-align: center;
      }
      .rating {
        margin-top: 4rem;
        margin-bottom: 4rem;
        svg:not(:last-child) {
          margin-right: 0.25rem;
        }
      }
      .notification-body {
        width: 28rem;
        line-height: ${({ theme }) => theme.fonts.m.line};
      }
    }
  }
`

export const StyledDeleteNotificationModal = styled.div`
  padding: 0.5rem;
  max-width: 20rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    text-align: center;
    max-width: 11rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

export const StyledConfirmationModal = styled.div`
  padding: 0.5rem;
  max-width: 20rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    text-align: center;
    max-width: 11rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

export default StyledNotifications
