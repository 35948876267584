import React, { Suspense } from "react"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "./styles/GlobalStyle"
import theme from "./styles/theme"
import Router from "./Router"
import Loader from "./components/atoms/Loader"
import UserProvider from "./providers/UserProvider"

function App() {
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Suspense fallback={<Loader fullScreen show />}>
          <Router />
        </Suspense>
      </ThemeProvider>
    </UserProvider>
  )
}

export default App
