import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import StyledDeleteAppointmentModal from "./DeleteAppointmentModal.style"
import { Modal, Text, Button, Loader } from "../../../components/atoms"
import ClinicService from "../../../services/Clinic"

export default function DeleteAppointmentModal({ appointment, clinic, handleCloseModal, handleDeleteAppointment, isPreferred }) {
  const { t } = useTranslation()
  const clinicService = new ClinicService()
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteButton = async () => {
    setIsLoading(true)
    try {
      if (isPreferred) {
        await clinicService.cancelAppointment(clinic, appointment)
      } else {
        await clinicService.deleteAppointment(clinic, appointment)
      }
      handleDeleteAppointment()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Modal open={!!appointment} showClose={false} handleClose={handleCloseModal}>
      <StyledDeleteAppointmentModal>
        <Loader show={isLoading} />
        <Text color="blueHealth" bold size="xxxl" as="h2">
          {t("pages.requests.deleteTitle")}
        </Text>
        <Text color="blueHealth">{t("pages.requests.deleteText")}</Text>
        <div className="modal-buttons">
          <Button secondary onClick={handleDeleteButton}>
            {t("pages.requests.confirmDelete")}
          </Button>
          <Button onClick={handleCloseModal}>{t("pages.requests.no")}</Button>
        </div>
      </StyledDeleteAppointmentModal>
    </Modal>
  )
}
