import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useHistory, Link } from "react-router-dom"
import qs from "qs"

import StyledDoctors, { StyledConfirmationModal } from "./Doctors.style"
import ClinicService from "../../services/Clinic"
import { Text, SearchInput, Loader, Button, Modal } from "../../components/atoms"
import { DoctorCard } from "../../components/molecules"
import { useDispatchAuth, useAuth } from "../../providers/UserProvider"
import paths from "../../utils/routePaths"

export default function Doctors() {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatchAuth = useDispatchAuth()
  const { selectedClinic } = useAuth()

  const clinicService = new ClinicService()

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const { state } = location

  const { newDoctor, doctorDeleted } = state || {}

  const [isLoading, setIsLoading] = useState(true)
  const [doctorsList, setDoctorsList] = useState([])
  const [unfiltered, setUnfiltered] = useState([])
  const [search, setSearch] = useState([])
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const handleRouterUpdate = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify(
        {
          ...query,
          ...value,
        },
        { addQueryPrefix: true }
      ),
    })
  }

  const handleCloseModal = () => {
    setShowConfirmation(false)
    setShowDeleteConfirmation(false)
  }

  useEffect(async () => {
    if (selectedClinic) {
      handleRouterUpdate({ center: selectedClinic })
    } else if (query.center) {
      dispatchAuth({
        type: "SELECT_CLINIC",
        payload: query.center,
      })
    } else {
      history.replace(paths.CENTERS)
    }

    if (newDoctor) {
      setShowConfirmation(true)
    }
    if (doctorDeleted) {
      setShowDeleteConfirmation(true)
    }

    if (selectedClinic || query.center) {
      const doctorsData = await clinicService.getDoctors(selectedClinic || query.center)
      setDoctorsList(doctorsData)
      setUnfiltered(doctorsData)
      setIsLoading(false)
    }
  }, [])

  const handleSearch = (event) => {
    const newSearch = event.target.value
    setSearch(newSearch)
    setDoctorsList(unfiltered.filter((item) => item.fullname.toLowerCase().includes(newSearch.toLowerCase())))
  }

  return (
    <StyledDoctors>
      <div className="cards-container">
        <div className="top-section">
          <div className="title-search">
            <Text as="h1" className="title" size="xxxl" bold>
              {t("pages.doctors.doctors")}
            </Text>
            <div className="search-input">
              <SearchInput label={t("pages.doctors.searchLabel")} handleSearch={handleSearch} value={search} />
            </div>
          </div>
          <div className="add-doctor">
            <Link to={`${paths.NEW_DOCTOR}?center=${selectedClinic}`}>
              <Button secondary>{t("pages.doctors.addDoctor")}</Button>
            </Link>
          </div>
        </div>
        <div className="doctors-list">
          {isLoading ? (
            <Loader show />
          ) : doctorsList.length === 0 ? (
            <Text size="xxl" className="no-results" bold>
              {t("pages.doctors.noResults")}
            </Text>
          ) : (
            doctorsList.map((doctor) => <DoctorCard doctor={doctor} key={doctor.id} clinic={selectedClinic} />)
          )}
        </div>
      </div>
      {showConfirmation && (
        <Modal open={showConfirmation} handleClose={handleCloseModal} showClose={false} timed>
          <StyledConfirmationModal>
            <Text color="blueHealth" bold size="xxxl" as="h2">
              {t("pages.doctors.doctorAdded")}
            </Text>
            <Text color="blueHealth" size="m">
              {t("pages.doctors.addedMessage")}
            </Text>
          </StyledConfirmationModal>
        </Modal>
      )}
      {showDeleteConfirmation && (
        <Modal open={showDeleteConfirmation} handleClose={handleCloseModal} showClose={false} timed>
          <StyledConfirmationModal>
            <Text color="blueHealth" bold size="xxxl" as="h2">
              {t("pages.doctors.doctorDeleted")}
            </Text>
          </StyledConfirmationModal>
        </Modal>
      )}
    </StyledDoctors>
  )
}
