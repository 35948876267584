import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, Link, useHistory } from "react-router-dom"
import { format, parseJSON } from "date-fns"

import { Text, DisplayDate } from "../../components/atoms"
import { DataField } from "../../components/molecules"
import AgreeRequestModal from "../Requests/AgreeRequestModal/AgreeRequestModal"
import CancelAppointmentModal from "../Requests/CancelAppointmentModal/CancelAppointmentModal"
import DeleteAppointmentModal from "../Requests/DeleteAppointmentModal/DeleteAppointmentModal"
import StyledRequestDetail from "./RequestDetail.Style"
import noImage from "../../resources/img/no-image.png"
import { ReactComponent as Back } from "../../resources/svg/back.svg"
import paths from "../../utils/routePaths"
import { capitalize } from "../../utils/functions"
import { useAuth } from "../../providers/UserProvider"

export default function RequestDetail() {
  const { t } = useTranslation(["translation", "lang", "countries"])
  const location = useLocation()
  const history = useHistory()
  const [showAgreeModal, setShowAgreeModal] = useState(undefined)
  const [showCancelModal, setShowCancelModal] = useState(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(undefined)
  const [preferredDoctor, setPreferredDoctor] = useState(undefined)
  const { state } = location
  const { selectedClinic } = useAuth()
  const { request, status, clinic, doctors } = state || {}
  const { selected_date, pass } = request
  useEffect(async () => {
    if (!state || !selectedClinic) {
      history.replace(paths.CENTERS)
    }
    if (request.preferred_appointment) {
      const findDoctor = doctors.find((item) => item.value === request.preferred_appointment.doctor_ref)
      setPreferredDoctor(findDoctor?.label)
    }
  }, [])

  const formatDate = (date) => {
    return format(parseJSON(date), "dd/MM/yyyy 'at' HH:mm")
  }

  const handleCloseModal = () => {
    setShowAgreeModal(undefined)
    setShowCancelModal(undefined)
    setShowDeleteModal(undefined)
  }

  const handleConfirmModal = () => {
    setShowAgreeModal(undefined)
    setShowCancelModal(undefined)
    setShowDeleteModal(undefined)
    history.replace(paths.REQUESTS)
  }

  const handleButton = () => {
    if (status === 0) {
      setShowAgreeModal(request)
    } else if (status === 1) {
      setShowAgreeModal(request)
    } else if (status === 2) {
      setShowCancelModal(request._id)
    } else {
      setShowDeleteModal(request._id)
    }
  }

  const statuses = {
    0: t("pages.requests.agree"),
    1: t("pages.requests.pendingEdit"),
    2: t("pages.requests.cancel"),
    3: t("pages.requests.delete"),
  }
  return (
    <StyledRequestDetail>
      <Text as="h1" className="title" size="xxxl" bold>
        {t("pages.requests.requests")}
      </Text>
      <div className="name-container">
        <Link to={paths.REQUESTS}>
          <Back />
        </Link>
        <Text bold color="blueHealth" size="xxl">
          {request.patient_ref?.first_name} {request.patient_ref?.last_name}
        </Text>
      </div>
      <img src={request.patient_ref?.avatar_url || noImage} alt={request.patient_ref?.first_name} className="profile-image" />
      {preferredDoctor ? (
        request.availableDates ? (
          <div className="request-buttons preferred">
            <button type="button" className="card-button delete" onClick={() => setShowDeleteModal(request._id)}>
              {t("pages.requests.delete")}
            </button>
            <button type="button" className="card-button" onClick={handleButton}>
              {statuses[status]}
            </button>
          </div>
        ) : (
          <div className="request-buttons">
            <button type="button" className="card-button delete" onClick={() => setShowDeleteModal(request._id)}>
              {t("pages.requests.delete")}
            </button>
          </div>
        )
      ) : (
        <div className="request-buttons">
          <button type="button" className="card-button" onClick={handleButton}>
            {statuses[status]}
          </button>
        </div>
      )}
      {/* <div className='request-buttons'>
        <Button onClick={handleButton}>{statuses[status]}</Button>
      </div> */}
      {request.appointmentDates?.length > 0 && (
        <div className="appointment-dates">
          {request.appointmentDates.map((date, index) => (
            <Text color="blueHealth" size="xxl" bold key={`${date}-${index}`}>
              {formatDate(date)}
            </Text>
          ))}
        </div>
      )}
      <div className="request-data">
        <div className="request-information">
          <Text bold size="xl" as="h4">
            {t("pages.requests.personalInformation")}
          </Text>
          <DataField label={t("pages.requests.fullName")} data={`${request.patient_ref?.first_name} ${request.patient_ref?.last_name}`} />
          {request.patient_ref?.gender >= 0 && (
            <DataField label={t("pages.requests.gender")} data={t(`common.gender.${request.patient_ref?.gender}`)} />
          )}
          {request.patient_ref?.nationality && (
            <DataField
              label={t("pages.requests.nationality")}
              data={request.patient_ref?.nationality.map((item) => t(`countries:${item}`))}
            />
          )}
          {request.patient_ref?.languages && request.patient_ref?.languages.length > 0 && (
            <DataField label={t("pages.requests.language")} data={request.patient_ref?.languages.map((lang) => t(`lang:${lang}`))} />
          )}
          {request.patient_ref?.mobile && <DataField label={t("pages.requests.mobile")} data={request.patient_ref?.mobile} />}
          {request.patient_ref?.email && <DataField label={t("pages.requests.email")} data={request.patient_ref?.email} />}
        </div>
        <div className="request-necessity">
          <Text bold size="xl" as="h4">
            {t("pages.requests.necessity")}
          </Text>
          <DataField
            label={preferredDoctor ? t("pages.requests.preferredDoctor") : t("pages.requests.doctor")}
            data={preferredDoctor || request.appointMentDoctor?.fullname}
          />
          <DataField label={t("pages.requests.speciality")} data={capitalize(request.speciality)} />
          <DataField
            label={t("pages.requests.date")}
            data={
              pass ? (
                <span>{format(new Date(selected_date), "dd-MM-yyyy HH:mm")}</span>
              ) : (
                request.date_range.map((date, index) => <DisplayDate date={date} long key={`${date}-${index}`} />)
              )
            }
          />
        </div>
      </div>
      {showAgreeModal && (
        <AgreeRequestModal
          request={showAgreeModal}
          doctors={doctors}
          handleCloseModal={handleCloseModal}
          handleAgreeRequest={handleConfirmModal}
          clinic={clinic}
        />
      )}
      <CancelAppointmentModal
        appointment={showCancelModal}
        clinic={clinic}
        handleCloseModal={handleCloseModal}
        handleCancelAppointment={handleConfirmModal}
      />
      <DeleteAppointmentModal
        appointment={showDeleteModal}
        clinic={clinic}
        handleCloseModal={handleCloseModal}
        handleDeleteAppointment={handleConfirmModal}
        isPreferred={!!preferredDoctor}
      />
    </StyledRequestDetail>
  )
}
