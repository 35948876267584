export default {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  REGISTER_MANAGER: "/register-manager",
  CENTERS: "/centers",
  REQUESTS: "/requests",
  PROFILE: "/profile",
  EDIT_PROFILE: "/profile/edit",
  DOCTORS: "/doctors",
  NEW_DOCTOR: "/doctors/new-doctor",
  NOTIFICATIONS: "/notifications",
}
