import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import styled from "styled-components"

const StyledSwitch = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.blueHealth};
    text-transform: uppercase;
    font-family: "Founders Grotesk", sans-serif;
    font-weight: ${({ theme }) => theme.fonts.light};
    font-size: ${({ theme }) => theme.fonts.xs.size};
  }
`

const StyledSwitchControl = styled(Switch)`
  &.MuiSwitch-root {
    width: 48px;
    height: 24px;
    padding: 0;
  }
  .MuiSwitch-switchBase {
    padding: 2px;
    color: ${({ theme }) => theme.colors.brey};
    &.Mui-checked {
      transform: translateX(24px);
      color: ${({ theme }) => theme.colors.white};
      + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.colors.blueHealth};
        border: 2px solid ${({ theme }) => theme.colors.blueHealth};
        opacity: 1;
      }
    }
  }
  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
  }
  .MuiSwitch-track {
    border-radius: 13px;
    border: 2px solid ${({ theme }) => theme.colors.brey};
    background-color: transparent;
  }
`

export default StyledSwitch

export { StyledSwitchControl }
