import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import StyledCancelAppointmentModal from "./CancelAppointmentModal.style"
import { Modal, Text, Button, Loader } from "../../../components/atoms"
import ClinicService from "../../../services/Clinic"

export default function CancelAppointmentModal({ appointment, clinic, handleCloseModal, handleCancelAppointment }) {
  const { t } = useTranslation()
  const clinicService = new ClinicService()
  const [isLoading, setIsLoading] = useState(false)

  const handleCancelButton = async () => {
    setIsLoading(true)
    try {
      await clinicService.cancelAppointment(clinic, appointment)
      handleCancelAppointment()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Modal open={!!appointment} showClose={false} handleClose={handleCloseModal}>
      <StyledCancelAppointmentModal>
        <Loader show={isLoading} />
        <Text color="blueHealth" bold size="xxxl" as="h2">
          {t("pages.requests.cancelTitle")}
        </Text>
        <Text color="blueHealth">{t("pages.requests.cancelText")}</Text>
        <div className="modal-buttons">
          <Button secondary onClick={handleCancelButton}>
            {t("pages.requests.confirmCancel")}
          </Button>
          <Button onClick={handleCloseModal}>{t("pages.requests.no")}</Button>
        </div>
      </StyledCancelAppointmentModal>
    </Modal>
  )
}
