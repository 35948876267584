import instance from "./Base"
import { errorHandler } from "./utils"

class Manager {
  me = async () => {
    let userData
    try {
      userData = await instance.get("/managers/me")
    } catch (error) {
      errorHandler(error)
    }
    return userData?.data
  }

  editProfile = async (data) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key])
    })
    let userData
    try {
      userData = await instance.put("/managers/me", formData, config)
    } catch (error) {
      errorHandler(error)
    }
    return userData?.data
  }

  managedClinics = async () => {
    let clinicsData
    try {
      clinicsData = await instance.get("/managers/clinics")
    } catch (error) {
      errorHandler(error)
    }
    return clinicsData?.data
  }

  addFCMToken = async (token) => {
    try {
      await instance.put("/managers/fcmToken", { token })
    } catch (error) {
      errorHandler(error)
    }
  }
}

export default Manager
