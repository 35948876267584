import styled from "styled-components"

import { centered } from "../../styles/utils/mixins"

const StyledRegisterManager = styled.div`
  position: relative;
  .form-container-area {
    height: 100vh;
    ${centered("column")}
    .form-container {
      width: 100%;
      max-width: 19.5rem;
      text-align: center;
      .logo {
        margin-bottom: 4.5rem;
      }
      .title {
        margin-bottom: 9rem;
        position: relative;
      }
      .image-input {
        margin-bottom: 2.5rem;
      }
      .manager-email {
        text-align: left;
        color: ${({ theme }) => theme.colors.blueHealth};
        font-size: ${({ theme }) => theme.fonts.m.size};
        border: 1px solid ${({ theme }) => theme.colors.blueHealth};
        border-radius: 6px;
        padding: 0.75rem 1rem;
        background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
        cursor: default;
      }
    }
  }
`

export default StyledRegisterManager
