import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams, useHistory } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import DoctorSchedules from "./DoctorSchedules/DoctorSchedules"
import { Loader, Text, Switch, Modal, Button } from "../../components/atoms"
import { DataField } from "../../components/molecules"
import StyledDoctorDetail, { StyledDeleteDoctorModal } from "./DoctorDetail.style"
import ClinicService from "../../services/Clinic"
import paths from "../../utils/routePaths"
import useQueryString from "../../hooks/useQueryString"
import noImage from "../../resources/img/no-image.png"
import { capitalize, weekdays } from "../../utils/functions"
import { useDispatchAuth, useAuth } from "../../providers/UserProvider"
import { ReactComponent as Back } from "../../resources/svg/back.svg"
import { ReactComponent as Pencil } from "../../resources/svg/pencil.svg"

export default function DoctorDetail() {
  const { t } = useTranslation(["translation", "lang", "countries"])
  const { id, clinic } = useParams()
  const [doctor, setDoctor] = useState({})
  const [clinicData, setClinicData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const dispatchAuth = useDispatchAuth()
  const { selectedClinic } = useAuth()
  const clinicService = new ClinicService()
  const [query, updateQuery] = useQueryString()
  const { tab } = query
  const tabs = [t("pages.doctors.information"), t("pages.doctors.schedules")]
  const [currenTab, setCurrentTab] = useState(0)
  const [appointmentsSwitch, setAppointmentsSwitch] = useState(false)
  const [workingTimes, setWorkingTimes] = useState([])
  const [contactMethods, setContactMethods] = useState(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const history = useHistory()

  const handleCloseModal = () => {
    setShowDeleteModal(false)
  }

  useEffect(async () => {
    if (!selectedClinic) {
      dispatchAuth({
        type: "SELECT_CLINIC",
        payload: clinic,
      })
    }
    try {
      const doctorResponse = await clinicService.getDoctorDetail(clinic, id)
      const clinicResponse = await clinicService.getClinic(clinic)
      setDoctor(doctorResponse)
      setClinicData(clinicResponse)
      setAppointmentsSwitch(doctorResponse.active)
      if (doctorResponse.contact) {
        setContactMethods(Object.values(doctorResponse.contact))
      }
      setWorkingTimes(
        weekdays.reduce((workDays, day) => {
          if (doctorResponse.working_time[day].length > 0) {
            doctorResponse.working_time[day].forEach((range) => {
              workDays.push(`${capitalize(day.substring(0, 3))} ${range.from} to ${range.to}`)
            })
          }
          return workDays
        }, [])
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (tab) {
      const idxTab = tabs.findIndex((tabItem) => tabItem === tab)
      setCurrentTab(idxTab !== -1 ? idxTab : 0)
    }
  }, [tab])

  const handleTabChange = (index, lastIndex) => {
    if (index === lastIndex || isLoading) {
      return false
    }
    updateQuery({ tab: tabs[index] })
    setCurrentTab(index)
    return true
  }

  const handleSwitch = async (event) => {
    setIsLoading(true)
    try {
      const response = await clinicService.editDoctor(clinic, id, {
        active: event.target.checked,
      })
      setIsLoading(false)
      setAppointmentsSwitch(response.active)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDeleteDoctor = async () => {
    setIsLoading(true)
    handleCloseModal()
    try {
      await clinicService.deleteDoctor(clinic, id)
      setIsLoading(false)
      history.push({
        pathname: paths.DOCTORS,
        state: {
          doctorDeleted: true,
        },
      })
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDelete = () => {
    setShowDeleteModal(true)
  }

  return (
    <StyledDoctorDetail>
      <Loader show={isLoading} />
      <Text as="h1" className="title" size="xxxl" bold>
        {t("pages.doctors.doctors")}
      </Text>
      <div className="name-container">
        <Link to={paths.DOCTORS}>
          <Back />
        </Link>
        <Text bold color="blueHealth" size="xxl">
          {doctor.fullname}
        </Text>
      </div>
      <Tabs selectedTabClassName="selected-tab" className="tabs" selectedIndex={currenTab} onSelect={handleTabChange}>
        <TabList className="tab-list">
          {tabs.map((tabItem) => (
            <Tab key={tabItem} className="tab">
              {tabItem}
            </Tab>
          ))}
        </TabList>
        <div className="tabs-content">
          <TabPanel>
            <div className="doctor-information">
              <img src={doctor.avatar_url || noImage} alt={doctor.fullname} className="profile-image" />
              <Switch
                label={t("pages.doctors.takingAppointments")}
                checked={appointmentsSwitch}
                handleChange={handleSwitch}
                className="appointments-switch"
              />
              <Link
                to={{
                  pathname: `${paths.DOCTORS}/${id}/clinic/${clinic}/edit`,
                  state: { doctor },
                }}
              >
                <div className="edit-profile">
                  <Pencil />
                  <Text uppercase size="xs" as="span">
                    {t("pages.doctors.editProfile")}
                  </Text>
                </div>
              </Link>
            </div>
            <div className="doctor-data">
              <div className="personal-information">
                <Text bold size="xl" as="h4">
                  {t("pages.doctors.personalInformation")}
                </Text>
                <DataField label={t("pages.doctors.fullName")} data={doctor.fullname} />
                <DataField label={t("pages.doctors.gender")} data={t(`common.gender.${doctor.gender}`)} />
                <DataField label={t("pages.requests.nationality")} data={doctor.nationalities?.map((item) => t(`countries:${item}`))} />
                <DataField label={t("pages.requests.language")} data={doctor.languages?.map((lang) => t(`lang:${lang}`))} />
                <DataField label={t("pages.doctors.mobile")} data={doctor.phone} />
                <DataField label={t("pages.doctors.email")} data={doctor.email} />
              </div>
              <div className="professional-information">
                <Text bold size="xl" as="h4">
                  {t("pages.doctors.professionalInformation")}
                </Text>
                <DataField label={t("pages.doctors.workingTime")} data={workingTimes} />
                <DataField label={t("pages.doctors.collegeNumber")} data={doctor.medical_number} />
                <DataField label={t("pages.doctors.yearsExperience")} data={doctor.years_experience} />
                <DataField label={t("pages.doctors.speciality")} data={doctor.speciality?.map((e) => capitalize(e))} />
                {/* PENDING */}
                <DataField label={t("pages.doctors.modality")} data={doctor.modality} />
                {/* PENDING */}
                <DataField label={t("pages.doctors.contactClinic")} data={contactMethods} />
                <DataField label={t("pages.doctors.medicalCenter")} data={clinicData.company_name} />
                <DataField label={t("pages.doctors.medicalCenterAddress")} data={clinicData.address} />
              </div>
            </div>
            <button onClick={handleDelete} className="delete-doctor" type="button">
              <Text color="red" size="s" as="span" className="delete-doctor">
                {t("pages.doctors.delete")}
              </Text>
            </button>
          </TabPanel>
          <TabPanel>
            <DoctorSchedules clinic={clinic} id={id} />
          </TabPanel>
        </div>
      </Tabs>
      <Modal open={showDeleteModal} showClose={false} handleClose={handleCloseModal}>
        <StyledDeleteDoctorModal>
          <Loader show={isLoading} />
          <Text color="blueHealth" bold size="xxxl" as="h2">
            {t("pages.doctors.deleteTitle")}
          </Text>
          <Text color="blueHealth">{t("pages.doctors.deleteText")}</Text>
          <div className="modal-buttons">
            <Button secondary onClick={handleDeleteDoctor}>
              {t("common.buttons.yes")}
            </Button>
            <Button onClick={handleCloseModal}>{t("common.buttons.no")}</Button>
          </div>
        </StyledDeleteDoctorModal>
      </Modal>
    </StyledDoctorDetail>
  )
}
