/* eslint-disable no-console */
import React from "react"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import ReactSelect, { components } from "react-select"

import StyledSelect, { customStyles, StyledIndicator } from "./Select.style"
import { ReactComponent as ArrowDown } from "../../../resources/svg/arrow-down.svg"

const DropdownIndicator = (props) => {
  const { isFocused } = props
  return (
    <components.DropdownIndicator {...props}>
      <StyledIndicator isFocused={isFocused}>
        <ArrowDown />
      </StyledIndicator>
    </components.DropdownIndicator>
  )
}

export default function Select({
  options,
  name,
  defaultValue = null,
  control,
  rules,
  disabled,
  label,
  error,
  className,
  searchable = false,
  onClick,
}) {
  const handleOnchange = (onChange, option) => {
    onChange(option.value)
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      disabled={disabled}
      defaultValue={defaultValue}
      render={({ onChange }) => (
        <StyledSelect className={className} onClick={onClick}>
          <ReactSelect
            defaultValue={options.find((option) => option.value === defaultValue)}
            isSearchable={searchable}
            options={options}
            isDisabled={disabled}
            onChange={(option) => handleOnchange(onChange, option)}
            placeholder={label}
            styles={customStyles}
            components={{ DropdownIndicator }}
            menuPortalTarget={document.body}
          />
          {error && <span className="error">{error}</span>}
        </StyledSelect>
      )}
    />
  )
}

export function ControlledSelect({ options, value, onchange, disabled, label, error, className, searchable = false, defaultValue }) {
  return (
    <StyledSelect className={className}>
      <ReactSelect
        value={value}
        isSearchable={searchable}
        options={options}
        isDisabled={disabled}
        onChange={(option) => onchange(option)}
        placeholder={label}
        styles={customStyles}
        defaultValue={defaultValue}
        components={{ DropdownIndicator }}
        menuPortalTarget={document.body}
      />
      {error && <span className="error">{error}</span>}
    </StyledSelect>
  )
}

Select.propTypes = {
  /**
   Options to display
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
}
