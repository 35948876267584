import styled from "styled-components"

const StyledDoctors = styled.div`
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 63rem;
    margin: auto;
    .top-section {
      margin-bottom: 2rem;
      display: flex;
      align-items: flex-end;
      width: 100%;
      .title-search {
        flex: 1;
        .title {
          text-align: center;
          margin-bottom: 1rem;
        }
        .search-input {
          display: flex;
          justify-content: center;
          margin-bottom: 1.5rem;
        }
      }
      .add-doctor {
        margin-bottom: 2rem;
        margin-right: 1.5rem;
        button {
          padding: 0 0.5rem;
        }
      }
    }
    .doctors-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 63rem;
      min-width: 10rem;
      min-height: 10rem;
      margin: auto;
      position: relative;
      .no-results {
        margin: auto;
      }
    }
  }
`

export const StyledConfirmationModal = styled.div`
  padding: 0.5rem;
  max-width: 20rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    text-align: center;
    max-width: 11rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

export default StyledDoctors
