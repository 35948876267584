import { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import qs from "qs"

export default function useQueryString() {
  const [query, setQuery] = useState({})
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setQuery(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })
    )
  }, [location])

  const updateQuery = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify(
        {
          ...query,
          ...value,
        },
        { addQueryPrefix: true }
      ),
    })
  }

  return [query, updateQuery]
}
