import app from "firebase/compat/app"
import "firebase/auth"
import "firebase/compat/auth"
import { getMessaging, getToken } from "firebase/messaging"

const { REACT_APP_FIREBASECONFIG: fbConfig, REACT_APP_V_API_ID_KEY } = process.env

export class Firebase {
  init
  app
  auth
  messaging

  constructor() {
    this.init = app.initializeApp(JSON.parse(fbConfig))

    this.app = app.app()
    this.auth = app.auth()
    this.messaging = getMessaging(this.init)

    this.verifyNotificationPermission()
  }

  async getToken() {
    const token = await getToken(this.messaging, {
      vapidKey: REACT_APP_V_API_ID_KEY,
    }).catch((error) => {
      console.error(error)
    })
    return token
  }

  async verifyNotificationPermission() {
    const permission = await Notification.requestPermission()
    if (permission === "granted") console.info("Notification permission granted.")
  }
}

const firebase = new Firebase()
export default firebase
