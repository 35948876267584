import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"

import StyledContactField, { StyledModal } from "./ContactField.style"
import { Modal, Text, Button, ButtonFormModal, Input } from "../../../components/atoms"

export default function ContactField({ contactPhone = "", contactEmail = "", setContactData }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { register, reset, handleSubmit, watch } = useForm({
    defaultValues: {
      contactPhone,
      contactEmail,
    },
  })

  const handleModal = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = (values) => {
    setContactData({
      contactPhone: values.contactPhone,
      contactEmail: values.contactEmail,
    })
    setOpen(false)
  }

  useEffect(() => {
    reset({
      contactPhone,
      contactEmail,
    })
  }, [reset, contactPhone, contactEmail])

  return (
    <StyledContactField>
      {contactEmail || contactPhone ? (
        <ButtonFormModal onClick={handleModal}>
          {contactPhone}
          {contactEmail && contactPhone && ","} {contactEmail}
        </ButtonFormModal>
      ) : (
        <Button secondary onClick={handleModal} className="empty-button">
          {t("pages.doctors.contact")}
        </Button>
      )}
      <Modal open={open} handleClose={handleClose}>
        <StyledModal>
          <div className="modal-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Text bold color="blueHealth" size="xxxl" as="h3">
                {t("pages.doctors.contact")}
              </Text>
              <Text bold size="xl" className="subtitle">
                {t("pages.doctors.writeContact")}
              </Text>
              <div className="contacts">
                <Input
                  label={t("pages.doctors.contactPhone")}
                  name="contactPhone"
                  inputRef={register}
                  defaultValue={contactPhone}
                  watch={watch}
                />
                <Input
                  label={t("pages.doctors.contactEmail")}
                  name="contactEmail"
                  inputRef={register}
                  defaultValue={contactEmail}
                  watch={watch}
                />
              </div>
              <Button className="ok-button" type="button" onClick={handleSubmit(onSubmit)}>
                {t("common.buttons.ok")}
              </Button>
            </form>
          </div>
        </StyledModal>
      </Modal>
    </StyledContactField>
  )
}
