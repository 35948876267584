import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useAuth } from "../../providers/UserProvider"
import firebase from "../../services/firebase"

import StyledLogin, { StyledConfirmationModal } from "./Login.style"
import { Button, Input, PasswordInput, Text, Loader, Modal } from "../../components/atoms"
import circularText from "../../resources/img/text-circle.png"
import { ReactComponent as BlueLogo } from "../../resources/svg/logo-blue.svg"
import paths from "../../utils/routePaths"

export default function Login() {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { register, handleSubmit, errors, watch } = useForm()
  const history = useHistory()
  const location = useLocation()
  const { user, loading } = useAuth()

  const { state } = location
  const { confirmed } = state || {}

  useEffect(() => {
    if (confirmed) {
      setShowConfirmation(true)
    }
  }, [])

  useEffect(() => {
    if (user) {
      history.replace("/")
    }
    return () => {}
  }, [user])

  const handleCloseModal = () => {
    setShowConfirmation(false)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    setLoginError(null)
    const { email, password } = data
    try {
      const { user: fbUser } = await firebase.auth.signInWithEmailAndPassword(email, password)
      const { claims } = await fbUser.getIdTokenResult()
      if (claims.role !== "owner" && claims.role !== "manager") {
        setLoginError(t("pages.login.wrongRole"))
        firebase.auth.signOut()
      }
    } catch (err) {
      const { code } = err
      if (code === "auth/wrong-password" || code === "auth/user-not-found") {
        setLoginError(t("pages.login.wrongCredentials"))
      } else {
        setLoginError(t("pages.login.unknowError"))
      }
    }

    setLoading(false)
  }

  return (
    <StyledLogin>
      <Loader fullScreen show={loading} />
      <img src={circularText} alt="doc in the city" className="circular-text" />
      <div className="login-form-area">
        <BlueLogo />
        <div className="login-form-container">
          <div className="form-title">
            <Text bold size="xxl">
              {t("pages.login.login")}
            </Text>
            <div className="user-options">
              <Text uppercase color="blueHealth" size="xxl" bold as="span">
                {t("pages.login.manager")}
              </Text>
              <Text uppercase color="blueHealth" size="xxl" bold as="span" className="disabled">
                {t("pages.login.doctor")}
              </Text>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              label={t("forms.fields.email")}
              name="email"
              inputRef={register({
                required: t("forms.errors.emailRequired"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("forms.errors.emailInvalid"),
                },
              })}
              error={errors.email && errors.email.message}
              watch={watch}
            />
            <PasswordInput
              label={t("forms.fields.password")}
              name="password"
              inputRef={register({
                required: t("forms.errors.passwordRequired"),
              })}
              error={(errors.password && errors.password.message) || loginError}
              watch={watch}
            />
            <Button type="submit">{t("pages.login.login")}</Button>
            <Link to={paths.FORGOT_PASSWORD}>
              <Text uppercase size="xs" className="forgot-password">
                {t("pages.login.forgotPassword")}
              </Text>
            </Link>
            <Loader show={isLoading} />
          </form>
        </div>
      </div>
      <div className="login-image">
        <Text uppercase color="white" size="xl" className="slogan" bold>
          {t("pages.login.slogan")}
        </Text>
      </div>
      {showConfirmation && (
        <Modal open={showConfirmation} handleClose={handleCloseModal} showClose={false} timed>
          <StyledConfirmationModal>
            <Text color="blueHealth" bold size="xxxl" as="h2">
              {t("pages.login.welcome")}
            </Text>
          </StyledConfirmationModal>
        </Modal>
      )}
    </StyledLogin>
  )
}
