import styled from "styled-components"

import noImage from "../../../resources/img/no-image.png"
import droplet from "../../../resources/svg/droplet.svg"
import { newRequestStyle } from "../../atoms/NavItem/NavItem.style"

const StyledNav = styled.div`
  overflow-y: auto;
  width: 11rem;
  background-color: ${({ theme }) => theme.colors.blueHealth};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .nav-items {
    margin-top: 4.5rem;
    .logo {
      display: block;
      margin: auto;
      margin-bottom: 5rem;
    }
    & > a {
      margin-bottom: 5rem;
    }
  }
  .profile-section {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ newRequest }) =>
      newRequest &&
      `
      position: relative;
      ${newRequestStyle}
    `}

    .clinics-list {
      margin-bottom: 3rem;
      display: block;
      background-color: ${({ theme }) => theme.colors.brownPerson};
      width: 103px;
      min-height: 91px;
      border-radius: 0.5rem;
      padding: 1rem;
      transition: all 0.3s;
      svg {
        display: block;
        margin: auto;
      }
      span {
        text-align: center;
        display: block;
      }
      &:hover {
        opacity: 0.9;
      }
    }
    .profile {
      margin-bottom: 3rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .logout {
      opacity: 0.5;
      cursor: pointer;
      transition: all 0.3s;
      display: block;
      &:hover {
        opacity: 1;
      }
    }
  }
`

export const ProfileImage = styled.div`
  width: 25px;
  height: 30px;
  background-image: url(${({ image }) => image || noImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  mask-image: url(${droplet});
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;
  margin-bottom: 0.5rem;
`

export const StyledLogoutModal = styled.div`
  padding: 0.5rem;
  width: 20rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    text-align: center;
    max-width: 11rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

export default StyledNav
