import styled from "styled-components"

const StyledSelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.blueHealth};
  background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
  border-radius: 6px;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.blueHealth};
  }
`

export default StyledSelectedItem
