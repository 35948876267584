import React from "react"

import StyledSelectedItem from "./SelectedItem.style"
import Text from "../Text/Text"
import { ReactComponent as CloseIcon } from "../../../resources/svg/clear.svg"

export default function SelectedItem({ item, onClick }) {
  return (
    <StyledSelectedItem>
      <Text bold>{item}</Text>
      <CloseIcon onClick={onClick} />
    </StyledSelectedItem>
  )
}
