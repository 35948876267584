import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { Text } from "../../atoms"
import { ReactComponent as CalendarIcon } from "../../../resources/svg/calendar.svg"
import { ReactComponent as GlobeIcon } from "../../../resources/svg/globe.svg"
import { ReactComponent as Star } from "../../../resources/svg/star.svg"
import paths from "../../../utils/routePaths"
import noImage from "../../../resources/img/no-image.png"
import { capitalize } from "../../../utils/functions"
import StyledDoctorCard from "./DoctorCard.style"

export default function DoctorCard({ doctor, clinic }) {
  const { t } = useTranslation()
  const {
    id,
    fullname,
    medical_number: medicalNumber,
    avatar_url: avatarUrl,
    working_time: workingTime = [],
    languages = [],
    rating = {},
    speciality,
  } = doctor
  const { avg } = rating

  const workingDays = Object.keys(workingTime).filter((day) => workingTime[day].length > 0)

  return (
    <Link
      to={{
        pathname: `${paths.DOCTORS}/${id}/clinic/${clinic}`,
      }}
    >
      <StyledDoctorCard>
        <div className="card-header">
          <img src={avatarUrl || noImage} alt={fullname} className="doctor-image" />
          <div className="info-text">
            <div className="doctor-name">
              <Text as="h4" bold size="xl">
                {fullname}
              </Text>
              {!!avg && (
                <div className="average-reviews">
                  <Star />
                  <Text color="blueHealth" size="s">
                    {avg}/5
                  </Text>
                </div>
              )}
            </div>
            <Text size="m" color="blueHealth" className="specialities">
              {capitalize(speciality)}
            </Text>
            <Text size="s">{medicalNumber}</Text>
          </div>
        </div>
        <div className="card-body">
          <div className="date">
            <CalendarIcon />
            {workingDays.length >= 3 ? (
              <Text size="s" color="blueHealth" className="several-items">
                {t("pages.doctors.severalDays")}
              </Text>
            ) : (
              <ul>
                {workingDays.map((day) => (
                  <Text as="li" key={day} size="s" color="blueHealth">
                    {t(`pages.doctors.${day}`)}
                  </Text>
                ))}
              </ul>
            )}
          </div>
          {languages.length > 0 && (
            <div className="languages">
              <GlobeIcon />
              {languages.length >= 3 ? (
                <Text size="s" color="blueHealth" className="several-items">
                  {t("pages.doctors.severalLanguages")}
                </Text>
              ) : (
                <ul>
                  {languages.map((language) => (
                    <Text as="li" key={language} size="s" color="blueHealth">
                      {language}
                    </Text>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </StyledDoctorCard>
    </Link>
  )
}
