import styled from "styled-components"

const StyledNationalityField = styled.div`
  position: relative;
  ${({ isDisabled }) => isDisabled && "opacity: 0.2; pointer-events: none;"}
  .empty-button {
    height: 2.625rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .error {
    position: absolute;
    text-transform: uppercase;
    bottom: -1.125rem;
    left: 0;
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fonts.xs.size};
  }
`

export const StyledModal = styled.div`
  width: 46rem;
  .modal-content {
    width: 19.5rem;
    min-height: 31.5rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 2.5rem;
    margin-top: 3.5rem;
    h3 {
      text-align: center;
      margin-bottom: 3rem;
    }
    .subtitle {
      margin-bottom: 4rem;
      text-align: center;
    }
    .countries {
      margin-bottom: 3rem;
    }
    .ok-button {
      margin-top: auto;
    }
  }
`

export default StyledNationalityField
