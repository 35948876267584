import axios from "axios"

import { errorHandler } from "./utils"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
})

class Invitation {
  getInvitation = async (id) => {
    let response
    try {
      response = await instance.get(`/invitations/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return response?.data
  }

  confirmManager = async (token, data) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key])
    })
    let response
    try {
      response = await instance.post(`/invitations/manager/${token}`, formData, config)
    } catch (error) {
      errorHandler(error)
    }
    return response?.data
  }
}

export default Invitation
