import instance from "./Base"
import { errorHandler } from "./utils"

class Clinic {
  getClinic = async (id) => {
    let requestData
    try {
      requestData = await instance.get(`/clinics/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return requestData?.data
  }

  getDoctors = async (id) => {
    let requestData
    try {
      requestData = await instance.get(`/clinics/${id}/doctors`)
    } catch (error) {
      errorHandler(error)
    }
    return requestData?.data
  }

  getDoctorDetail = async (clinicId, id) => {
    let requestData
    try {
      requestData = await instance.get(`/clinics/${clinicId}/doctors/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return requestData?.data
  }

  createDoctor = async (clinic, data) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === "object" && key !== "avatar") {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    })
    let response
    try {
      response = await instance.post(`/clinics/${clinic}/create/doctor`, formData, config)
    } catch (error) {
      if (error.response) {
        return { data: error.response.data, status: error.response.status }
      }
      if (error.request) {
        return error.request
      }
      return error.message
    }
    return response?.data
  }

  editDoctor = async (clinic, id, data) => {
    let response

    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      }

      const formData = new FormData()
      Object.keys(data).forEach((key) => {
        if (typeof data[key] === "object" && key !== "avatar") {
          formData.append(key, JSON.stringify(data[key]))
        } else {
          formData.append(key, data[key])
        }
      })

      response = await instance.put(`/clinics/${clinic}/doctors/${id}`, formData, config)
    } catch (error) {
      if (error.response) {
        return { data: error.response.data, status: error.response.status }
      }
      if (error.request) {
        return error.request
      }
      return error.message
    }
    return response?.data
  }

  deleteDoctor = async (clinic, id) => {
    let response
    try {
      response = await instance.delete(`/clinics/${clinic}/doctors/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return response?.data
  }

  getRequests = async (status, id, query) => {
    let requestsData
    try {
      requestsData = await instance.get(`/clinics/${id}/requests/status/${status}${query || ""}`)
    } catch (error) {
      errorHandler(error)
    }
    return requestsData?.data
  }

  getAppointments = async (clinic, id) => {
    let appointmentsData
    try {
      appointmentsData = await instance.get(`/clinics/${clinic}/doctors/${id}/appointments`)
    } catch (error) {
      errorHandler(error)
    }
    return appointmentsData?.data
  }

  createAppointment = async (id, data) => {
    let requestsData
    try {
      requestsData = await instance.post(`/clinics/${id}/create/appointment`, data)
    } catch (error) {
      errorHandler(error)
    }
    return requestsData?.data
  }

  getAppointmentDetail = async (idClinic, id) => {
    let appointmentData
    try {
      appointmentData = await instance.get(`/clinics/${idClinic}/appointments/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return appointmentData.data
  }

  editAppointment = async (idClinic, id, body) => {
    let appointmentData
    try {
      appointmentData = await instance.put(`/clinics/${idClinic}/appointments/${id}`, body)
    } catch (error) {
      errorHandler(error)
    }
    return appointmentData.data
  }

  cancelAppointment = async (idClinic, id) => {
    let appointmentData
    try {
      appointmentData = await instance.put(`/clinics/${idClinic}/appointments/${id}/cancel`)
    } catch (error) {
      errorHandler(error)
    }
    return appointmentData.data
  }

  deleteAppointment = async (idClinic, id) => {
    let appointmentData
    try {
      appointmentData = await instance.delete(`/clinics/${idClinic}/appointments/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return appointmentData.data
  }

  getManagers = async (id) => {
    let responseData
    try {
      responseData = await instance.get(`clinics/${id}/managers`)
    } catch (error) {
      errorHandler(error)
    }
    return responseData.data
  }

  changeRole = async (clinic, id, role) => {
    let responseData
    try {
      responseData = await instance.put(`clinics/${clinic}/managers/${id}`, {
        role,
      })
    } catch (error) {
      errorHandler(error)
    }
    return responseData.data
  }

  sendInvitations = async (clinic, emails) => {
    let responseData
    try {
      responseData = await instance.post(`clinics/${clinic}/invite/manager`, {
        emails,
      })
    } catch (error) {
      errorHandler(error)
    }
    return responseData.data
  }

  deleteManager = async (clinic, id) => {
    let responseData
    try {
      responseData = await instance.delete(`clinics/${clinic}/managers/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return responseData.data
  }

  getNotifications = async (clinic) => {
    let responseData
    try {
      responseData = await instance.get(`clinics/${clinic}/notifications`)
    } catch (error) {
      errorHandler(error)
    }
    return responseData.data
  }

  getNotificationDetail = async (clinic, id) => {
    let responseData
    try {
      responseData = await instance.get(`clinics/${clinic}/notifications/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return responseData.data
  }

  deleteNotification = async (clinic, id) => {
    let responseData
    try {
      responseData = await instance.delete(`clinics/${clinic}/notifications/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return responseData.data
  }
}

export default Clinic
