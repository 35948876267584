import styled from "styled-components"

const StyledRequests = styled.div`
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .search-input {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  .tabs {
    .tab-list {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 4rem;
      .tab {
        text-transform: uppercase;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.blueHealth};
        font-weight: 500;
        font-size: ${({ theme }) => theme.fonts.xl.size};
        opacity: 0.3;
        transition: all 0.3s;
        &:not(:last-child) {
          margin-right: 2rem;
        }
        &:hover {
          opacity: 1;
        }
        &:focus {
          outline: none;
        }
        &.selected-tab {
          opacity: 1;
          font-size: ${({ theme }) => theme.fonts.xxl.size};
          cursor: default;
        }
      }
    }
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 63rem;
    margin: auto;
    position: relative;
    .no-results {
      margin: auto;
    }
  }
  .load-more {
    width: 11.5rem;
    margin: auto;
    margin-top: 1.5rem;
  }
  .skeleton {
    height: 9.75rem;
    width: 19.5rem;
    margin: 0 0.75rem 2.5rem 0.75rem;
    display: block;
    transform: scale(1, 1);
    box-shadow: 4px 1px 16px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
  }
`

export default StyledRequests
