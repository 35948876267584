import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"

import StyledAvatarUpload from "./AvatarUpload.style"
import { ReactComponent as Camera } from "../../../resources/svg/camera.svg"

export default function AvatarUpload({ avatarURL, isLoading, setImage }) {
  const [preview, setPreview] = useState(avatarURL)
  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      isLoading(true)
      setImage(acceptedFiles[0])
      setPreview(URL.createObjectURL(acceptedFiles[0]))
      isLoading(false)
    } else if (rejectedFiles.length > 0) {
      // TODO -> manage error
    }
  })

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  })

  return (
    <StyledAvatarUpload isDragActive={isDragActive}>
      <div {...getRootProps({ className: "avatar-container" })}>
        <input {...getInputProps()} />
        {preview && <img src={preview} alt="" />}
        <Camera />
      </div>
    </StyledAvatarUpload>
  )
}
