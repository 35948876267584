import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useFieldArray } from "react-hook-form"

import StyledNationalityField, { StyledModal } from "./NationalityField.style"
import { Modal, ControlledSelect as Select, Text, Button, SelectedItem, AddButton, ButtonFormModal } from "../../../components/atoms"
import countriesJSON from "../../../utils/countries.json"

export default function NationalityField({ control, name, error, disabled }) {
  const { t } = useTranslation(["translation", "countries"])
  const [countriesOptions, setCountriesOptions] = useState([])
  const [selected, setSelected] = useState(null)
  const [open, setOpen] = useState(false)
  const [displaySelect, setDisplaySelect] = useState(true)
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })
  const getCountries = () =>
    Object.keys(countriesJSON).map((country) => ({
      value: country,
      label: t(`countries:${country}`),
    }))
  const handleModal = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const onSelect = (value) => {
    setSelected(null)
    setCountriesOptions(countriesOptions.filter((country) => country.value !== value.value))
    append(value)
    setDisplaySelect(false)
  }
  const handleRemoveItem = (idx, value) => {
    remove(idx)
    setCountriesOptions(
      getCountries().filter((country) => fields.find((field) => field.value === country.value && field.value !== value) === undefined)
    )
  }
  const handleAddButton = () => setDisplaySelect(true)

  useEffect(() => {
    setCountriesOptions(getCountries())
  }, [])

  return (
    <StyledNationalityField isDisabled={disabled}>
      {fields.length === 0 ? (
        <Button secondary onClick={handleModal} className="empty-button">
          {t("pages.doctors.nationality")}*
        </Button>
      ) : (
        <ButtonFormModal onClick={handleModal}>{fields.map((field) => field.label).join(", ")}</ButtonFormModal>
      )}
      {error && <span className="error">{error}</span>}
      <Modal open={open} handleClose={handleClose}>
        <StyledModal>
          <div className="modal-content">
            <Text bold color="blueHealth" size="xxxl" as="h3">
              {t("pages.doctors.nationality")}
            </Text>
            <Text bold size="xl" className="subtitle">
              {t("pages.doctors.chooseNationality")}
            </Text>
            <div className="countries">
              {fields.map((field, idx) => (
                <SelectedItem item={field.label} key={field.id} onClick={() => handleRemoveItem(idx, field.value)} />
              ))}
              {displaySelect ? (
                <Select
                  options={countriesOptions}
                  name="country"
                  value={selected}
                  onchange={onSelect}
                  label={t("pages.doctors.nationality")}
                  searchable
                />
              ) : (
                <AddButton label={t("pages.doctors.addCountry")} onClick={handleAddButton} />
              )}
            </div>
            <Button className="ok-button" onClick={handleClose}>
              {t("common.buttons.ok")}
            </Button>
          </div>
        </StyledModal>
      </Modal>
    </StyledNationalityField>
  )
}
