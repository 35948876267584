import styled from "styled-components"

const StyledContactField = styled.div`
  .empty-button {
    height: 2.625rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`

export const StyledModal = styled.div`
  width: 46rem;
  .modal-content {
    width: 19.5rem;
    min-height: 31.5rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 2.5rem;
    margin-top: 3.5rem;
    h3 {
      text-align: center;
      margin-bottom: 3rem;
    }
    .subtitle {
      margin-bottom: 4rem;
      text-align: center;
    }
    .contacts {
      margin-bottom: 3rem;
    }
    .ok-button {
      margin-top: auto;
    }
  }
`

export default StyledContactField
