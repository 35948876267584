import React from "react"

import StyledItemCard from "./ItemCard.style"
import Text from "../Text/Text"

export default function ItemCard({ item, isSelected, handleClick }) {
  return (
    <StyledItemCard onClick={() => handleClick(item.value)} selected={isSelected}>
      <div className="card-content">
        {item.icon}
        <Text color="blueHealth" size="s">
          {item.label}
        </Text>
      </div>
    </StyledItemCard>
  )
}
