import styled, { css } from "styled-components"

const selectedStyle = css`
  background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
  border: 2px solid ${({ theme }) => theme.colors.blueHealth};
`

const StyledItemCard = styled.div`
  width: 6rem;
  height: 6rem;
  background-color: ${({ theme }) => theme.colors.brownPerson};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: border-color 0.3s;
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    svg {
      margin-bottom: 0.5rem;
      fill: ${({ theme }) => theme.colors.blueHealth};
    }
    p {
      text-align: center;
    }
  }
  ${({ selected }) => selected && selectedStyle}
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.blueHealth};
  }
`

export default StyledItemCard
