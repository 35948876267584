import { addMinutes, eachDayOfInterval, format, getDay, getHours, getMinutes, isWithinInterval, parse, parseISO } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { enGB } from "date-fns/locale"

const locales = { en: enGB }

const { REACT_APP_DATES_INTERVAL: datesInterval, REACT_APP_AFTERNOON_START: afternoonStart } = process.env

const YEAR = 2021
const MONTH = 1
const DAY = 1

const morningInterval = {
  start: new Date(YEAR, MONTH, DAY, 0, 0),
  end: new Date(YEAR, MONTH, DAY, afternoonStart, 0),
}

const afternoonInterval = {
  start: new Date(YEAR, MONTH, DAY, afternoonStart, 1),
  end: new Date(YEAR, MONTH, DAY, 23, 59),
}

export const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const dateOption = (day, date) => {
  const [dd, MM, yy] = day.date.split("/")
  const H = getHours(date)
  const mm = getMinutes(date)
  return {
    value: parse(`${dd} ${MM} ${yy} ${H} ${mm} Z`, "dd MM yy H mm X", new Date()).toJSON().replace("Z", ""),
    label: `${dd}/${MM}/${yy} at ${H}:${mm === 0 ? "00" : mm}`,
  }
}

export const formatDateISO = (date, lang) => {
  const zoned = utcToZonedTime(parseISO(date), "Etc/GMT")
  return format(zoned, "dd/MM/yy", { locale: locales[lang] })
}

export const formatDate = (date, lang) => {
  return format(date, "dd/MM/yy", {
    locale: locales[lang],
  })
}

export const capitalize = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getAvailableDates = (doctorTime, dateRange) => {
  const weekdaysList = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

  const workDays = Object.keys(doctorTime).reduce((r, day) => {
    if (doctorTime[day].length > 0) {
      const datesRange = []
      doctorTime[day].forEach((range) => {
        const { from, to } = range
        const fromArr = from.split(":")
        const toArr = to.split(":")
        const fromDate = new Date(YEAR, MONTH, DAY, fromArr[0], fromArr[1])
        const toDate = new Date(YEAR, MONTH, DAY, toArr[0], toArr[1])
        let nextDate = fromDate
        while (nextDate < toDate) {
          datesRange.push(nextDate)
          nextDate = addMinutes(nextDate, datesInterval)
        }
        datesRange.push(nextDate)
      })
      // eslint-disable-next-line no-param-reassign
      r[day] = datesRange
    }
    return r
  }, {})
  const requestDays = dateRange.reduce((r, date) => {
    const { from, to, time_range: timeRange } = date
    const individualDays = eachDayOfInterval({
      start: parseISO(from),
      end: parseISO(to),
    })
    individualDays.forEach((day) => {
      r.push({
        weekday: weekdaysList[getDay(day)],
        date: formatDate(day),
        timeRange,
      })
    })
    return r
  }, [])
  const optionsDates = []
  const now = new Date()
  requestDays.forEach((day) => {
    if (workDays[day.weekday]) {
      workDays[day.weekday].forEach((date) => {
        const time = dateOption(day, date)
        if (new Date(time.value) < now) return
        if (day.timeRange === "morning") {
          if (isWithinInterval(date, morningInterval)) {
            optionsDates.push(time)
          }
        } else if (day.timeRange === "afternoon") {
          if (isWithinInterval(date, afternoonInterval)) {
            optionsDates.push(time)
          }
        } else {
          optionsDates.push(time)
        }
      })
    }
  })
  return optionsDates
}

export const getInputMinutes = (val) => {
  const [str1, str2] = val.split(":")
  const val1 = Number(str1)
  const val2 = Number(str2)

  if (!Number.isNaN(val1) && Number.isNaN(val2)) {
    return val1
  }

  if (!Number.isNaN(val1) && !Number.isNaN(val2)) {
    return val1 * 60 + val2
  }

  return 0
}

export const getDateValues = (date) => {
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
  const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date)

  const hour = date.toLocaleTimeString("es", {
    hour: "2-digit",
    minute: "2-digit",
  })
  return {
    year,
    month: month.length < 2 ? `0${month}` : month,
    day: day.length < 2 ? `0${day}` : day,
    hour,
  }
}

export const getDateAndHour = (date) => {
  const { day, month, year, hour } = getDateValues(date)
  return `${day}/${month}/${year} - ${hour}`
}
