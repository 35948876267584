import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { useLocation, useHistory } from "react-router-dom"
import qs from "qs"

import AgreeRequestModal from "./AgreeRequestModal/AgreeRequestModal"
import CancelAppointmentModal from "./CancelAppointmentModal/CancelAppointmentModal"
import DeleteAppointmentModal from "./DeleteAppointmentModal/DeleteAppointmentModal"
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal"
import StyledRequests from "./Requests.style"
import { Text, SearchInput, Loader, Button } from "../../components/atoms"
import { RequestCard } from "../../components/molecules"
import { useDispatchAuth, useAuth } from "../../providers/UserProvider"
import ClinicService from "../../services/Clinic"
import paths from "../../utils/routePaths"
import { getAvailableDates } from "../../utils/functions"

const itemsPerLoad = 9

export default function Requests() {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatchAuth = useDispatchAuth()
  const { selectedClinic, updatedRequest } = useAuth()

  useEffect(() => {
    dispatchAuth({ type: "CLEAR_NEW_REQUEST", payload: selectedClinic })
  }, [selectedClinic])

  const clinicService = new ClinicService()

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const tabs = [
    { label: t("pages.requests.new"), status: "new" },
    { label: t("pages.requests.pending"), status: "pending" },
    { label: t("pages.requests.approve"), status: "approved" },
    { label: t("pages.requests.past"), status: "past" },
  ]

  const idxTab = tabs.findIndex((tab) => tab.status === query.tab)

  const [isLoading, setIsLoading] = useState(true)
  const [isAgreeing, setIsAgreeing] = useState(null)
  const [isCancelling, setIsCancelling] = useState(null)
  const [isDeleting, setIsDeleting] = useState(null)
  const [isPreferred, setIsPreferred] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(null)
  const [requestsList, setRequestsList] = useState([])
  const [doctorsList, setDoctorsList] = useState([])
  const [totalItems, setTotalItems] = useState(null)
  const [currenTab, setCurrentTab] = useState(idxTab !== -1 ? idxTab : 0)
  const [isEdit, setIsEdit] = useState(false)
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (updatedRequest) {
      if (updatedRequest.status === "completed") setRequestsList(requestsList.filter((request) => request._id !== updatedRequest.id))
      else setRequestsList([...requestsList, updatedRequest])
    }
  }, [updatedRequest])

  const handleRouterUpdate = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify(
        {
          ...query,
          ...value,
        },
        { addQueryPrefix: true }
      ),
    })
  }

  const getRequests = async (status, options) => {
    const { clinicDoctors, resetOffset, clearSearch } = options || {}
    setIsLoading(true)
    const apiData = await clinicService.getRequests(
      status,
      selectedClinic || query.center,
      qs.stringify(
        {
          limit: itemsPerLoad,
          offset: resetOffset ? 0 : offset,
          search: clearSearch ? "" : search,
        },
        { addQueryPrefix: true }
      )
    )
    const { results, total } = apiData || {}
    setTotalItems(total)
    let requestsData
    if (status === tabs[0].status) {
      requestsData = results.map((data) => {
        let availableDates = true
        let preferredDoctor = false
        if (data.preferred_appointment) {
          const selectedDoctor = (clinicDoctors || doctorsList).find((doctor) => doctor.value === data.preferred_appointment.doctor_ref)
          if (selectedDoctor) {
            preferredDoctor = true
            const datesList = getAvailableDates(selectedDoctor.workingTime, data.date_range)
            if (datesList.length === 0) {
              availableDates = false
            }
          }
        }
        return {
          ...data,
          availableDates,
          preferredDoctor,
        }
      })
    } else if (status === tabs[1].status) {
      requestsData = results.map((data) => ({
        ...data.request_ref,
        patient_ref: data.patient_ref,
        appointmentId: data._id,
        appointmentDates: data.dates,
        appointMentDoctor: data.doctor_ref,
      }))
    } else {
      requestsData = results.map((data) => ({
        ...data.request_ref,
        patient_ref: data.patient_ref,
        appointmentId: data._id,
        appointMentDoctor: data.doctor_ref,
        pass: true,
        selected_date: data.selected_date,
      }))
    }
    if (resetOffset) {
      setOffset(itemsPerLoad)
      setRequestsList(requestsData)
    } else {
      setOffset(offset + itemsPerLoad)
      setRequestsList([...requestsList, ...requestsData])
    }
    setIsLoading(false)
  }

  useEffect(async () => {
    if (selectedClinic) {
      handleRouterUpdate({ center: selectedClinic, tab: tabs[0].label })
    } else if (query.center) {
      dispatchAuth({
        type: "SELECT_CLINIC",
        payload: query.center,
      })
      if (idxTab === -1) {
        handleRouterUpdate({ tab: tabs[0].label })
      }
    } else {
      history.replace(paths.CENTERS)
    }

    if (selectedClinic || query.center) {
      const clinicData = await clinicService.getClinic(selectedClinic || query.center)
      const clinicDoctors = clinicData.doctors_ref.map((doctor) => ({
        label: doctor.fullname,
        value: doctor.id,
        workingTime: doctor.working_time,
        speciality: doctor.speciality,
      }))
      setDoctorsList(clinicDoctors)
      if (idxTab !== -1) {
        getRequests(query.tab, { clinicDoctors })
        setCurrentTab(idxTab)
      } else {
        getRequests(tabs[0].status, { clinicDoctors })
      }
    }
  }, [])

  const handleSelectRequestToAgree = (card) => {
    setIsAgreeing(card)
  }

  const handleAgreeRequest = () => {
    setIsAgreeing(null)
    getRequests(tabs[isEdit ? 1 : 0].status, { resetOffset: true })
    setShowConfirmation(t(`pages.requests.${isEdit ? "edited" : "added"}`))
    setIsEdit(false)
  }

  const handleSelectAppointmentToEdit = (appointment) => {
    setIsEdit(true)
    setIsAgreeing(appointment)
  }

  const handleSelectRequestToCancel = (id) => {
    setIsCancelling(id)
  }
  const handleCancelAppointment = async () => {
    setIsCancelling(null)
    setShowConfirmation(t("pages.requests.cancelled"))
    getRequests(tabs[currenTab].status, { resetOffset: true })
  }

  const handleSelectRequestToDelete = (id) => setIsDeleting(id)

  const handleSelectPreferredRequestToDelete = (id) => {
    setIsDeleting(id)
    setIsPreferred(true)
  }

  const handleDeleteAppointment = () => {
    setIsDeleting(null)
    setIsPreferred(null)
    setShowConfirmation(t("pages.requests.deleted"))
    getRequests(tabs[currenTab].status, { resetOffset: true })
  }

  const handleCloseModal = () => {
    setIsCancelling(null)
    setIsDeleting(null)
    setIsAgreeing(null)
    setShowConfirmation(null)
    setIsEdit(false)
  }
  const handleTabChange = (index, lastIndex) => {
    if (index === lastIndex || isLoading) {
      return false
    }
    getRequests(tabs[index].status, { resetOffset: true, clearSearch: true })
    handleRouterUpdate({ tab: tabs[index].status })
    setCurrentTab(index)
    setSearch("")
    return true
  }
  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    getRequests(tabs[currenTab].status, { resetOffset: true })
  }, [search])

  const handleCardClick = (card) => {
    if (currenTab === 0) {
      handleSelectRequestToAgree(card)
    } else if (currenTab === 1) {
      handleSelectAppointmentToEdit(card)
    } else if (currenTab === 2) {
      handleSelectRequestToCancel(card.appointmentId)
    } else {
      handleSelectRequestToDelete(card.appointmentId)
    }
  }

  const fetchMoreData = () => {
    getRequests(tabs[currenTab].status)
  }

  const displayCards = () => (
    <>
      {requestsList.length === 0 && !isLoading ? (
        <div className="cards-container">
          <Text size="xxl" className="no-results" bold>
            {t("pages.requests.noResults")}
          </Text>
        </div>
      ) : (
        <>
          <div className="cards-container">
            <Loader show={isLoading} />
            {requestsList.map((card) => (
              <RequestCard
                status={currenTab}
                key={card._id}
                doctors={doctorsList}
                clinic={selectedClinic}
                request={card}
                onClick={() => handleCardClick(card)}
                onDeletePreferred={() => handleSelectPreferredRequestToDelete(card._id)}
              />
            ))}
          </div>
          {!isLoading && requestsList.length < totalItems && (
            <Button className="load-more" secondary onClick={fetchMoreData}>
              {t("common.buttons.loadMore")}
            </Button>
          )}
        </>
      )}
    </>
  )

  return (
    <StyledRequests id="requests-container">
      <Text as="h1" className="title" size="xxxl" bold>
        {t("pages.requests.requests")}
      </Text>
      <div className="search-input">
        <SearchInput label={t("pages.requests.searchLabel")} handleSearch={handleSearch} value={search} />
      </div>
      <Tabs selectedTabClassName="selected-tab" className="tabs" defaultIndex={currenTab} onSelect={handleTabChange}>
        <TabList className="tab-list">
          {tabs.map((tab) => (
            <Tab key={tab.label} className="tab">
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <div className="tabs-content">
          <TabPanel>
            {/* NEW */}
            {displayCards()}
          </TabPanel>
          <TabPanel>
            {/* PENDING */}
            {displayCards()}
          </TabPanel>
          <TabPanel>
            {/* APPROVED */}
            {displayCards()}
          </TabPanel>
          <TabPanel>
            {/* PAST */}
            {displayCards()}
          </TabPanel>
        </div>
      </Tabs>
      {isAgreeing && (
        <AgreeRequestModal
          request={isAgreeing}
          handleCloseModal={handleCloseModal}
          handleAgreeRequest={handleAgreeRequest}
          doctors={doctorsList}
          clinic={selectedClinic}
        />
      )}
      <CancelAppointmentModal
        appointment={isCancelling}
        clinic={selectedClinic}
        handleCloseModal={handleCloseModal}
        handleCancelAppointment={handleCancelAppointment}
      />
      <DeleteAppointmentModal
        appointment={isDeleting}
        clinic={selectedClinic}
        handleCloseModal={handleCloseModal}
        handleDeleteAppointment={handleDeleteAppointment}
        isPreferred={isPreferred}
      />
      {showConfirmation && <ConfirmationModal showConfirmation={showConfirmation} handleCloseModal={handleCloseModal} />}
    </StyledRequests>
  )
}
