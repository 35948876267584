import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import qs from "qs"

import { ReactComponent as BlueLogo } from "../../resources/svg/logo-blue.svg"
import { ReactComponent as Back } from "../../resources/svg/back.svg"
import paths from "../../utils/routePaths"
import { Button, Input, Text, Loader, Options, PasswordInput, AvatarUpload } from "../../components/atoms"
import NationalityField from "../EditDoctor/NationalityField/NationalityField"
import LanguageField from "../EditDoctor/LanguageField/LanguageField"
import WorkingDaysField from "../EditDoctor/WorkingDaysField/WorkingDaysField"
import SpecialityField from "../EditDoctor/SpecialityField/SpecialityField"
import ModalityField from "../EditDoctor/ModalityField/ModalityField"
import ContactField from "../EditDoctor/ContactField/ContactField"
import StyledNewDoctor from "./NewDoctor.style"
import { useDispatchAuth, useAuth } from "../../providers/UserProvider"
import ClinicService from "../../services/Clinic"

const defaultDays = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
}

export default function NewDoctor() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [contactData, setContactData] = useState({})
  const [workingDays, setWorkingDays] = useState(defaultDays)
  const [step, setStep] = useState(1)
  const location = useLocation()
  const dispatchAuth = useDispatchAuth()
  const { selectedClinic } = useAuth()
  const clinicService = new ClinicService()
  const history = useHistory()
  const [image, setImage] = useState(null)

  const handleSetImage = (imageData) => {
    setImage(imageData)
  }

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const { center } = query

  const genderOptions = [
    { value: 1, label: t("common.gender.1") },
    { value: 2, label: t("common.gender.2") },
    { value: 0, label: t("common.gender.0") },
  ]
  const schema = yup.object().shape({
    fullname: yup.string().required(t("forms.errors.valueRequired")).min(8, t("forms.errors.doctorNameLength")),
    phone: yup.string().required(t("forms.errors.valueRequired")),
    email: yup.string().required(t("forms.errors.valueRequired")).email(t("forms.errors.emailInvalid")),
    password: yup.string().required(t("forms.errors.valueRequired")),
    medical_number: yup.string().required(t("forms.errors.valueRequired")).min(3, t("forms.errors.medicalNumberLength")),
    gender: yup.number().required(t("forms.errors.valueRequired")).typeError(t("forms.errors.valueRequired")),
    years_experience: yup.string().required(t("forms.errors.valueRequired")),
    speciality: yup.array().min(1, t("forms.errors.valueRequired")),
    nationalities: yup.array().min(1, t("forms.errors.valueRequired")),
    languages: yup.array().min(1, t("forms.errors.valueRequired")),
    preferred_contact: yup.array().min(1, t("forms.errors.valueRequired")),
    working_time: yup.object().test("check times", t("forms.errors.valueRequired"), (value) => {
      if (!value) {
        return false
      }
      let isValid = false
      Object.keys(value).forEach((item) => {
        if (value[item].length > 0) {
          isValid = true
        }
      })
      return isValid
    }),
  })

  useEffect(() => {
    if (center && !selectedClinic) {
      dispatchAuth({
        type: "SELECT_CLINIC",
        payload: center,
      })
    }
  }, [])

  const { register, handleSubmit, errors, control, getValues, setValue, trigger, watch, setError } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values) => {
    const formValues = {
      ...values,
      languages: values.languages.map((item) => item.value),
      nationalities: values.nationalities.map((item) => item.value),
      preferred_contact: values.preferred_contact.map((item) => item.value),
      contact: {
        phone: contactData.contactPhone,
        email: contactData.contactEmail,
      },
    }
    if (image) {
      formValues.avatar = image
    }
    setIsLoading(true)
    try {
      const response = await clinicService.createDoctor(center, formValues)
      setIsLoading(false)
      if (response.status === 409) {
        setError("email", {
          type: "manual",
          message: t("forms.errors.emailUsed"),
        })
        setStep(1)
      } else {
        history.push({
          pathname: paths.DOCTORS,
          state: {
            newDoctor: true,
          },
        })
      }
    } catch (error) {
      setIsLoading(false)
    }
  }
  const handlePrevTab = () => {
    setStep(1)
  }
  const handleNextTab = async () => {
    const result = await trigger(["fullname", "gender", "nationalities", "languages", "phone", "email", "password"])
    if (result) {
      setStep(2)
    }
  }
  return (
    <StyledNewDoctor step={step}>
      <Loader show={isLoading} />
      <div className="form-container-area">
        <div className="form-container">
          <BlueLogo className="logo" />
          <Text color="blueHealth" uppercase size="xxl" bold className="title">
            {t("pages.doctors.addDoctor")}
            <Link to={paths.DOCTORS} className="back-link">
              <Back />
            </Link>
          </Text>
          <Text bold size="xl">
            {step === 1 ? t("pages.doctors.personalInformation") : t("pages.doctors.professionalInformation")}
          </Text>
          <div className="form-steps">
            <div onClick={handlePrevTab} onKeyDown={handlePrevTab} role="button" tabIndex={0} aria-label="step 1">
              <div className="inner-tab" />
            </div>
            <div onClick={handleNextTab} onKeyDown={handleNextTab} role="button" tabIndex={0} aria-label="step 2">
              <div className="inner-tab" />
            </div>
          </div>
          <AvatarUpload isLoading={setIsLoading} setImage={handleSetImage} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {step === 1 && (
              <div className="form-column">
                <Input
                  label={`${t("pages.doctors.fullName")}*`}
                  name="fullname"
                  inputRef={register}
                  error={errors.fullname && errors.fullname.message}
                  watch={watch}
                />
                <Options
                  options={genderOptions}
                  name="gender"
                  control={control}
                  className="gender-options"
                  error={errors.gender && errors.gender.message}
                  defaultValue={Number(getValues("gender"))}
                  watch={watch}
                />
                <NationalityField control={control} name="nationalities" error={errors.nationalities && errors.nationalities.message} />
                <LanguageField control={control} name="languages" error={errors.languages && errors.languages.message} />
                <Input
                  label={`${t("pages.doctors.mobile")}*`}
                  name="phone"
                  inputRef={register}
                  error={errors.phone && errors.phone.message}
                  watch={watch}
                />
                <Input
                  label={`${t("pages.doctors.email")}*`}
                  name="email"
                  inputRef={register}
                  error={errors.email && errors.email.message}
                  watch={watch}
                />
                <PasswordInput
                  label={`${t("forms.fields.password")}*`}
                  name="password"
                  inputRef={register}
                  error={errors.password && errors.password.message}
                  watch={watch}
                />
                <Button onClick={handleNextTab} className="next-button">
                  {t("forms.buttons.next")}
                </Button>
              </div>
            )}
            {step === 2 && (
              <div className="form-column">
                <WorkingDaysField
                  formWorkingDays={workingDays}
                  setFormWorkingDays={setWorkingDays}
                  name="working_time"
                  setValue={setValue}
                  error={errors.working_time && errors.working_time.message}
                />
                <Input
                  label={`${t("pages.doctors.collegeNumber")}*`}
                  name="medical_number"
                  inputRef={register}
                  error={errors.medical_number && errors.medical_number.message}
                  watch={watch}
                />
                <Input
                  label={`${t("pages.doctors.yearsExperience")}*`}
                  name="years_experience"
                  inputRef={register}
                  watch={watch}
                  error={errors.years_experience && errors.years_experience.message}
                />
                <SpecialityField control={control} name="speciality" error={errors.speciality && errors.speciality.message} />
                <ModalityField
                  control={control}
                  name="preferred_contact"
                  error={errors.preferred_contact && errors.preferred_contact.message}
                />
                <ContactField
                  setContactData={setContactData}
                  contactPhone={contactData.contactPhone}
                  contactEmail={contactData.contactEmail}
                />
                <Button type="submit" className="next-button">
                  {t("pages.doctors.add")}
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </StyledNewDoctor>
  )
}
