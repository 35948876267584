import React from "react"
import { DebounceInput } from "react-debounce-input"

import StyledSearchInput from "./SearchInput.style"
import { ReactComponent as SearchIcon } from "../../../resources/svg/search.svg"

export default function SearchInput({ handleSearch, value, label, className }) {
  return (
    <StyledSearchInput className={className}>
      <DebounceInput debounceTimeout={400} onChange={handleSearch} value={value} type="text" placeholder={label} className="filter-items" />
      <SearchIcon className="search-icon" />
    </StyledSearchInput>
  )
}
