import React, { useState, useEffect } from "react"
import { dateFnsLocalizer } from "react-big-calendar"
import { enGB } from "date-fns/locale"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import isSameDay from "date-fns/isSameDay"
import { useTranslation } from "react-i18next"
import { parseISO, getHours, isPast } from "date-fns"
import { addMinutes } from "date-fns/esm"

import "react-big-calendar/lib/css/react-big-calendar.css"
import StyledDoctorSchedules, {
  StyledCalendar,
  StyledToolbar,
  StyledDateHeader,
  StyledDateItem,
  StyledModal,
} from "./DoctorSchedules.style"
import CancelAppointmentModal from "./CancelAppointmentModal/CancelAppointmentModal"
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal"
import { Text, Loader, Modal, Button } from "../../../components/atoms"
import { DataField } from "../../../components/molecules"
import { ReactComponent as Back } from "../../../resources/svg/back.svg"
import ClinicService from "../../../services/Clinic"
import noImage from "../../../resources/img/no-image.png"
import { capitalize } from "../../../utils/functions"

const locales = {
  "en-GB": enGB,
}

const { REACT_APP_DATES_INTERVAL: datesInterval } = process.env

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
})

const ToolBar = ({ label, onNavigate }) => {
  const handlePrev = () => {
    onNavigate("PREV")
  }

  const handleNext = () => {
    onNavigate("NEXT")
  }

  return (
    <StyledToolbar>
      <button type="button" className="arrow" onClick={handlePrev}>
        <Back />
      </button>
      <Text color="blueHealth" bold size="xl">
        {label}
      </Text>
      <button type="button" className="next arrow" onClick={handleNext}>
        <Back />
      </button>
    </StyledToolbar>
  )
}

const DateModal = ({ modal, setModal, isCurrent, getAppointments }) => {
  const { t } = useTranslation(["translation", "lang", "countries"])
  const [selectedDelay, setSelectedDelay] = useState(null)
  const [showCancelModal, setShowCancelModal] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(null)

  const handleCloseModal = () => {
    setModal(null)
    setSelectedDelay(null)
  }
  const handleSelectDelay = (type) => {
    setSelectedDelay(type)
  }
  const handleNotifyButton = () => {
    // TODO -> manage event
  }

  const handleCancelDate = () => {
    setShowCancelModal(modal.id)
  }

  const handleCloseCancelModal = () => {
    setShowCancelModal(null)
  }

  const handleCloseConfirmationModal = () => {
    setShowConfirmation(null)
    handleCloseModal()
    getAppointments()
  }

  const handleCancelAppointment = () => {
    setShowCancelModal(null)
    setShowConfirmation(t("pages.requests.deleted"))
  }

  return (
    <Modal open={!!modal} handleClose={handleCloseModal}>
      {modal && (
        <StyledModal>
          <Text size="xxxl" bold color="blueHealth" uppercase className="title">
            {format(modal.start, "EEEE d, LLL 'at' HH:mm")}
          </Text>
          <img src={modal.patient?.avatar_url || noImage} alt="patient" className="patient-avatar" />
          <div className="modal-content">
            <div className="personal-information">
              <Text bold size="xl" as="h4">
                {t("pages.doctors.personalInformation")}
              </Text>
              <DataField label={t("pages.doctors.fullName")} data={`${modal.patient?.first_name} ${modal.patient?.last_name}`} />
              <DataField label={t("pages.doctors.gender")} data={t(`common.gender.${modal.patient?.gender}`)} />
              <DataField label={t("pages.requests.language")} data={modal.patient?.languages.map((lang) => t(`lang:${lang}`))} />
              <DataField
                label={t("pages.requests.nationality")}
                data={modal.patient?.nationalities?.map((item) => t(`countries:${item}`))}
              />
            </div>
            <div className="necessity">
              <Text bold size="xl" as="h4">
                {t("pages.doctors.necessity")}
              </Text>
              <DataField
                label={t("pages.doctors.speciality")}
                data={modal.request?.speciality?.map((e) => capitalize(t(`common.specialities.${e}`)))}
              />
              <DataField label={t("pages.doctors.appointment")} data={format(modal.start, "dd/MM/yyyy 'at' HH:mm")} />
            </div>
            {isCurrent ? (
              <div className="notify-delay">
                <Text bold size="xl" as="h4">
                  {t("pages.doctors.notifyDelay")}
                </Text>
                <div
                  className={`delay-type ${selectedDelay === "10" && "selected"}`}
                  role="button"
                  onClick={() => handleSelectDelay("10")}
                  onKeyDown={() => handleSelectDelay("10")}
                  tabIndex="-1"
                >
                  10 min
                </div>
                <div
                  className={`delay-type ${selectedDelay === "20" && "selected"}`}
                  role="button"
                  onClick={() => handleSelectDelay("20")}
                  onKeyDown={() => handleSelectDelay("20")}
                  tabIndex="-1"
                >
                  20 min
                </div>
                <div
                  className={`delay-type ${selectedDelay === "30" && "selected"}`}
                  role="button"
                  onClick={() => handleSelectDelay("30")}
                  onKeyDown={() => handleSelectDelay("30")}
                  tabIndex="-1"
                >
                  30 min
                </div>
                <Button disabled={!selectedDelay} onClick={handleNotifyButton} className="notify-button">
                  {t("pages.doctors.notify")}
                </Button>
              </div>
            ) : (
              <div className="notify-delay" />
            )}
          </div>
          <Button secondary className="cancel-date" onClick={handleCancelDate}>
            {t("pages.doctors.cancel")}
          </Button>
        </StyledModal>
      )}
      <CancelAppointmentModal
        appointment={showCancelModal}
        clinic={modal?.clinic?.id}
        handleCloseModal={handleCloseCancelModal}
        handleCancelAppointment={handleCancelAppointment}
      />
      {showConfirmation && <ConfirmationModal showConfirmation={showConfirmation} handleCloseModal={handleCloseConfirmationModal} />}
    </Modal>
  )
}

const DateItem = ({ date, getAppointments }) => {
  const { start, end, speciality } = date
  const [isCurrent, setIsCurrent] = useState(false)
  const [modal, setModal] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (isPast(start) && !isPast(end)) {
      setIsCurrent(true)
    }
  }, [])

  const handleClickItem = () => {
    setModal(date)
  }

  return (
    <>
      <StyledDateItem isPast={isPast(start)} isCurrent={isCurrent} onClick={handleClickItem}>
        <span className="time">
          {format(start, "HH:mm")} - {format(end, "HH:mm")}
        </span>
        <span className="speciality">{t(`common.specialities.${speciality}`)}</span>
      </StyledDateItem>
      <DateModal modal={modal} setModal={setModal} isCurrent={isCurrent} getAppointments={getAppointments} />
    </>
  )
}

export default function DoctorSchedules({ clinic, id }) {
  const clinicService = new ClinicService()
  const [events, setEvents] = useState([])
  const [selected, setSelected] = useState(null)
  const [isLoading, setIsloading] = useState(true)
  const { t } = useTranslation()

  const DateHeader = ({ label, date }) => {
    const hasEvent = events.find((event) => isSameDay(event.start, date))

    const handleClickDate = () => {
      if (hasEvent) {
        const dayDates = hasEvent.dates.reduce(
          (rows, item) => {
            const dateHour = getHours(item.start)
            rows[dateHour].push(item)
            return rows
          },
          Array.from(Array(24), () => [])
        )
        setSelected({ day: hasEvent.start, dates: dayDates })
      }
    }

    return (
      <StyledDateHeader className={hasEvent && "has-event"} onClick={handleClickDate}>
        {label}
      </StyledDateHeader>
    )
  }

  const components = {
    toolbar: ToolBar,
    month: {
      dateHeader: DateHeader,
    },
  }

  const formats = {
    weekdayFormat: (date, culture, local) => local.format(date, "E", culture),
    monthHeaderFormat: (date, culture, local) => local.format(date, "LLL yyyy", culture),
    dateFormat: (date, culture, local) => local.format(date, "dd", culture),
  }

  const handleClickEvent = () => {
    // TODO -> manage event
  }

  const getAppointments = async () => {
    setSelected(null)
    setIsloading(true)
    try {
      const appointmentsData = await clinicService.getAppointments(clinic, id)
      const eventsList = appointmentsData.map((item) => ({
        id: item.id,
        start: parseISO(item.dates[0]),
        end: addMinutes(parseISO(item.dates[0]), datesInterval),
        speciality: item?.request_ref?.speciality,
        request: item?.request_ref,
        patient: item?.patient_ref,
        clinic: item?.clinic_ref,
      }))
      const groupedEvents = eventsList.reduce((grouped, event) => {
        const findIdx = grouped.findIndex((item) => format(item.start, "dd/MM/yyyy") === format(event.start, "dd/MM/yyyy"))
        if (findIdx === -1) {
          grouped.push({
            start: event.start,
            end: event.start,
            dates: [event],
          })
        } else {
          grouped[findIdx].dates.push(event)
        }

        return grouped
      }, [])
      setEvents(groupedEvents)
      setIsloading(false)
    } catch (error) {
      setIsloading(false)
    }
  }

  useEffect(async () => {
    await getAppointments()
  }, [])

  return (
    <StyledDoctorSchedules>
      <Loader show={isLoading} />
      <div className="calendar-container">
        <StyledCalendar
          localizer={localizer}
          events={events}
          views={["month"]}
          components={components}
          formats={formats}
          onSelectEvent={handleClickEvent}
        />
      </div>
      <div className="details-container">
        {!selected && (
          <Text size="xl" bold color="blueHealth">
            {t("pages.doctors.selectDate")}
          </Text>
        )}
        {selected && (
          <div className="dates-container">
            <div className="container-header">
              <div className="header-date">
                <Text color="blueHealth" size="xxxl" bold>
                  {format(selected.day, "d")}
                </Text>
                <Text color="blueHealth" size="xl" bold>
                  {format(selected.day, "E")}
                </Text>
              </div>
            </div>
            <div className="container-rows">
              {selected?.dates.map((hour, index) => (
                <div key={`${hour}-${index}`} className="hour-row">
                  {hour.map((date, index) => (
                    <DateItem key={`${date}-${index}`} date={date} getAppointments={getAppointments} />
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </StyledDoctorSchedules>
  )
}
