import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import styled from "styled-components"

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.8);
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
      background-color: transparent;
    }
  }
  .MuiDialog-paper {
    background-color: ${({ theme }) => theme.colors.brownPerson};
    box-shadow: none;
    border-radius: 1rem;
    padding: 2rem;
  }
  .close {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    fill: ${({ theme }) => theme.colors.blueHealth};
    cursor: pointer;
  }
`

const StyledContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
`

export { StyledDialog, StyledContent }
