import styled from "styled-components"
import theme from "../../../styles/theme"

const StyledSelect = styled.div`
  position: relative;
  .error {
    position: absolute;
    text-transform: uppercase;
    bottom: -1.125rem;
    left: 0;
    color: ${() => theme.colors.red};
    font-size: ${() => theme.fonts.xs.size};
  }
`

export const StyledIndicator = styled.div`
  svg {
    transition: all 0.3s;
    transform: rotate(${({ isFocused }) => (isFocused ? "180deg" : "0deg")});
    fill: ${({ isFocused }) => (isFocused ? theme.colors.blueHealth : theme.colors.black)};
  }
`

export const customStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: () => ({}),
  control: (provided, state) => {
    return {
      ...provided,
      boxShadow: "none",
      border: state.isFocused ? `1px solid ${theme.colors.blueHealth}` : `1px solid ${theme.colors.brey}`,
      borderRadius: "6px",
      backgroundColor: state.isFocused ? theme.colors.blueSkyOpacity : "transparent",
      minHeight: "2.5rem",
      ":focus": {
        outline: "none",
      },
      ":hover": {
        backgroundColor: theme.colors.blueSkyOpacity,
        borderColor: theme.colors.blueHealth,
      },
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 1rem",
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: theme.colors.black,
      fontWeight: theme.fonts.regular,
      margin: 0,
    }
  },
  menu: (provided) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: theme.colors.blueSkyOpacity,
    borderRadius: 0,
    borderBottomRightRadius: "0.5rem",
    margin: 0,
  }),
  container: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled && 0.2,
  }),
}

export default StyledSelect
