import styled from "styled-components"
import { centered } from "../../styles/utils/mixins"

const StyledForgotPassword = styled.div`
  ${centered("column")}
  min-height: 100vh;
  position: relative;
  padding: 4.5rem;
  padding-top: 6rem;
  .back-link-container {
    position: absolute;
    top: 4.5rem;
    right: 4.5rem;
    .back-link {
      text-align: right;
    }
  }
  .form-container {
    width: 100%;
    max-width: 19.5rem;
    text-align: center;
    svg {
      display: block;
      margin: auto;
      margin-bottom: 5rem;
    }
    h2 {
      margin-bottom: 1rem;
    }
    form {
      margin-top: 3rem;
      button {
        margin-top: 3rem;
      }
    }
  }
`

export const StyledPasswordModal = styled.div`
  margin: 4.5rem;
  max-width: 15rem;
  .modal-message {
    text-align: center;
    margin-bottom: 2rem;
  }
`

export default StyledForgotPassword
