import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { useLocation, useHistory, Link } from "react-router-dom"
import { useForm, useFieldArray } from "react-hook-form"
import qs from "qs"

import { Text, Button, ControlledSelect as Select, Modal, Loader, Input, AddButton } from "../../components/atoms"
import { DataField } from "../../components/molecules"
import noImage from "../../resources/img/no-image.png"
import StyledProfile, { StyledDeleteUserModal, StyledConfirmationModal, StyledInvitationsModal } from "./Profile.style"
import { useAuth, useDispatchAuth } from "../../providers/UserProvider"
import { ReactComponent as Pencil } from "../../resources/svg/pencil.svg"
import { ReactComponent as Trash } from "../../resources/svg/trash.svg"
import paths from "../../utils/routePaths"
import ClinicsService from "../../services/Clinic"

export default function Profile() {
  const { user, selectedClinic } = useAuth()
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatchAuth = useDispatchAuth()

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const { tab } = query

  const updateQuery = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify(
        {
          ...query,
          ...value,
        },
        { addQueryPrefix: true }
      ),
    })
  }

  const clinicService = new ClinicsService()
  const tabs = [t("pages.profile.information"), t("pages.profile.managers")]
  const [currenTab, setCurrentTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [selected, setSelected] = useState(null)
  const [managersActive, setManagersActive] = useState(false)
  const [isFormEmpty, setIsFormEmpty] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showInvitationsModal, setShowInvitationsModal] = useState(false)
  const [invitationsSent, setInvitationsSent] = useState(false)
  const { control, register, handleSubmit, reset, errors, watch } = useForm({
    defaultValues: {
      invitations: [{ email: "" }],
    },
  })
  const { fields, append } = useFieldArray({
    control,
    name: "invitations",
  })

  const options = [
    { value: "owner", label: t("pages.profile.owner") },
    { value: "manager", label: t("pages.profile.manager") },
  ]

  const watchInvitations = watch("invitations")

  const getManagers = async () => {
    setIsLoading(true)
    try {
      const response = await clinicService.getManagers(selectedClinic || query.center)
      setUsers(response.filter((item) => user.id !== item.id))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleSelectUser = (item) => {
    setSelected(item)
    setShowDeleteModal(true)
  }

  const handleCloseModal = () => {
    setShowDeleteModal(false)
    setShowConfirmation(false)
    reset()
    setShowInvitationsModal(false)
    setInvitationsSent(false)
  }

  const handleDeleteUser = async () => {
    setShowDeleteModal(false)
    const { id } = selected
    setIsLoading(true)
    try {
      await clinicService.deleteManager(selectedClinic, id)
      await getManagers()
      setSelected(null)
      setShowConfirmation(true)
    } catch (error) {
      setIsLoading(false)
      setSelected(null)
    }
  }

  const handleAddButton = () => {
    setShowInvitationsModal(true)
  }

  const handleAddField = () => {
    append({ email: "" })
  }

  const onSubmit = async (data) => {
    setShowInvitationsModal(false)
    setIsLoading(true)
    const emails = data.invitations.map((item) => item.email)
    try {
      await clinicService.sendInvitations(selectedClinic, emails)
      setIsLoading(false)
      setInvitationsSent(true)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleRolechange = async (option, data) => {
    const { value } = option
    const { id } = data
    setIsLoading(true)
    try {
      await clinicService.changeRole(selectedClinic, id, value)
      await getManagers()
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleTabChange = (index, lastIndex) => {
    if (index === lastIndex || isLoading) {
      return false
    }
    if (index === 0) {
      setUsers([])
    }
    updateQuery({ tab: tabs[index] })
    setCurrentTab(index)
    return true
  }

  useEffect(async () => {
    if (selectedClinic) {
      updateQuery({ center: selectedClinic })
    } else if (query.center) {
      dispatchAuth({
        type: "SELECT_CLINIC",
        payload: query.center,
      })
    }
    if (selectedClinic && user.role === "owner") {
      setManagersActive(true)
    }
  }, [])

  useEffect(() => {
    if (selectedClinic && user.role === "owner") {
      setManagersActive(true)
    }
  }, [selectedClinic])

  useEffect(() => {
    if (tab) {
      const idxTab = tabs.findIndex((tabItem) => tabItem === tab)
      setCurrentTab(idxTab !== -1 ? idxTab : 0)
      if (idxTab === 1) {
        getManagers()
      }
    }
  }, [tab])

  useEffect(() => {
    let isEmpty = true
    watchInvitations.forEach((field) => {
      if (field.email) {
        isEmpty = false
      }
    })
    setIsFormEmpty(isEmpty)
  }, [watchInvitations])

  return (
    <StyledProfile disableManagers={!managersActive}>
      <Text as="h1" className="title" size="xxxl" bold>
        {t("pages.profile.profile")}
      </Text>

      <Tabs selectedTabClassName="selected-tab" className="tabs" selectedIndex={currenTab} onSelect={handleTabChange}>
        <TabList className="tab-list">
          {tabs.map((tabItem) => (
            <Tab key={tabItem} className="tab">
              {tabItem}
            </Tab>
          ))}
        </TabList>
        <div className="tabs-content">
          <TabPanel>
            <div className="profile-information">
              <div className="profile-image">
                <img src={user.avatar_url || noImage} alt={user.fullname} />
              </div>
              <Link
                to={{
                  pathname: paths.EDIT_PROFILE,
                }}
              >
                <div className="edit-profile">
                  <Pencil />
                  <Text uppercase size="xs" as="span">
                    {t("pages.doctors.editProfile")}
                  </Text>
                </div>
              </Link>
            </div>
            <div className="profile-data">
              <div>
                <DataField label={t("pages.profile.fullName")} data={user.fullname} />
                <DataField label={t("pages.profile.email")} data={user.email} />
              </div>
              <div>
                <DataField label={t("pages.profile.mobile")} data={user.phone} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            {managersActive && (
              <div className="managers-screen">
                <Loader show={isLoading} />
                <Button secondary className="add-manager" onClick={handleAddButton}>
                  {t("pages.profile.addManager")}
                </Button>
                <div className="managers-list">
                  <div className="list-header">
                    <Text size="s" color="blueHealth" bold>
                      {t("pages.profile.manager")}
                    </Text>
                    <Text size="s" color="blueHealth" bold>
                      {t("pages.profile.role")}
                    </Text>
                    <Text size="s" color="blueHealth" bold>
                      {t("pages.profile.email")}
                    </Text>
                    <Text />
                  </div>
                  <div className="list-content">
                    {users.map((item) => (
                      <div className="list-item" key={item.id}>
                        <div className="avatar-container">{item.avatar_url && <img src={item.avatar_url} alt="" />}</div>
                        <div className="column">
                          <Text size="m">{item.fullname}</Text>
                        </div>
                        <div className="column select-role">
                          <Select
                            options={options}
                            onchange={(event) => handleRolechange(event, item)}
                            value={options.find((option) => option.value === item.role)}
                            className="role-selector"
                          />
                        </div>
                        <div className="column">
                          <Text size="m">{item.email}</Text>
                        </div>
                        <div className="column delete-item">
                          <button type="button" onClick={() => handleSelectUser(item)} className="delete-icon">
                            <Trash />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabPanel>
        </div>
      </Tabs>
      <Modal open={showDeleteModal} showClose={false} handleClose={handleCloseModal}>
        <StyledDeleteUserModal>
          <Loader show={isLoading} />
          <Text color="blueHealth" bold size="xxxl" as="h2">
            {t("pages.profile.deleteTitle")}
          </Text>
          <Text color="blueHealth">{t("pages.profile.deleteText")}</Text>
          <div className="modal-buttons">
            <Button secondary onClick={handleDeleteUser}>
              {t("common.buttons.yes")}
            </Button>
            <Button onClick={handleCloseModal}>{t("common.buttons.no")}</Button>
          </div>
        </StyledDeleteUserModal>
      </Modal>
      {showConfirmation && (
        <Modal open={showConfirmation} handleClose={handleCloseModal} showClose={false} timed>
          <StyledConfirmationModal>
            <Text color="blueHealth" bold size="xxxl" as="h2">
              {t("pages.profile.deleted")}
            </Text>
          </StyledConfirmationModal>
        </Modal>
      )}
      {showInvitationsModal && (
        <Modal open={showInvitationsModal} handleClose={handleCloseModal}>
          <StyledInvitationsModal>
            <Text color="blueHealth" bold size="xxxl" as="h2" className="title">
              {t("pages.profile.sendInvitation")}
            </Text>
            <Text size="xl" className="subtitle">
              {t("pages.profile.writeEmails")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              {fields.map((item, idx) => (
                <div key={item.id}>
                  <Input
                    label={t("forms.fields.email")}
                    name={`invitations[${idx}.email]`}
                    watch={watch}
                    inputRef={register({
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("forms.errors.emailInvalid"),
                      },
                    })}
                    error={errors?.invitations && errors?.invitations[idx]?.email && errors?.invitations[idx]?.email.message}
                  />
                  {idx === fields.length - 1 && watchInvitations[watchInvitations.length - 1].email && (
                    <AddButton label={t("pages.profile.addEmail")} onClick={handleAddField} />
                  )}
                </div>
              ))}
              <Button type="submit" className="send-button" disabled={isFormEmpty}>
                {t("pages.profile.send")}
              </Button>
            </form>
          </StyledInvitationsModal>
        </Modal>
      )}
      {invitationsSent && (
        <Modal open={invitationsSent} handleClose={handleCloseModal} showClose={false} timed>
          <StyledConfirmationModal>
            <Text color="blueHealth" bold size="xxxl" as="h2">
              {t("pages.profile.invitationsSent")}
            </Text>
            <Text color="blueHealth" size="m">
              {t("pages.profile.invitationsText")}
            </Text>
          </StyledConfirmationModal>
        </Modal>
      )}
    </StyledProfile>
  )
}
