import React from "react"

import StyledSwitch, { StyledSwitchControl } from "./Switch.style"

export default function Switch({ checked, handleChange, label, className, disabled, name }) {
  return (
    <StyledSwitch
      label={label}
      className={className}
      control={<StyledSwitchControl checked={checked} onChange={handleChange} disabled={disabled} name={name} />}
    />
  )
}
