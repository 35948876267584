import styled from "styled-components"

const StyledAvatarUpload = styled.div`
  .avatar-container {
    margin: auto;
    height: 8.25rem;
    width: 8.25rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.brownPerson};
    position: relative;
    border: ${({ theme, isDragActive }) => (isDragActive ? `1px solid ${theme.colors.blueHealth}` : "none")};
    cursor: pointer;
    img {
      height: 8.25rem;
      width: 8.25em;
      border-radius: 50%;
      object-fit: cover;
      background: ${({ theme }) => theme.colors.brownPerson};
      opacity: 0.3;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export default StyledAvatarUpload
