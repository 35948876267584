/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"

import StyledAgreeModal from "./AgreeRequestModal.style"
import { Modal, Text, Button, DisplayDate, Select, Loader, AddButton, SelectedItem } from "../../../components/atoms"
import { capitalize, getAvailableDates } from "../../../utils/functions"
import ClinicService from "../../../services/Clinic"
import { uniqueId } from "lodash-es"

export default function AgreeRequestModal({ handleCloseModal, request, handleAgreeRequest, doctors, clinic }) {
  const { t } = useTranslation()
  const { handleSubmit, control, watch, setValue, reset, formState } = useForm({
    shouldUnregister: false,
  })

  const doctorList = doctors.filter((doctor) => {
    const optionsDates = getAvailableDates(doctor.workingTime, request.date_range)
    return doctor.speciality.includes(request.speciality) && optionsDates?.length > 0
  })

  const [isLoading, setIsLoading] = useState(false)

  const [availableDates, setAvailableDates] = useState({})

  const [activeDoctor, setActiveDoctor] = useState(null)

  const [availableDoctors, setAvailableDoctors] = useState(doctorList)

  const [displayDoctorSelector, setDisplayDoctorSelector] = useState(true)

  const [unknowDoctor, setUnknowDoctor] = useState(false)
  const [defaultDoctor, setDefaultdoctor] = useState(null)
  const [preferredDoctor, setPreferredDoctor] = useState(null)

  const [allAppointments, setAllAppointments] = useState({})

  const watchDoctor = watch("doctor")
  const watchDate = watch("date")

  const clinicService = new ClinicService()
  const isEditing = request?.appointMentDoctor && request?.appointmentDates.length > 0

  const getDates = (docId, appointmentDates) => {
    const selectedDoctor = doctorList.find((doctor) => doctor.value === docId)
    if (!selectedDoctor) {
      setUnknowDoctor(true)
      return
    }
    setUnknowDoctor(false)
    const optionsDates = getAvailableDates(selectedDoctor.workingTime, request.date_range)

    if (appointmentDates) {
      return optionsDates.filter((date) => !appointmentDates.includes(date.value))
    } else {
      return optionsDates
    }
  }
  // const showDateLabel = (docId, date)=>{
  //   const selectedDoctor = doctorList.find((doctor) => doctor.value === docId)
  //   if (!selectedDoctor) {
  //     return
  //   }
  //   const optionsDates = getAvailableDates(
  //     selectedDoctor.workingTime,
  //     request.date_range
  //   )

  //   return optionsDates.find(dt => dt.value === date)?.label

  // }

  const showDateLabel = (date) => {
    const _date = new Date(date)
    const _hours = _date.getHours()
    const _minutes = _date.getMinutes()
    const hours = _hours < 10 ? `0${_hours}` : _hours
    const minutes = _minutes < 10 ? `0${_minutes}` : _minutes

    return `${_date.toLocaleDateString()} at ${hours}:${minutes}`
  }

  const onSubmit = () => {
    // TODO -> manage submit
  }

  //Revisar este useEffect
  useEffect(() => {
    if (request?.preferred_appointment) {
      const preferred = request.preferred_appointment.doctor_ref
      setPreferredDoctor(preferred)
      setValue("doctor", preferred, {
        shouldDirty: true,
      })
      getDates(preferred)
    }
    if (request?.appointMentDoctor && request?.appointmentDates.length > 0) {
      getDates(request.appointMentDoctor._id, request.appointmentDates)
      setAllAppointments({
        [request.appointMentDoctor._id]: [...request.appointmentDates],
      })
      setValue("doctor", request.appointMentDoctor._id, { shouldDirty: true })
      setDefaultdoctor(request.appointMentDoctor._id)
    }
  }, [])

  useEffect(() => {
    if (watchDate) {
      const hasAppointments = allAppointments[activeDoctor]
      setAllAppointments({
        ...allAppointments,
        [activeDoctor]: hasAppointments ? [...allAppointments[activeDoctor], watchDate] : [watchDate],
      })
    }
  }, [watchDate])

  useEffect(() => {
    if (watchDoctor) {
      const hasAppointments = allAppointments[watchDoctor]
      setAllAppointments({
        ...allAppointments,
        [watchDoctor]: hasAppointments ? [...allAppointments[watchDoctor]] : [],
      })
      setDisplayDoctorSelector(false)
      setValue("doctor", null)
    }
  }, [watchDoctor])

  useEffect(() => {
    const _availableAppointments = Object.entries(allAppointments).reduce((appointmentsList, [doctor, dates]) => {
      const _next = { ...appointmentsList }
      _next[doctor] = getDates(doctor, dates || null)
      return _next
    }, {})
    setAvailableDates(_availableAppointments)
    setValue("date", null)
    const busyDoctors = Object.keys(allAppointments)
    setAvailableDoctors(doctorList.filter((doc) => !busyDoctors.includes(doc.value)))
  }, [allAppointments])

  // const handleAddButton = (doctor) => {
  //   const hasAppointments = allAppointments[watchDoctor]
  //   setAllAppointments({...allAppointments, [doctor]:hasAppointments ? [...allAppointments[doctor], ""]: [""]})
  // }

  const handleRemoveDate = (doctor, date) => {
    setAllAppointments({
      ...allAppointments,
      [doctor]: allAppointments[doctor].filter((d) => d !== date),
    })
  }

  const handleRemoveDoctor = (doctor) => {
    const _allAppointments = { ...allAppointments }
    delete _allAppointments[doctor]
    setAllAppointments(_allAppointments)
    setDisplayDoctorSelector(true)
  }

  const showDoctorLabel = (doctorId) => {
    const [_doctor] = doctorList.filter((doctor) => doctor.value === doctorId)
    return _doctor.label
  }

  const handleClose = () => {
    reset()
    handleCloseModal()
  }

  const handleAcceptButton = async () => {
    setIsLoading(true)
    // const appointmentData = {
    //   doctor_ref: watchDoctor,
    //   dates: selectedDates,
    //   clinic_ref: clinic,
    //   patient_ref: request.patient_ref._id,
    //   request_ref: request._id,
    // }

    const appointments = Object.entries(allAppointments).map(([doctor, dates]) => ({
      doctor_ref: doctor,
      dates: dates.map((date) => {
        const _date = new Date(date)
        _date.setHours(_date.getHours())
        return _date
      }),
      clinic_ref: clinic,
      patient_ref: request.patient_ref._id || request.patient_ref.id,
      request_ref: request._id || request.id,
    }))

    let response
    try {
      if (isEditing) {
        response = await clinicService.editAppointment(clinic, request.appointmentId, {
          dates: Object.values(allAppointments)[0]?.map((date) => {
            const _date = new Date(date)
            _date.setHours(_date.getHours())
            return _date
          }),
          doctor_ref: Object.keys(allAppointments)[0],
        })
      } else {
        response = await Promise.all(appointments.map((appointment) => clinicService.createAppointment(clinic, appointment)))
      }
      handleAgreeRequest(response)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Modal open={!!request} handleClose={handleClose}>
      <Loader show={isLoading} />
      {request && (
        <StyledAgreeModal>
          <div className="modal-container">
            <Text color="blueHealth" bold size="xxxl" as="h2">
              {t(`pages.requests.${isEditing ? "editAppointment" : "selectDoctor"}`)}
            </Text>
            <Text color="blueHealth">{capitalize(request.speciality)}</Text>
            <Text
              color="blueHealth"
              bold
              className="modal-patient-name"
            >{`${request.patient_ref?.first_name} ${request.patient_ref?.last_name}`}</Text>
            <ul className="modal-dates">
              {request.date_range?.map((date, idx) => (
                <Text
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${date}-${idx}`}
                  as="li"
                  size="s"
                >
                  <DisplayDate date={date} long />
                </Text>
              ))}
            </ul>
            {doctorList.length === 0 ? (
              <Text size="xl" className="no-doctors">
                {t("pages.requests.noDoctor")}
              </Text>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                {unknowDoctor && <Text className="unknow-doctor">{t("pages.requests.unknowDoctor")}</Text>}
                {preferredDoctor && (
                  <div className="preferred-doctor">
                    <Text>{t("pages.requests.preferredDoctor")}</Text>
                    <Text size="xl" bold>
                      {doctorList.find((doctor) => doctor.value === preferredDoctor)?.label}
                    </Text>
                  </div>
                )}

                {Object.entries(allAppointments).map(([doctor, dates]) => {
                  return (
                    <div key={doctor} className="appointment">
                      <div className="selected-dates">
                        <SelectedItem item={showDoctorLabel(doctor)} onClick={() => handleRemoveDoctor(doctor)} />
                      </div>
                      {dates.map((date) => (
                        <SelectedItem key={uniqueId()} item={showDateLabel(date)} onClick={() => handleRemoveDate(doctor, date)} />
                      ))}
                      {availableDates[doctor]?.length === 0 || watchDate ? null : (
                        <Select
                          options={availableDates[doctor] || []}
                          label="select a date"
                          name="date"
                          control={control}
                          disabled={availableDates[doctor]?.length === 0}
                          className="modal-select"
                          onClick={() => setActiveDoctor(doctor)}
                        />
                      )}
                    </div>
                  )
                })}

                {displayDoctorSelector ? (
                  <Select
                    options={availableDoctors}
                    label="select a doctor"
                    name="doctor"
                    control={control}
                    className="modal-select"
                    defaultValue={preferredDoctor || defaultDoctor}
                  />
                ) : null}

                {!displayDoctorSelector && !isEditing && availableDoctors.length > 0 && (
                  <AddButton label={t("pages.requests.addDoctor")} onClick={() => setDisplayDoctorSelector(true)} className="add-date" />
                )}
              </form>
            )}
            <div>
              <Button
                onClick={handleAcceptButton}
                disabled={
                  Object.entries(allAppointments).length === 0 || Object.values(allAppointments).some((dates) => dates?.length === 0)
                  // unknowDoctor
                }
              >
                {t("pages.requests.ok")}
              </Button>
            </div>
          </div>
        </StyledAgreeModal>
      )}
    </Modal>
  )
}
