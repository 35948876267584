import styled from "styled-components"

const StyledDoctorDetail = styled.div`
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 5rem;
  }
  .name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
    a {
      margin-right: 4rem;
      display: flex;
      svg {
        width: 12px;
      }
    }
  }
  .tabs {
    .tab-list {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 4rem;
      .tab {
        text-transform: uppercase;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.blueHealth};
        font-weight: 500;
        font-size: ${({ theme }) => theme.fonts.xl.size};
        opacity: 0.3;
        transition: all 0.3s;
        &:not(:last-child) {
          margin-right: 2rem;
        }
        &:hover {
          opacity: 1;
        }
        &:focus {
          outline: none;
        }
        &.selected-tab {
          opacity: 1;
          font-size: ${({ theme }) => theme.fonts.xxl.size};
          cursor: default;
        }
      }
    }
  }
  .doctor-information {
    width: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 3rem;
    .profile-image {
      width: 8.25rem;
      height: 8.25rem;
      object-fit: cover;
      border-radius: 50%;
      display: block;
      margin: auto;
      margin-bottom: 1.5rem;
    }
    .appointments-switch {
      margin-bottom: 2rem;
    }
    .edit-profile {
      cursor: pointer;
      display: flex;
      align-items: center;
      span {
        margin-left: 0.5rem;
      }
    }
  }
  .doctor-data {
    display: flex;
    justify-content: space-between;
    width: 32rem;
    margin: auto;
    margin-bottom: 2.5rem;
    & > div {
      width: 15rem;
    }
    h4 {
      margin-bottom: 1rem;
    }
  }
  .delete-doctor {
    margin: auto;
    display: block;
    cursor: pointer;
  }
`

export const StyledDeleteDoctorModal = styled.div`
  padding: 0.5rem;
  max-width: 20rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    text-align: center;
    max-width: 11rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

export default StyledDoctorDetail
