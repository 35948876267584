import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { Text } from "../../atoms"

import StyledMedicalCenterCard from "./MedicalCenterCard.style"

export default function MedicalCenterCard({ id, name, location, image, newRequest }) {
  const { t } = useTranslation()

  return (
    <Link to={`/requests/?center=${id}`}>
      <StyledMedicalCenterCard newRequest={newRequest} image={image}>
        <div className="card-header">
          <div>
            <div className="center-image" />
          </div>
          <div className="card-text">
            <Text as="h4" bold size="xl">
              {name}
            </Text>
            <Text color="blueHealth">{location}</Text>
          </div>
        </div>
        <button type="button" className="card-button">
          {t("pages.centers.go")}
        </button>
      </StyledMedicalCenterCard>
    </Link>
  )
}
