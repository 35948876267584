import styled from "styled-components"
import { centered } from "../../../styles/utils/mixins"

const StyledMedicalCenterCard = styled.div`
  height: 7.5rem;
  width: 19.5rem;
  box-shadow: 4px 1px 16px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0.75rem 2.5rem 0.75rem;

  ${({ newRequest }) =>
    newRequest &&
    `
      position: relative;
      &:before {
        position: absolute;
        top: 5%;
        left: 2%;
        content: "";
        background-image: url("/notification-nav.svg");
        background-size: contain;
        background-position: center;
        width: 10px;
        height: 10px;
        border-radius: 100%;
      }
    `}

  .card-header {
    display: flex;
    align-items: center;
    .center-image {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: 1rem;
      background-color: ${({ theme }) => theme.colors.blueSky};
      ${({ image }) => image && `background-image: url(${image});`}
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .card-button {
    display: block;
    width: 100%;
    height: calc(2.5rem - 2px);
    border: 1px solid ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
    border-radius: 6px;
    ${centered}
    color: ${({ theme }) => theme.colors.blueHealth};
    font-size: ${({ theme }) => theme.fonts.l.size};
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: ${({ theme }) => theme.colors.blueHealth};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export default StyledMedicalCenterCard
