import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import StyledCenters from "./Centers.style"
import { Text, Button, SearchInput, Loader } from "../../components/atoms"
import { MedicalCenterCard } from "../../components/molecules"
import ManagerService from "../../services/Manager"
import { useDispatchAuth, useAuth } from "../../providers/UserProvider"

export default function Centers() {
  const { t } = useTranslation()
  const { newRequest } = useAuth()
  const dispatch = useDispatchAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [clinicsList, setClinicsList] = useState([])
  const managerService = new ManagerService()

  const fakeApiCall = () => setTimeout(() => setIsLoading(false), 1000)

  useEffect(async () => {
    dispatch({ type: "CLEAR_CLINIC" })
    const apiData = await managerService.managedClinics()
    setClinicsList(apiData)
    setIsLoading(false)
  }, [])

  const handleSearch = () => {
    setIsLoading(true)
    fakeApiCall()
  }

  return (
    <StyledCenters>
      <div className="cards-container">
        <div className="top-section">
          <div className="title-search">
            <Text as="h1" className="title" size="xxxl" bold>
              {t("pages.centers.medicalCenters")}
            </Text>
            <div className="search-input">
              <SearchInput label={t("pages.centers.searchLabel")} handleSearch={handleSearch} />
            </div>
          </div>
          <div className="add-centers">
            <Button secondary>{t("pages.centers.add")}</Button>
          </div>
        </div>
        {isLoading ? (
          <Loader show />
        ) : (
          clinicsList.map((clinic) => (
            <MedicalCenterCard
              name={clinic.company_name}
              image={clinic.avatar_url}
              location={clinic.address}
              id={clinic.id}
              newRequest={newRequest.includes(clinic.id)}
              key={clinic.id}
            />
          ))
        )}
      </div>
    </StyledCenters>
  )
}
