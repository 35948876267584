import styled from "styled-components"

const StyledOptions = styled.div`
  border-radius: 0.5rem;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 2rem;
  position: relative;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    label {
      cursor: default;
    }
  }
  .option {
    input[type="radio"] {
      display: none;
      &:checked ~ label {
        background-color: ${({ theme }) => theme.colors.blueHealth};
        border-color: ${({ theme }) => theme.colors.blueHealth};
        color: ${({ theme }) => theme.colors.white};
        font-weight: ${({ theme }) => theme.fonts.regular};
      }
    }
    label {
      padding: 0.75rem 1.5rem;
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.colors.brey};
    }
    &:first-child {
      label {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }
    }
    &:last-child {
      label {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
    &:not(:first-child) {
      margin-left: -1px;
    }
  }
  .error {
    position: absolute;
    text-transform: uppercase;
    bottom: -1.125rem;
    left: 0;
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fonts.xs.size};
  }
`

export default StyledOptions
