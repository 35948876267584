import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useFieldArray } from "react-hook-form"

import StyledModalityField, { StyledModal } from "./ModalityField.style"
import { Modal, Text, Button, ButtonFormModal, ItemCard } from "../../../components/atoms"
import { ReactComponent as EyeIcon } from "../../../resources/svg/eye.svg"
import { ReactComponent as HomeIcon } from "../../../resources/svg/home.svg"
import { ReactComponent as VideoIcon } from "../../../resources/svg/video.svg"

export default function ModalityField({ control, name, error }) {
  const { t } = useTranslation()

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })
  const [open, setOpen] = useState(false)

  const modalities = [
    {
      value: "face_to_face",
      label: t("common.modalities.face_to_face"),
      icon: <EyeIcon />,
    },
    {
      value: "home",
      label: t("common.modalities.home"),
      icon: <HomeIcon />,
    },
    {
      value: "video_call",
      label: t("common.modalities.video_call"),
      icon: <VideoIcon />,
    },
  ]

  const isSelected = (value) => {
    return fields.findIndex((field) => field.value === value) !== -1
  }

  const handleModal = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const onSelect = (value) => {
    if (isSelected(value)) {
      if (fields.length > 1) {
        const idx = fields.findIndex((field) => field.value === value)
        remove(idx)
      }
    } else {
      const addItem = modalities.find((item) => item.value === value)
      append({
        value: addItem.value,
        label: addItem.label,
      })
    }
  }

  return (
    <StyledModalityField>
      {fields.length === 0 ? (
        <Button secondary onClick={handleModal} className="empty-button">
          {t("pages.doctors.modality")}*
        </Button>
      ) : (
        <ButtonFormModal onClick={handleModal}>{fields.map((field) => field.label).join(", ")}</ButtonFormModal>
      )}
      {error && <span className="error">{error}</span>}
      <Modal open={open} handleClose={handleClose}>
        <StyledModal>
          <div className="modal-content">
            <Text bold color="blueHealth" size="xxxl" as="h3">
              {t("pages.doctors.modality")}
            </Text>
            <Text bold size="xl" className="subtitle">
              {t("pages.doctors.chooseModality")}
            </Text>
            <div className="modalities">
              {modalities.map((item) => (
                <ItemCard key={item.value} item={item} handleClick={onSelect} isSelected={isSelected(item.value)} />
              ))}
            </div>
            <Button className="ok-button" onClick={handleClose}>
              {t("common.buttons.ok")}
            </Button>
          </div>
        </StyledModal>
      </Modal>
    </StyledModalityField>
  )
}
