import React from "react"
import PropTypes from "prop-types"

import StyledButtonFormModal from "./ButtonFormModal.style"
import { ReactComponent as PlusCircle } from "../../../resources/svg/plus-circle.svg"

export default function ButtonFormModal({ children, onClick, disabled, className, type = "button" }) {
  return (
    <StyledButtonFormModal
      onClick={onClick}
      disabled={disabled}
      className={className}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
      <span className="plus-circle">
        <PlusCircle />
      </span>
    </StyledButtonFormModal>
  )
}

ButtonFormModal.propTypes = {
  /**
   The button text
   */
  children: PropTypes.string.isRequired,
  /**
   Set the button disabled
   */
  disabled: PropTypes.bool,
}
