import styled from "styled-components"

import { baseInput } from "../../../styles/utils/mixins"

const AutocompleteWrapper = styled.div`
  ${baseInput}
  .MuiAutocomplete-paper {
    box-shadow: none;
    border-radius: 0;
    border-bottom-right-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
    .MuiAutocomplete-noOptions {
      font-family: "Founders Grotesk", sans-serif;
      font-weight: 300;
    }
    .MuiAutocomplete-listbox {
      li {
        font-family: "Founders Grotesk", sans-serif;
        font-weight: 300;
      }
    }
  }
`

export default AutocompleteWrapper
