import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, Link, useHistory, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import StyledEditDoctor from "./EditDoctor.style"
import paths from "../../utils/routePaths"
import { Loader, Text, Switch, Button, Input, Options, AvatarUpload } from "../../components/atoms"
import { ReactComponent as Back } from "../../resources/svg/back.svg"
import NationalityField from "./NationalityField/NationalityField"
import LanguageField from "./LanguageField/LanguageField"
import WorkingDaysField from "./WorkingDaysField/WorkingDaysField"
import SpecialityField from "./SpecialityField/SpecialityField"
import ModalityField from "./ModalityField/ModalityField"
import ContactField from "./ContactField/ContactField"
import countriesJSON from "../../utils/countries.json"
import langJSON from "../../utils/lang.json"
import ClinicService from "../../services/Clinic"
import { Box } from "@material-ui/core"

export default function EditDoctor() {
  const { t } = useTranslation(["translation", "lang", "countries"])
  const genderOptions = [
    { value: 1, label: t("common.gender.1") },
    { value: 2, label: t("common.gender.2") },
    { value: 0, label: t("common.gender.0") },
  ]
  const location = useLocation()
  const history = useHistory()
  const { state } = location
  const { doctor = {} } = state || {}
  const { id, clinic } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [workingDays, setWorkingDays] = useState(doctor.working_time)
  const [appointmentsSwitch, setAppointmentsSwitch] = useState(false)
  const [contactData, setContactData] = useState({})
  const [image, setImage] = useState(null)

  const clinicService = new ClinicService()

  const handleSetImage = (imageData) => {
    setImage(imageData)
  }

  const schema = yup.object().shape({
    speciality: yup.array().min(1, t("forms.errors.valueRequired")),
    preferred_contact: yup.array().min(1, t("forms.errors.valueRequired")),
    working_time: yup.object().test("check times", t("forms.errors.valueRequired"), (value) => {
      let isValid = false
      Object.keys(value).forEach((item) => {
        if (value[item].length > 0) {
          isValid = true
        }
      })
      return isValid
    }),
  })
  const getCountries = () =>
    Object.keys(countriesJSON).map((country) => ({
      value: country,
      label: t(`countries:${country}`),
    }))
  const getLanguages = () =>
    Object.keys(langJSON).map((lang) => ({
      value: lang,
      label: t(`lang:${lang}`),
    }))

  const defaultValues = {
    ...doctor,
    nationalities: getCountries().filter((country) => doctor.nationalities.find((item) => item === country.value) !== undefined),
    languages: getLanguages().filter((language) => doctor.languages.find((item) => item === language.value) !== undefined),
    preferred_contact: doctor.preferred_contact?.map((item) => ({
      value: item,
      label: t(`common.modalities.${item}`),
    })),
  }
  const { handleSubmit, control, errors, setValue, register, watch } = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (!state) {
      history.replace(`${paths.DOCTORS}/${id}/clinic/${clinic}`)
    }
    if (doctor?.contact) {
      setContactData({
        contactPhone: doctor.contact.phone,
        contactEmail: doctor.contact.email,
      })
    }
  }, [])

  const onSubmit = async (values) => {
    const { speciality, preferred_contact, working_time } = values
    const contact = {}
    if (contactData.contactPhone) {
      contact.phone = contactData.contactPhone
    }
    if (contactData.contactEmail) {
      contact.email = contactData.contactEmail
    }
    const formValues = {
      speciality,
      working_time,
      preferred_contact: preferred_contact.map((item) => item.value),
      contact,
    }

    if (image) {
      formValues.avatar = image
    }

    setIsLoading(true)
    try {
      await clinicService.editDoctor(clinic, id, formValues)
      history.replace(`${paths.DOCTORS}/${id}/clinic/${clinic}`)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleSwitch = (event) => {
    setAppointmentsSwitch(event.target.checked)
  }

  return (
    <StyledEditDoctor>
      <Loader show={isLoading} />
      <Text as="h1" className="title" size="xxxl" bold>
        {t("pages.doctors.doctors")}
      </Text>
      <div className="name-container">
        <Link to={`${paths.DOCTORS}/${id}/clinic/${clinic}`}>
          <Back />
        </Link>
        <Text bold color="blueHealth" size="xxl">
          {doctor.fullname}
        </Text>
      </div>
      <div className="doctor-information">
        <Box marginBottom={"1.5rem"}>
          <AvatarUpload avatarURL={doctor.avatar_url} isLoading={setIsLoading} setImage={handleSetImage} />
        </Box>

        <Switch
          label={t("pages.doctors.takingAppointments")}
          checked={appointmentsSwitch}
          handleChange={handleSwitch}
          className="appointments-switch"
          disabled
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="edit-doctor">
          <div className="personal-information">
            <Text bold size="xl" as="h4">
              {t("pages.doctors.personalInformation")}
            </Text>
            <Input
              label={`${t("pages.doctors.fullName")}*`}
              name="fullname"
              inputRef={register}
              error={errors.fullname && errors.fullname.message}
              defaultValue={defaultValues.fullname}
              watch={watch}
              disabled
            />
            <Options
              options={genderOptions}
              name="gender"
              control={control}
              className="gender-options"
              defaultValue={defaultValues.gender}
              error={errors.gender && errors.gender.message}
              disabled
            />
            <NationalityField
              control={control}
              name="nationalities"
              error={errors.nationalities && errors.nationalities.message}
              disabled
            />
            <LanguageField control={control} name="languages" error={errors.languages && errors.languages.message} disabled />
            <Input
              label={`${t("pages.doctors.mobile")}*`}
              name="phone"
              inputRef={register}
              error={errors.phone && errors.phone.message}
              defaultValue={defaultValues.phone}
              watch={watch}
              disabled
            />
            <Input
              label={`${t("pages.doctors.email")}*`}
              name="email"
              inputRef={register}
              error={errors.email && errors.email.message}
              defaultValue={defaultValues.email}
              watch={watch}
              disabled
            />
          </div>
          <div className="professional-information">
            <Text bold size="xl" as="h4">
              {t("pages.doctors.professionalInformation")}
            </Text>
            <WorkingDaysField
              formWorkingDays={workingDays}
              setFormWorkingDays={setWorkingDays}
              name="working_time"
              setValue={setValue}
              error={errors.working_time && errors.working_time.message}
            />
            <Input
              label={`${t("pages.doctors.collegeNumber")}*`}
              name="medical_number"
              inputRef={register}
              error={errors.medical_number && errors.medical_number.message}
              defaultValue={defaultValues.medical_number}
              watch={watch}
              disabled
            />
            <Input
              label={`${t("pages.doctors.yearsExperience")}*`}
              name="years_experience"
              inputRef={register}
              error={errors.years_experience && errors.years_experience.message}
              defaultValue={defaultValues.years_experience}
              watch={watch}
              disabled
            />
            <SpecialityField control={control} name="speciality" error={errors.speciality && errors.speciality.message} />
            <ModalityField
              control={control}
              name="preferred_contact"
              error={errors.preferred_contact && errors.preferred_contact.message}
            />
            <ContactField contactPhone={contactData.contactPhone} contactEmail={contactData.contactEmail} setContactData={setContactData} />
          </div>
        </div>
        <Button className="save-button" type="submit">
          {t("pages.doctors.save")}
        </Button>
      </form>
    </StyledEditDoctor>
  )
}
