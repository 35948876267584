import instance from "./Base"
import { errorHandler } from "./utils"

class Review {
  getReview = async (id) => {
    let response
    try {
      response = await instance.get(`/reviews/${id}`)
    } catch (error) {
      errorHandler(error)
    }
    return response?.data
  }
}

export default Review
