import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import InputWrapper from "./Input.style"

export default function Input({ label, disabled = false, error, inputRef, name, defaultValue, watch }) {
  const [isActive, setIsActive] = useState(!!defaultValue)

  const watchValue = watch(name)

  useEffect(() => {
    if (watchValue) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [watchValue])

  return (
    <InputWrapper>
      <input
        disabled={disabled}
        ref={inputRef}
        name={name}
        id={name}
        // value={value}
        //  onChange={(e) => handleTextChange(e.target.value)}
        defaultValue={defaultValue}
        className={isActive ? "value" : ""}
      />
      <label htmlFor={name} className={isActive ? "active" : ""}>
        {label}
      </label>
      {!disabled && error && <span className="error">{error}</span>}
    </InputWrapper>
  )
}

Input.propTypes = {
  /**
   The label / placeholder
   */
  label: PropTypes.string,
  /**
   Set the input disabled state
   */
  disabled: PropTypes.bool,
  /**
   Input error message
   */
  error: PropTypes.string,
  /**
   Pass a ref to the `input` element
   */
  inputRef: PropTypes.func,
  /**
   Name of the form element
   */
  name: PropTypes.string,
}
