import { NavLink } from "react-router-dom"
import styled, { css } from "styled-components"

import { centered } from "../../../styles/utils/mixins"

const disabledStyle = css`
  pointer-events: none;
  cursor: default;
  opacity: 0.2;
`

export const newRequestStyle = css`
  &:before {
    position: absolute;
    top: 2%;
    left: 5%;
    content: "";
    background-image: url("/notification-nav.svg");
    background-size: contain;
    background-position: center;
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
`

const StyledNavItem = styled(NavLink)`
  ${centered("column")}
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.3s;
  position: relative;
  &.active {
    opacity: 1;
    cursor: default;
  }
  &:hover {
    opacity: 1;
  }
  svg {
    fill: ${({ theme }) => theme.colors.white};
    margin: auto;
  }
  ${({ disabled }) => disabled && disabledStyle}
  ${({ newRequest }) => newRequest && newRequestStyle}
`

export default StyledNavItem
