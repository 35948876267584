import styled from "styled-components"

const StyledDoctorCard = styled.div`
  height: 7rem;
  width: 19.5rem;
  box-shadow: 4px 1px 16px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0.75rem 2.5rem 0.75rem;
  .card-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    .doctor-image {
      width: 1.5rem;
      height: 1.5rem;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 1rem;
    }
    .info-text {
      flex: 1;
      .doctor-name {
        margin-bottom: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .average-reviews {
          display: flex;
          svg {
            margin-right: 0.125rem;
          }
        }
      }
      .specialities {
        margin-bottom: 0.25rem;
      }
    }
  }
  .card-body {
    margin-left: 2.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    .date {
      display: flex;
      align-items: center;
    }
    .languages {
      display: flex;
      align-items: center;
    }
    .several-items {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    ul {
      li {
        display: inline;
        &:not(:last-child) {
          margin-right: 3px;
          padding-right: 4px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 3px;
            bottom: 3px;
            width: 1px;
            border-right: 1px solid ${({ theme }) => theme.colors.blueHealth};
          }
        }
      }
    }
    svg {
      width: 12px;
      height: 13.33px;
      fill: ${({ theme }) => theme.colors.blueHealth};
      margin-right: 0.25rem;
    }
  }
`

export default StyledDoctorCard
