import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useHistory, Link } from "react-router-dom"
import qs from "qs"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { useAuth } from "../../providers/UserProvider"
import { ReactComponent as BlueLogo } from "../../resources/svg/logo-blue.svg"
import { Button, Input, PasswordInput, Text, Loader, AvatarUpload } from "../../components/atoms"
import StyledRegisterManager from "./RegisterManager.style"
import paths from "../../utils/routePaths"
import InvitationsService from "../../services/Invitation"

export default function RegisterManager() {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { user, loading } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [manager, setManager] = useState({})
  const [avatar, setAvatar] = useState(null)
  const invitationsService = new InvitationsService()

  const handleSetImage = (imageData) => {
    setAvatar(imageData)
  }

  const schema = yup.object().shape({
    fullname: yup.string().required(t("forms.errors.valueRequired")).min(8, t("forms.errors.doctorNameLength")),
    password: yup.string().required(t("forms.errors.passwordRequired")),
    phone: yup.string().required(t("forms.errors.valueRequired")),
    repeatPassword: yup
      .string()
      .required(t("forms.errors.passwordRequired"))
      .oneOf([yup.ref("password"), null], t("forms.errors.passwordsMatch")),
  })

  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(schema),
  })

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const { token } = query

  useEffect(async () => {
    if (!token) {
      history.push(paths.LOGIN)
    }
    try {
      const response = await invitationsService.getInvitation(token)
      if (response.email) {
        setManager({
          email: response.email,
        })
      } else {
        setManager({
          invalidToken: true,
        })
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!loading && user) {
      history.push("/")
    }
  }, [loading])

  const onSubmit = async (values) => {
    const { fullname, password, phone } = values
    const formValues = {
      fullname,
      password,
      phone,
    }
    if (avatar) {
      formValues.avatar = avatar
    }
    setIsLoading(true)
    try {
      await invitationsService.confirmManager(token, formValues)
      history.push({
        pathname: paths.LOGIN,
        state: {
          confirmed: true,
        },
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <StyledRegisterManager>
      <Loader show={isLoading} />
      <div className="form-container-area">
        <div className="form-container">
          <BlueLogo className="logo" />
          {manager.invalidToken && (
            <>
              <Text uppercase as="h2" size="xxl" bold className="title">
                {t("pages.register.invalidToken")}
              </Text>
              <Link to={paths.LOGIN}>
                <Text as="span" color="blueHealth">
                  {t("pages.register.returnLogin")}
                </Text>
              </Link>
            </>
          )}
          {manager.email && (
            <>
              <Text color="blueHealth" uppercase size="xl" bold className="title">
                {t("pages.register.completeProfile")}
              </Text>
              <div className="image-input">
                <AvatarUpload isLoading={setIsLoading} setImage={handleSetImage} />
              </div>
              <div className="manager-email">{manager.email}</div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  label={`${t("pages.doctors.fullName")}*`}
                  name="fullname"
                  inputRef={register}
                  error={errors.fullname && errors.fullname.message}
                  watch={watch}
                />
                <Input
                  label={`${t("pages.doctors.mobile")}*`}
                  name="phone"
                  inputRef={register}
                  error={errors.phone && errors.phone.message}
                  watch={watch}
                />
                <PasswordInput
                  label={`${t("forms.fields.password")}*`}
                  name="password"
                  inputRef={register}
                  error={errors.password && errors.password.message}
                  watch={watch}
                />
                <PasswordInput
                  label={`${t("forms.fields.repeatPassword")}*`}
                  name="repeatPassword"
                  inputRef={register}
                  error={errors.repeatPassword && errors.repeatPassword.message}
                  watch={watch}
                />
                <Button type="submit">{t("pages.register.login")}</Button>
              </form>
            </>
          )}
        </div>
      </div>
    </StyledRegisterManager>
  )
}
