import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import PasswordInputWrapper from "./PasswordInput.style"
import { ReactComponent as Eye } from "../../../resources/svg/eye.svg"
import { ReactComponent as EyeOff } from "../../../resources/svg/eye-off.svg"

export default function Input({ label, disabled = false, error, inputRef, name, watch }) {
  const [showPassword, setShowPassword] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const watchValue = watch(name)

  useEffect(() => {
    if (watchValue) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [watchValue])

  const handleShowPassword = () => setShowPassword(!showPassword)
  return (
    <PasswordInputWrapper disabled={disabled}>
      <input disabled={disabled} type={showPassword ? "text" : "password"} ref={inputRef} name={name} />
      <label htmlFor={name} className={isActive ? "active" : ""}>
        {label}
      </label>
      <div className="show-password" onClick={handleShowPassword} onKeyPress={handleShowPassword} role="button" tabIndex="-1">
        {showPassword ? <Eye /> : <EyeOff />}
      </div>
      {!disabled && error && <span className="error">{error}</span>}
    </PasswordInputWrapper>
  )
}

Input.propTypes = {
  /**
   The label / placeholder
   */
  label: PropTypes.string,
  /**
   Set the input disabled state
   */
  disabled: PropTypes.bool,
  /**
   Input error message
   */
  error: PropTypes.string,
  /**
   Pass a ref to the `input` element
   */
  inputRef: PropTypes.func,
  /**
   Name of the form element
   */
  name: PropTypes.string,
}
