import styled from "styled-components"

const StyledAgreeModal = styled.div`
  padding: 0.5rem;
  width: 46rem;
  .modal-container {
    margin: auto;
    max-width: 19.5rem;
    text-align: center;
    h2 {
      margin-bottom: 1rem;
    }
    .modal-patient-name {
      margin-bottom: 1.5rem;
    }
    .modal-dates {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 1rem;
      li {
        margin-right: 0.5rem;
      }
    }
    .no-doctors {
      margin-bottom: 2rem;
    }
    .no-dates {
      margin-bottom: 1rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .modal-select {
      margin-bottom: 1rem;
    }
    .add-date {
      margin-bottom: 1rem;
    }
    .unknow-doctor {
      margin-bottom: 1rem;
    }
    .preferred-doctor {
      margin-bottom: 1rem;
    }
    .appointment {
      margin-bottom: 2rem;
    }
  }
`

export default StyledAgreeModal
