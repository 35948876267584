import styled from "styled-components"

const StyledSpecialityField = styled.div`
  position: relative;
  .empty-button {
    height: 2.625rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .error {
    position: absolute;
    text-transform: uppercase;
    bottom: -1.125rem;
    left: 0;
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fonts.xs.size};
  }
`

export const StyledModal = styled.div`
  width: 46rem;
  .modal-content {
    width: 35rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 2.5rem;
    margin-top: 3.5rem;
    transform: translateX(0.5rem);
    h3 {
      text-align: center;
      margin-bottom: 3rem;
    }
    .subtitle {
      margin-bottom: 4rem;
      text-align: center;
    }
    .specialities {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      & > div {
        margin-bottom: 1rem;
        margin-right: 1rem;
      }
    }
    .ok-button {
      width: 21rem;
      margin: auto;
      margin-top: auto;
    }
  }
`

export default StyledSpecialityField
