import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../providers/UserProvider"
import { Loader } from "../components/atoms"
import paths from "./routePaths"

export default function PrivateRoute({ children, ...rest }) {
  const auth = useAuth()
  return !("loading" in auth) || auth.loading ? (
    <Loader fullScreen show background="#FFFFFF" />
  ) : (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: paths.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
