import { rgba } from "polished"
import styled from "styled-components"

const StyledSearchInput = styled.div`
  position: relative;
  input {
    width: 19rem;
    border: none;
    background-color: ${({ theme }) => rgba(theme.colors.blueSky, 0.12)};
    font-family: "Founders Grotesk", sans-serif;
    padding: 11px 1rem;
    border-radius: 3rem;
    font-weight: ${({ theme }) => theme.fonts.light};
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: ${({ theme }) => theme.fonts.m.size};
      color: ${({ theme }) => theme.colors.brey};
    }
  }

  .search-icon {
    pointer-events: none;
    position: absolute;
    left: 17rem;
    top: 50%;
    transform: translateY(-50%);
    fill: ${({ theme }) => theme.colors.brey};
  }
`

export default StyledSearchInput
