import axios from "axios"
import firebase from "./firebase"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
})

instance.interceptors.request.use(
  async (config) => {
    const token = await firebase.auth.currentUser.getIdToken()
    if (token != null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default instance
