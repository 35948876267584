import React from "react"

import StyledNavItem from "./NavItem.style"
import Text from "../Text/Text"

export default function NavItem({ title, icon, to, className, disabled, newRequest }) {
  return (
    <StyledNavItem
      className={className}
      disabled={disabled}
      newRequest={newRequest}
      to={to}
      activeClassName="active"
      onClick={(e) => disabled && e.preventDefault()}
      exact
    >
      {icon}
      <Text color="white" bold>
        {title}
      </Text>
    </StyledNavItem>
  )
}
