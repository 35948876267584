import React from "react"
import { useTranslation } from "react-i18next"

import { formatDateISO, formatDate } from "../../../utils/functions"

export default function DisplayDate({ date: dateObj, long }) {
  const { t, i18n } = useTranslation()

  const today = formatDate(new Date(), i18n.language)

  const tomorrowDate = new Date()
  tomorrowDate.setDate(new Date().getDate() + 1)

  const tomorrow = formatDate(tomorrowDate, i18n.language)

  const displayDate = (date) => {
    const { from, to, time_range: timeRange } = date
    const formattedFrom = formatDateISO(from, i18n.language)
    const formattedTo = formatDateISO(to, i18n.language)
    if (formattedTo === formattedFrom) {
      let singleDate = formattedTo
      if (formattedTo === today) {
        singleDate = t("common.dates.today")
      }
      if (formattedTo === tomorrow) {
        singleDate = t("common.dates.tomorrow")
      }
      return `${singleDate} ${long ? t(`common.dates.${timeRange}`) : ""}`
    }
    return `${formattedFrom} ${t("common.dates.to")} ${formattedTo} ${long ? t(`common.dates.${timeRange}`) : ""}`
  }
  return <span>{displayDate(dateObj)}</span>
}
