import React, { useEffect } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import PrivateRoute from "./utils/PrivateRoute"
import Layout from "./styles/Layout"
import Login from "./pages/Login/Login"
import NotFound from "./pages/NotFound"
import ForgotPassWord from "./pages/ForgotPassword/ForgotPassword"
import Requests from "./pages/Requests/Requests"
import RequestDetail from "./pages/RequestDetail/RequestDetail"
import Doctors from "./pages/Doctors/Doctors"
import DoctorDetail from "./pages/DoctorDetail/DoctorDetail"
import NewDoctor from "./pages/NewDoctor/NewDoctor"
import EditDoctor from "./pages/EditDoctor/EditDoctor"
import RegisterManager from "./pages/RegisterManager/RegisterManager"
import Profile from "./pages/Profile/Profile"
import EditProfile from "./pages/EditProfile/EditProfile"
import Centers from "./pages/Centers/Centers"
import Notifications from "./pages/Notifications/Notifications"
import { Nav } from "./components/molecules"
import paths from "./utils/routePaths"
import { useAuth } from "./providers/UserProvider"
import { onMessage } from "firebase/messaging"
import firebase from "./services/firebase"
import Manager from "./services/Manager"

export default function Router() {
  const { user } = useAuth()

  useEffect(() => {
    const notificationHandler = async () => {
      const fcmToken = await firebase.getToken()

      const managerService = new Manager()
      managerService.addFCMToken(fcmToken)

      // ** Manage notification
      onMessage(firebase.messaging, (message) => {
        const notificationTitle = message?.notification?.title

        const notificationOptions = {
          body: message?.notification?.body,
          icon: "/img/avatar.png",
          data: message?.data,
        }

        const notification = new Notification(notificationTitle, notificationOptions)

        notification.onclick = (event) => {
          event.preventDefault()
          const origin = window.location.origin
          window.location.replace(`${origin}`)
          notification.close()
        }
      })
    }

    if (!user) return
    notificationHandler()
  }, [user])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.FORGOT_PASSWORD} component={ForgotPassWord} />
        <Route exact path={paths.REGISTER_MANAGER} component={RegisterManager} />
        <Route exact>
          <Layout>
            <Nav />
            <Switch>
              <PrivateRoute exact path="/">
                <Redirect to={paths.CENTERS} />
              </PrivateRoute>
              <PrivateRoute exact path={paths.REQUESTS}>
                <Requests />
              </PrivateRoute>
              <PrivateRoute exact path={`${paths.REQUESTS}/:id`}>
                <RequestDetail />
              </PrivateRoute>
              <PrivateRoute exact path={paths.DOCTORS}>
                <Doctors />
              </PrivateRoute>
              <PrivateRoute exact path={paths.NEW_DOCTOR}>
                <NewDoctor />
              </PrivateRoute>
              <PrivateRoute exact path={`${paths.DOCTORS}/:id/clinic/:clinic`}>
                <DoctorDetail />
              </PrivateRoute>
              <PrivateRoute exact path={`${paths.DOCTORS}/:id/clinic/:clinic/edit`}>
                <EditDoctor />
              </PrivateRoute>
              <PrivateRoute exact path={paths.PROFILE}>
                <Profile />
              </PrivateRoute>
              <PrivateRoute exact path={paths.EDIT_PROFILE}>
                <EditProfile />
              </PrivateRoute>
              <PrivateRoute exact path={paths.CENTERS}>
                <Centers />
              </PrivateRoute>
              <PrivateRoute exact path={paths.NOTIFICATIONS}>
                <Notifications />
              </PrivateRoute>
              <PrivateRoute exact>
                <NotFound />
              </PrivateRoute>
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
