import styled from "styled-components"

const StyledCenters = styled.div`
  .top-section {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-end;
    width: 100%;
    .title-search {
      flex: 1;
      .title {
        text-align: center;
        margin-bottom: 1rem;
      }
      .search-input {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
      }
    }
    .add-centers {
      margin-bottom: 2rem;
      margin-right: 1.5rem;
      button {
        padding: 0 0.5rem;
      }
    }
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 63rem;
    margin: auto;
  }
  .skeleton {
    height: 7.5rem;
    width: 19.5rem;
    margin: 0 0.75rem 2.5rem 0.75rem;
    display: block;
    transform: scale(1, 1);
    box-shadow: 4px 1px 16px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
  }
`

export default StyledCenters
