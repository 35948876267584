import React from "react"
import PropTypes from "prop-types"

import StyledText from "./Text.style"

export default function Text({ children, as = "p", size = "m", color = "black", bold = false, uppercase = false, className }) {
  return (
    <StyledText as={as} size={size} color={color} bold={bold} uppercase={uppercase} className={className}>
      {children}
    </StyledText>
  )
}

Text.propTypes = {
  /**
   The text itself
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
  /**
   Set the text as other html element instead of 'p'
   */
  as: PropTypes.string,
  /**
   Set the text size
   */
  size: PropTypes.oneOf(["xxxl", "xxl", "xl", "l", "m", "mRegular", "s", "xs"]),
  /**
   Set the text color
   */
  color: PropTypes.oneOf([
    "blueHealth",
    "white",
    "black",
    "blueSky",
    "brownPerson",
    "blueSkyOpacity",
    "brownPersonOpacity",
    "brey",
    "red",
    "past",
  ]),
  /**
   Set the text bold
   */
  bold: PropTypes.bool,
  /**
  Makes the text uppercase
   */
  uppercase: PropTypes.bool,
}
