import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from "react-router-dom"
import qs from "qs"
import { format, isToday, parseISO } from "date-fns"
import { Text, Loader, Modal, Button } from "../../components/atoms"
import StyledNotifications, { StyledDeleteNotificationModal, StyledConfirmationModal } from "./Notifications.style"
import { useDispatchAuth, useAuth } from "../../providers/UserProvider"
import ClinicService from "../../services/Clinic"
import ReviewService from "../../services/Review"
import paths from "../../utils/routePaths"
import { ReactComponent as EmptyStar } from "../../resources/svg/empty-star.svg"
import { ReactComponent as FullStar } from "../../resources/svg/full-star.svg"

export default function Notifications() {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatchAuth = useDispatchAuth()
  const { selectedClinic } = useAuth()
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const clinicService = new ClinicService()
  const reviewService = new ReviewService()
  const [isLoading, setIsLoading] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(null)

  const handleRouterUpdate = (value) => {
    history.push({
      path: history.location.pathname,
      search: qs.stringify(
        {
          ...query,
          ...value,
        },
        { addQueryPrefix: true }
      ),
    })
  }

  const handleSelectNotification = async (id) => {
    setIsLoading(true)
    try {
      const response = await clinicService.getNotificationDetail(selectedClinic || query.center, id)
      const { type } = response.data || {}
      let appointmentRef = response.data.ref
      let review
      if (type === "review_emitted") {
        review = await reviewService.getReview(response.data.ref)
        appointmentRef = review.appointment_ref
      }

      const appointment = await clinicService.getAppointmentDetail(selectedClinic || query.center, appointmentRef)
      const { patient_ref: patientRef, doctor_ref: doctorRef, selected_date: selectedDate } = appointment || {}
      const { avatar_url: avatar, first_name: firstName, last_name: lastName } = patientRef || {}
      const { fullname: doctor, speciality } = doctorRef || {}
      const { rating, message } = review || {}
      const notificationInfo = {
        id: response.id,
        type: t(`pages.notifications.${type}`),
        date: parseISO(response.createdAt),
        avatar,
        name: firstName && lastName && `${firstName} ${lastName}`,
        title: t(`pages.notifications.${response.data?.type}_title`),
        doctor,
        speciality,
        appointmentDate: selectedDate && parseISO(selectedDate),
        rating,
        message,
      }
      if (type !== "review_emitted") {
        notificationInfo.footer = t("pages.notifications.footer")
      }
      setSelected(notificationInfo)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
    setNotifications(
      notifications.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            new: false,
          }
        }
        return { ...item }
      })
    )
  }

  const handleDeleteButton = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setShowConfirmation(false)
  }

  const handleDeleteNotification = async () => {
    setShowModal(false)
    setIsLoading(true)
    try {
      await clinicService.deleteNotification(selectedClinic || query.center, selected.id)
      const updatedNotifications = [...notifications]
      updatedNotifications.splice(selected.id - 1, 1)
      setNotifications(updatedNotifications)
      setSelected(null)
      setShowConfirmation(true)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(async () => {
    if (selectedClinic) {
      handleRouterUpdate({ center: selectedClinic })
    } else if (query.center) {
      dispatchAuth({
        type: "SELECT_CLINIC",
        payload: query.center,
      })
    } else {
      history.replace(paths.CENTERS)
    }
    setIsLoading(true)
    try {
      const response = await clinicService.getNotifications(selectedClinic || query.center)
      setNotifications(
        response.map((item) => ({
          id: item.id,
          type: t(`pages.notifications.${item.data?.type}`),
          new: !item.read,
        }))
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  return (
    <StyledNotifications>
      <Loader show={isLoading} />
      <Text as="h1" className="title" size="xxxl" bold>
        {t("pages.notifications.notifications")}
      </Text>
      <div className="notifications-container">
        <div className="notification-list">
          {!notifications.length && (
            <Text bold size="l">
              {t("pages.notifications.noNotifications")}
            </Text>
          )}
          {notifications.map((item) => (
            <div
              key={item.id}
              className={`notification-item ${item?.id === selected?.id && "selected"}`}
              onClick={() => handleSelectNotification(item.id)}
              onKeyDown={() => handleSelectNotification(item.id)}
              role="button"
              tabIndex="-1"
            >
              <div className="item-detail">
                <Text size="m" className="name">
                  {t("pages.notifications.notification-centre")}
                </Text>
                <Text size="s" bold color="blueHealth">
                  {item.type}
                </Text>
              </div>
              <div className="dot-container">
                <div className={`item-dot ${item.new && "is-new"}`} />
              </div>
            </div>
          ))}
        </div>
        <div className="notification-detail-container">
          {!selected && (
            <Text size="xl" bold color="blueHealth" className="select-notification">
              {t("pages.notifications.selectNotification")}
            </Text>
          )}
          {selected && (
            <>
              <div className="notification-header">
                <div className="notification-item">
                  {selected.avatar && <img src={selected.avatar} alt="" />}
                  <div className="item-detail">
                    {selected.name && (
                      <Text size="m" className="name">
                        {selected.name}
                      </Text>
                    )}
                    <Text size="s" bold color="blueHealth">
                      {selected.type}
                    </Text>
                  </div>
                  <div className="date-container">
                    <Text size="s">{format(selected.date, isToday(selected.date) ? "HH:mm" : "dd/MM/yyyy 'at' HH:mm")}</Text>
                  </div>
                  <button className="delete-appointment" type="button" onClick={handleDeleteButton}>
                    {t("pages.notifications.delete")}
                  </button>
                </div>
              </div>
              <div className="notification-content">
                {selected.title && (
                  <Text color="blueHealth" bold size="xxxl" as="h2" className="notification-title">
                    {selected.title}
                  </Text>
                )}
                {selected.doctor && (
                  <Text bold size="m">
                    {selected.doctor}
                  </Text>
                )}
                {selected.speciality && <Text>{t(`common.specialities.${selected.speciality}`)}</Text>}
                {selected.appointmentDate && (
                  <Text color="blueHealth" bold size="xl" uppercase className="appointment-date">
                    {format(selected.appointmentDate, "EEEE d, LLL 'at' HH:mm")}
                  </Text>
                )}
                {selected.footer && (
                  <Text size="s" className="notification-footer">
                    {selected.footer}
                  </Text>
                )}
                {selected.rating && (
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map((number, idx) =>
                      number <= selected.rating ? <FullStar key={`${number}-${idx}`} /> : <EmptyStar key={`${number}-${idx}`} />
                    )}
                  </div>
                )}
                {selected.message && (
                  <Text size="m" className="notification-body">
                    {selected.message}
                  </Text>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Modal open={showModal} showClose={false} handleClose={handleCloseModal}>
        <StyledDeleteNotificationModal>
          <Loader show={isLoading} />
          <Text color="blueHealth" bold size="xxxl" as="h2">
            {t("pages.notifications.deleteTitle")}
          </Text>
          <Text color="blueHealth">{t("pages.notifications.deleteText")}</Text>
          <div className="modal-buttons">
            <Button secondary onClick={handleDeleteNotification}>
              {t("common.buttons.yes")}
            </Button>
            <Button onClick={handleCloseModal}>{t("common.buttons.no")}</Button>
          </div>
        </StyledDeleteNotificationModal>
      </Modal>
      {showConfirmation && (
        <Modal open={showConfirmation} handleClose={handleCloseModal} showClose={false} timed>
          <StyledConfirmationModal>
            <Text color="blueHealth" bold size="xxxl" as="h2">
              {t("pages.notifications.deleted")}
            </Text>
          </StyledConfirmationModal>
        </Modal>
      )}
    </StyledNotifications>
  )
}
