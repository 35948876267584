import styled from "styled-components"

const StyledEditDoctor = styled.div`
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 5rem;
  }
  .name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
    a {
      margin-right: 4rem;
      display: flex;
      svg {
        width: 12px;
      }
    }
  }
  .doctor-information {
    width: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 3rem;
    .profile-image {
      width: 8.25rem;
      height: 8.25rem;
      object-fit: cover;
      border-radius: 50%;
      display: block;
      margin: auto;
      margin-bottom: 1.5rem;
    }
    .appointments-switch {
      margin-bottom: 2rem;
    }
  }
  .edit-doctor {
    display: flex;
    justify-content: space-between;
    width: 44rem;
    margin: auto;
    margin-bottom: 2.5rem;
    & > div {
      width: 19.5rem;
    }
    h4 {
      margin-bottom: 1rem;
      text-align: center;
    }
    .gender-options {
      width: 100%;
      .option {
        width: 33.33333%;
        label {
          width: 100%;
          display: block;
        }
      }
    }
  }
  .save-button {
    width: 19.5rem;
    margin: auto;
  }
`

export default StyledEditDoctor
