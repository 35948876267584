import styled from "styled-components"

const StyledWorkingDaysField = styled.div`
  position: relative;
  .empty-button {
    height: 2.625rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .error {
    position: absolute;
    text-transform: uppercase;
    bottom: -1.125rem;
    left: 0;
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fonts.xs.size};
  }
`

export const StyledModal = styled.div`
  width: 46.5rem;
  .modal-content {
    min-height: 31.5rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 2.5rem;
    margin-top: 3.5rem;
    h3 {
      text-align: center;
      margin-bottom: 3rem;
    }
    .subtitle {
      margin-bottom: 1.5rem;
      text-align: center;
    }
    .working-days {
      margin: auto;
      margin-bottom: 4.5rem;
      .weekday {
        display: flex;
        .label-switch {
          display: flex;
          align-items: center;
          margin-bottom: auto;
          margin-top: 0.5rem;
          .day-label {
            width: 4.5rem;
            text-align: right;
          }
          .day-switch {
            margin-left: 2.5rem;
            margin-right: 1rem;
          }
        }
        .time-range {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          .to {
            margin: 0 1rem;
          }
          .delete-container {
            width: 1.5rem;
            .trash-icon {
              cursor: pointer;
              margin-left: 0.75rem;
            }
          }
        }
        .add-hour {
          margin-bottom: 1rem;
        }
        &.active {
          .day-label {
            color: ${({ theme }) => theme.colors.blueHealth};
          }
        }
      }
    }
    .error-message {
      text-align: center;
      margin-bottom: 1rem;
    }
    .ok-button {
      width: 19.5rem;
      margin: auto;
      margin-top: auto;
    }
  }
`

export default StyledWorkingDaysField
