import React, { useState } from "react"

import StyledTimeInput from "./TimeInput.style"
import { getInputMinutes } from "../../../utils/functions"

export default function TimeInput({ defaultValue, name, onChange, disabled, error }) {
  const [value, setValue] = useState(defaultValue)
  const handleOnchange = (event) => {
    const { value: val } = event.target
    setValue(val)
  }

  const toHHMM = (min) => {
    const hoursNum = Math.floor(min / 60)
    const hours = hoursNum > 23 ? 0 : hoursNum
    const minutes = min % 60

    return [hours, minutes].map((val) => (val < 10 ? `0${val}` : val)).join(":")
  }

  const handleOnBlur = (event) => {
    const { value: val } = event.target
    const minutes = Math.max(0, getInputMinutes(val))
    const time = toHHMM(minutes)
    onChange(name, time)
    setValue(time)
  }
  return (
    <StyledTimeInput
      value={value}
      onChange={handleOnchange}
      onBlur={handleOnBlur}
      name={name}
      disabled={disabled}
      className={value && "value"}
      error={error}
    />
  )
}
