import { css } from "styled-components"

const centered = (direction = "row") => css`
  display: flex;
  flex-direction: ${direction};
  justify-content: center;
  align-items: center;
`

const baseInput = () => css`
  position: relative;
  margin-bottom: 1rem;
  margin-top: 2rem;
  input {
    border: 1px solid ${({ theme }) => theme.colors.brey};
    border-radius: 6px;
    font-size: ${({ theme }) => theme.fonts.m.size};
    padding: 0.75rem 1rem;
    width: 100%;
    transition: all 0.3s;
    &.value {
      border-color: ${({ theme }) => theme.colors.blueHealth};
      background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
      outline: none;
    }
    &::placeholder {
      font-family: "Founders Grotesk";
      font-weight: ${({ theme }) => theme.fonts.light};
    }
    &:focus,
    &:hover {
      border-color: ${({ theme }) => theme.colors.blueHealth};
      background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
      outline: none;
    }
    &:disabled {
      opacity: 0.2;
      border: 1px solid ${({ theme }) => theme.colors.brey};
      background-color: transparent;
      & + label {
        opacity: 0.2;
        font-weight: ${({ theme }) => theme.fonts.light};
      }
    }
  }
  label {
    pointer-events: none;
    position: absolute;
    transform: translate(0.75rem, 12px) scale(1);
    left: 0;
    transform-origin: top left;
    transition: all 0.2s ease-out;
    font-weight: ${({ theme }) => theme.fonts.light};
    color: ${({ theme }) => theme.colors.brey};
  }
  &:focus-within label,
  .active {
    transform: translate(0.75rem, -20px) scale(0.66);
    color: ${({ theme }) => theme.colors.blueHealth};
    text-transform: uppercase;
  }
  .error {
    position: absolute;
    text-transform: uppercase;
    bottom: -1.125rem;
    left: 0;
    color: ${({ theme }) => theme.colors.red};
    font-size: ${({ theme }) => theme.fonts.xs.size};
  }
`

export { centered, baseInput }
