import { rgba } from "polished"
import styled, { css } from "styled-components"

import camera from "../../resources/svg/camera.svg"

const disabledTabStyles = css`
  cursor: default;
  &:hover {
    cursor: default;
    opacity: 0.3;
    pointer-events: none;
  }
`

const StyledProfile = styled.div`
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .tabs {
    .tab-list {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 4rem;
      .tab {
        text-transform: uppercase;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.blueHealth};
        font-weight: 500;
        font-size: ${({ theme }) => theme.fonts.xl.size};
        opacity: 0.3;
        transition: all 0.3s;
        &:not(:last-child) {
          margin-right: 2rem;
        }
        &:hover {
          opacity: 1;
        }
        &:focus {
          outline: none;
        }
        &.selected-tab {
          opacity: 1;
          font-size: ${({ theme }) => theme.fonts.xxl.size};
          cursor: default;
        }
        &:nth-child(2) {
          ${({ disableManagers }) => disableManagers && disabledTabStyles}
        }
      }
    }
  }
  .profile-information {
    width: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 3rem;
    .profile-image {
      width: 8.25rem;
      height: 8.25rem;
      border-radius: 50%;
      display: block;
      margin: auto;
      margin-bottom: 2.5rem;
      position: relative;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      &::after {
        transition: all 0.3s;
        content: url(${camera});
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => rgba(theme.colors.brownPerson, 0.7)};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        opacity: 0;
      }
      &:hover {
        &::after {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
    .edit-profile {
      cursor: pointer;
      display: flex;
      align-items: center;
      span {
        margin-left: 0.5rem;
      }
    }
  }
  .profile-data {
    display: flex;
    justify-content: space-between;
    width: 32rem;
    margin: auto;
    & > div {
      width: 12.5rem;
    }
  }
  .managers-screen {
    width: 62rem;
    margin: auto;
    position: relative;
    .add-manager {
      width: 11rem;
      margin: auto;
      margin-bottom: 3rem;
    }
    .managers-list {
      .list-header {
        display: flex;
        margin-right: 4rem;
        & > p {
          flex: 1;
          text-align: center;
        }
        margin-bottom: 3.75rem;
      }
      .list-content {
        .list-item {
          display: flex;
          align-items: center;
          padding: 0.5rem 0;
          border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.blueSky, 0.3)};
          .avatar-container {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors.brownPerson};
            margin-right: 1.5rem;
            img {
              height: 2.5rem;
              width: 2.5rem;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .column {
            flex: 1;
          }
          .select-role {
            .role-selector {
              width: 8.25rem;
            }
          }
          .delete-item {
            display: flex;
            .delete-icon {
              margin-left: auto;
              margin-right: 2rem;
              cursor: pointer;
              opacity: 0;
            }
          }
          &:hover {
            .delete-item {
              .delete-icon {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`

export const StyledDeleteUserModal = styled.div`
  padding: 0.5rem;
  max-width: 20rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    text-align: center;
    max-width: 11rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

export const StyledConfirmationModal = styled.div`
  padding: 0.5rem;
  max-width: 20rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    text-align: center;
    max-width: 11rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

export const StyledInvitationsModal = styled.div`
  width: 46rem;
  padding: 2rem;
  .title {
    text-align: center;
    margin-bottom: 3rem;
  }
  .subtitle {
    text-align: center;
    margin-bottom: 4rem;
  }
  form {
    width: 19.5rem;
    margin: auto;
    .send-button {
      margin-top: 3rem;
    }
  }
`

export default StyledProfile
