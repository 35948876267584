import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { Text, DisplayDate } from "../../atoms"
import { ReactComponent as CalendarIcon } from "../../../resources/svg/calendar.svg"
import { ReactComponent as GlobeIcon } from "../../../resources/svg/globe.svg"
import { ReactComponent as EyeIcon } from "../../../resources/svg/eye.svg"
import { ReactComponent as HomeIcon } from "../../../resources/svg/home.svg"
import { ReactComponent as VideoIcon } from "../../../resources/svg/video.svg"
import noImage from "../../../resources/img/no-image.png"
import { capitalize, getDateAndHour } from "../../../utils/functions"

import StyledRequestCard from "./RequestCard.style"

export default function RequestCard({ status, disabled, onClick, request, clinic, doctors, onDeletePreferred }) {
  const { t } = useTranslation()
  const doctorFullName = request?.appointMentDoctor?.fullname
  const exactDate = request?.selected_date
  const isApproved = status === 2
  const isPast = status === 3
  const {
    _id: id,
    speciality: specialist = "MISSING_SPECIALIST",
    date_range: dates = [],
    preferred_contact: type,
    patient_ref: patient = {},
    availableDates = true,
    preferredDoctor = false,
    languages = [],
    appointMentDoctor,
  } = request

  const { first_name: firstName, last_name: lastName, avatar_url: image } = patient || {}
  const handleButton = (e) => {
    e.preventDefault()
    if (onClick) {
      onClick()
    }
  }

  const handleDeletePreferredButton = (e) => {
    e.preventDefault()
    onDeletePreferred()
  }

  const name = () => (firstName || lastName ? `${firstName} ${lastName}` : "MISSING_NAME")

  const typeIcons = {
    face_to_face: <EyeIcon />,
    home: <HomeIcon />,
    video_call: <VideoIcon />,
  }

  const statuses = {
    0: t("pages.requests.agree"),
    1: t("pages.requests.pendingEdit"),
    2: t("pages.requests.cancel"),
    3: t("pages.requests.delete"),
  }

  const VALID_STATUSES = ["pending", "completed"]

  return (
    <Link
      to={{
        pathname: `/requests/${id}`,
        state: { request, status, clinic, doctors },
      }}
    >
      <StyledRequestCard className={disabled && "disabled"} isPast={isPast}>
        <div className="card-header">
          <div className="patient-info">
            <img src={image || noImage} alt={name()} className="profile-image" />
            <div className="info-text">
              <Text as="h4" bold size="xl" color={isPast ? "past" : undefined}>
                {name()}
              </Text>
              <Text size="m" color={isPast ? "past" : "blueHealth"}>
                {capitalize(specialist)}
              </Text>
              {appointMentDoctor?.active && VALID_STATUSES.includes(request.status) && (
                <Text className="doctor-name" size="s" color={isPast ? "past" : "blueHealth"}>
                  Doctor: {capitalize(doctorFullName)}
                </Text>
              )}
            </div>
          </div>
          <div className="request-type">{typeIcons[type]}</div>
        </div>
        <div className="card-body">
          <div className="request-info">
            <div className="date">
              <CalendarIcon />
              {dates && dates.length === 1 ? (
                <Text size="s" color={isPast ? "past" : "blueHealth"}>
                  {isApproved && exactDate ? <span>{getDateAndHour(new Date(exactDate))}</span> : <DisplayDate date={dates[0]} short />}
                </Text>
              ) : (
                <Text size="s" color={isPast ? "past" : "blueHealth"} className="view-schedules">
                  {t("pages.requests.viewSchedules")}
                </Text>
              )}
            </div>
            {languages.length > 0 && (
              <div className="languages">
                <GlobeIcon />
                <ul>
                  {languages.map((language) => (
                    <Text as="li" key={language} size="s" color={isPast ? "past" : "blueHealth"}>
                      {language}
                    </Text>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {preferredDoctor ? (
            availableDates ? (
              <div className="preferred-buttons">
                <button type="button" className="card-button delete" onClick={handleDeletePreferredButton}>
                  {t("pages.requests.delete")}
                </button>
                <button type="button" className="card-button" onClick={handleButton}>
                  {statuses[status]}
                </button>
              </div>
            ) : (
              <button type="button" className="card-button delete" onClick={handleDeletePreferredButton}>
                {t("pages.requests.delete")}
              </button>
            )
          ) : (
            <button type="button" className="card-button" onClick={handleButton}>
              {statuses[status]}
            </button>
          )}
        </div>
      </StyledRequestCard>
    </Link>
  )
}
