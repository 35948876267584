import React, { useReducer, useContext, createContext, useEffect } from "react"
import ManagerService from "../services/Manager"
import { io } from "socket.io-client"
import firebase from "../services/firebase"

const UserStateContext = createContext()
const UserDispatchContext = createContext()

export default function UserProvider({ children }) {
  const managerService = new ManagerService()

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_USER":
        return {
          ...state,
          user: action.payload,
          loading: false,
        }
      case "SELECT_CLINIC":
        return {
          ...state,
          selectedClinic: action.payload,
          loading: false,
        }
      case "CLEAR_CLINIC":
        return {
          ...state,
          selectedClinic: undefined,
          loading: false,
        }
      case "NEW_REQUEST": {
        const newRequest = state.newRequest || []
        const newCenterAlert = action.payload
        if (!newRequest.includes(newCenterAlert)) {
          newRequest.push(newCenterAlert)
        }
        return {
          ...state,
          newRequest,
          loading: false,
        }
      }
      case "NEW_UPDATED_REQUEST": {
        const updatedRequest = action.payload
        return {
          ...state,
          updatedRequest,
        }
      }
      case "UPDATE_REQUEST": {
        const updatedRequest = action.payload
        return {
          ...state,
          updatedRequest,
        }
      }
      case "CLEAR_NEW_REQUEST": {
        const newRequest = state.newRequest || []

        const newRequestLength = newRequest.length
        const deleteCenter = action.payload

        for (let index = 0; index < newRequestLength; index++) {
          if (deleteCenter === newRequest[index]) {
            newRequest.splice(index, 1)
            break
          }
        }
        return {
          ...state,
          newRequest,
          loading: false,
        }
      }
      case "LOGOUT":
        firebase.auth.signOut()
        return {
          ...state,
          user: null,
        }
      default:
        throw new Error(`Unknown action: ${action.type}`)
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    user: null,
    selectedClinic: undefined,
    newRequest: [],
    loading: true,
  })

  useEffect(async () => {
    let sokectConnect
    await firebase.auth.onAuthStateChanged(async (userAuth) => {
      const token = await userAuth?.getIdToken()
      let userData = null
      if (token) {
        const me = await managerService.me()

        if (me?.role === "owner" || me?.role === "manager") {
          userData = me
        }
        sokectConnect = io(process.env.REACT_APP_SOCKET_URL, {
          reconnectionDelay: 1000,
          extraHeaders: {
            authorization: `Bearer ${token}`,
          },
        })
        if (sokectConnect) {
          sokectConnect.on("connection", () => {})
          sokectConnect.on("new_request", ({ clinic }) => {
            dispatch({
              type: "NEW_REQUEST",
              payload: clinic,
            })
          })
          sokectConnect.on("create_request", ({ request }) => {
            dispatch({
              type: "NEW_UPDATED_REQUEST",
              payload: request,
            })
          })
          sokectConnect.on("update_request", ({ request }) => {
            dispatch({
              type: "UPDATE_REQUEST",
              payload: request,
            })
          })
        }
      }
      dispatch({
        type: "SET_USER",
        payload: userData,
      })
    })
    return () => {
      if (sokectConnect) {
        console.info("unsubcribed socket")
        sokectConnect.removeAllListeners()
      }
    }
  }, [])
  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>
    </UserDispatchContext.Provider>
  )
}

export const useAuth = () => useContext(UserStateContext)
export const useDispatchAuth = () => useContext(UserDispatchContext)
