import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { ReactComponent as CloseIcon } from "../../../resources/svg/clear.svg"
import { StyledDialog, StyledContent } from "./Modal.style"

export default function Modal({ open, handleClose, children, showClose = true, clickOutside = true, timed = false, timeout = 1200 }) {
  useEffect(() => {
    if (timed) {
      setTimeout(() => {
        handleClose()
      }, timeout)
    }
  }, [])

  return (
    <StyledDialog open={open} onClose={clickOutside && handleClose} maxWidth="xl">
      {showClose && <CloseIcon onClick={handleClose} className="close" />}
      <StyledContent>{children}</StyledContent>
    </StyledDialog>
  )
}

Modal.propTypes = {
  /**
   Modal content
   */
  children: PropTypes.any,
  /**
   Opens modal
   */
  open: PropTypes.bool,
  /**
   Sets modal state
   */
  handleClose: PropTypes.func,
  /**
   Show the close icon
   */
  showClose: PropTypes.bool,
  /**
   Allows to close the modal by clicking outside it or pressing ESC
   */
  clickOutside: PropTypes.bool,
}
