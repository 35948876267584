import React from "react"
import PropTypes from "prop-types"

import { Text } from "../../atoms"
import StyledDataField from "./DataField.style"

export default function DataField({ label, data = "---" }) {
  return (
    <StyledDataField>
      <Text uppercase color="blueHealth" bold size="xs" className="field-title">
        {label}
      </Text>
      {Array.isArray(data) ? (
        // eslint-disable-next-line react/no-array-index-key
        data.map((item, idx) => <Text key={`item-${idx}`}>{item}</Text>)
      ) : (
        <Text>{data}</Text>
      )}
    </StyledDataField>
  )
}

DataField.propTypes = {
  /**
   The field label
   */
  label: PropTypes.string.isRequired,
  /**
   The field information
   */
  data: PropTypes.any,
}
