import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useController } from "react-hook-form"
import { findIndex } from "lodash"

import StyledSpecialityField, { StyledModal } from "./SpecialityField.style"
import { Modal, Text, Button, ButtonFormModal, ItemCard } from "../../../components/atoms"
import { ReactComponent as General } from "../../../resources/svg/specialities/general.svg"
import { ReactComponent as Cardiology } from "../../../resources/svg/specialities/cardiology.svg"
import { ReactComponent as Dermatology } from "../../../resources/svg/specialities/dermatology.svg"
import { ReactComponent as Dentist } from "../../../resources/svg/specialities/dentist.svg"
import { ReactComponent as Ophthalmology } from "../../../resources/svg/specialities/ophthalmology.svg"
import { ReactComponent as Pediatrics } from "../../../resources/svg/specialities/pediatrics.svg"
import { ReactComponent as Gynecology } from "../../../resources/svg/specialities/gynecology.svg"
import { ReactComponent as Traumatology } from "../../../resources/svg/specialities/traumatology.svg"
import { ReactComponent as Physiotherapy } from "../../../resources/svg/specialities/physiotherapy.svg"
import { ReactComponent as Urology } from "../../../resources/svg/specialities/urology.svg"
import { ReactComponent as Orthopedist } from "../../../resources/svg/specialities/orthopedist.svg"
import { ReactComponent as Esthetic } from "../../../resources/svg/specialities/esthetic.svg"
import { ReactComponent as Dietetic } from "../../../resources/svg/specialities/dietetic.svg"
import { ReactComponent as Podology } from "../../../resources/svg/specialities/podology.svg"
import { ReactComponent as Radiology } from "../../../resources/svg/specialities/radiology.svg"
import { ReactComponent as Allergology } from "../../../resources/svg/specialities/allergology.svg"
import { ReactComponent as GeneralSurgery } from "../../../resources/svg/specialities/general-surgery.svg"
import { ReactComponent as Endocrinology } from "../../../resources/svg/specialities/endocrinology.svg"
import { ReactComponent as Geriatrics } from "../../../resources/svg/specialities/geriatrics.svg"
import { ReactComponent as Gastroenterology } from "../../../resources/svg/specialities/gastroenterology.svg"
import { ReactComponent as Hematology } from "../../../resources/svg/specialities/hematology.svg"
import { ReactComponent as Homeopathy } from "../../../resources/svg/specialities/homeopathy.svg"
import { ReactComponent as Hypnosis } from "../../../resources/svg/specialities/hypnosis.svg"
import { ReactComponent as InfectiousDisease } from "../../../resources/svg/specialities/infectious-disease.svg"
import { ReactComponent as TravelMedicine } from "../../../resources/svg/specialities/travel-medicine.svg"
import { ReactComponent as AdultMedicalEmergency } from "../../../resources/svg/specialities/adult-medical-emergency.svg"
import { ReactComponent as PediatricMedicalEmergency } from "../../../resources/svg/specialities/pediatric-medical-emergency.svg"
import { ReactComponent as DivingMedicine } from "../../../resources/svg/specialities/diving-medicine.svg"
import { ReactComponent as Infertility } from "../../../resources/svg/specialities/infertility.svg"
import { ReactComponent as SportsMedicine } from "../../../resources/svg/specialities/sports-medicine.svg"
import { ReactComponent as Nephrology } from "../../../resources/svg/specialities/nephrology.svg"
import { ReactComponent as Neurology } from "../../../resources/svg/specialities/neurology.svg"
import { ReactComponent as Oncology } from "../../../resources/svg/specialities/oncology.svg"
import { ReactComponent as Otolaryngology } from "../../../resources/svg/specialities/otolaryngology.svg"
import { ReactComponent as Pulmonology } from "../../../resources/svg/specialities/pulmonology.svg"
import { ReactComponent as Psychiatry } from "../../../resources/svg/specialities/psychiatry.svg"
import { ReactComponent as Rheumatology } from "../../../resources/svg/specialities/rheumatology.svg"

export default function SpecialityField({ control, name, error }) {
  const { t } = useTranslation()
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  })

  const [selected, setSelected] = useState(value)
  const [open, setOpen] = useState(false)

  const specialitiesData = [
    {
      value: "general",
      label: t("common.specialities.general"),
      icon: <General />,
      selected: false,
    },
    {
      value: "cardiology",
      label: t("common.specialities.cardiology"),
      icon: <Cardiology />,
      selected: false,
    },
    {
      value: "dermatology",
      label: t("common.specialities.dermatology"),
      icon: <Dermatology />,
      selected: false,
    },
    {
      value: "dentist",
      label: t("common.specialities.dentist"),
      icon: <Dentist />,
      selected: false,
    },
    {
      value: "ophthalmology",
      label: t("common.specialities.ophthalmology"),
      icon: <Ophthalmology />,
      selected: false,
    },
    {
      value: "pediatrics",
      label: t("common.specialities.pediatrics"),
      icon: <Pediatrics />,
      selected: false,
    },
    {
      value: "gynecology",
      label: t("common.specialities.gynecology"),
      icon: <Gynecology />,
      selected: false,
    },
    {
      value: "traumatology",
      label: t("common.specialities.traumatology"),
      icon: <Traumatology />,
      selected: false,
    },
    {
      value: "physiotherapy",
      label: t("common.specialities.physiotherapy"),
      icon: <Physiotherapy />,
      selected: false,
    },
    {
      value: "urology",
      label: t("common.specialities.urology"),
      icon: <Urology />,
      selected: false,
    },
    {
      value: "orthopedist",
      label: t("common.specialities.orthopedist"),
      icon: <Orthopedist />,
      selected: false,
    },
    {
      value: "esthetic",
      label: t("common.specialities.esthetic"),
      icon: <Esthetic />,
      selected: false,
    },
    {
      value: "dietetic",
      label: t("common.specialities.dietetic"),
      icon: <Dietetic />,
      selected: false,
    },
    {
      value: "podology",
      label: t("common.specialities.podology"),
      icon: <Podology />,
      selected: false,
    },
    {
      value: "radiology",
      label: t("common.specialities.radiology"),
      icon: <Radiology />,
      selected: false,
    },
    {
      value: "allergology",
      label: t("common.specialities.allergology"),
      icon: <Allergology />,
      selected: false,
    },
    {
      value: "general-surgery",
      label: t("common.specialities.general-surgery"),
      icon: <GeneralSurgery />,
      selected: false,
    },
    {
      value: "endocrinology",
      label: t("common.specialities.endocrinology"),
      icon: <Endocrinology />,
      selected: false,
    },
    {
      value: "geriatrics",
      label: t("common.specialities.geriatrics"),
      icon: <Geriatrics />,
      selected: false,
    },
    {
      value: "gastroenterology",
      label: t("common.specialities.gastroenterology"),
      icon: <Gastroenterology />,
      selected: false,
    },
    {
      value: "hematology",
      label: t("common.specialities.hematology"),
      icon: <Hematology />,
      selected: false,
    },
    {
      value: "homeopathy",
      label: t("common.specialities.homeopathy"),
      icon: <Homeopathy />,
      selected: false,
    },
    {
      value: "hypnosis",
      label: t("common.specialities.hypnosis"),
      icon: <Hypnosis />,
      selected: false,
    },
    {
      value: "infectious-disease",
      label: t("common.specialities.infectious-disease"),
      icon: <InfectiousDisease />,
      selected: false,
    },
    {
      value: "travel-medicine",
      label: t("common.specialities.travel-medicine"),
      icon: <TravelMedicine />,
      selected: false,
    },
    {
      value: "adult-medical-emergency",
      label: t("common.specialities.adult-medical-emergency"),
      icon: <AdultMedicalEmergency />,
      selected: false,
    },
    {
      value: "pediatric-medical-emergency",
      label: t("common.specialities.pediatric-medical-emergency"),
      icon: <PediatricMedicalEmergency />,
      selected: false,
    },
    {
      value: "diving-medicine",
      label: t("common.specialities.diving-medicine"),
      icon: <DivingMedicine />,
      selected: false,
    },
    {
      value: "infertility",
      label: t("common.specialities.infertility"),
      icon: <Infertility />,
      selected: false,
    },
    {
      value: "sports-medicine",
      label: t("common.specialities.sports-medicine"),
      icon: <SportsMedicine />,
      selected: false,
    },
    {
      value: "nephrology",
      label: t("common.specialities.nephrology"),
      icon: <Nephrology />,
      selected: false,
    },
    {
      value: "neurology",
      label: t("common.specialities.neurology"),
      icon: <Neurology />,
      selected: false,
    },
    {
      value: "oncology",
      label: t("common.specialities.oncology"),
      icon: <Oncology />,
      selected: false,
    },
    {
      value: "otolaryngology",
      label: t("common.specialities.otolaryngology"),
      icon: <Otolaryngology />,
      selected: false,
    },
    {
      value: "pulmonology",
      label: t("common.specialities.pulmonology"),
      icon: <Pulmonology />,
      selected: false,
    },
    {
      value: "psychiatry",
      label: t("common.specialities.psychiatry"),
      icon: <Psychiatry />,
      selected: false,
    },
    {
      value: "rheumatology",
      label: t("common.specialities.rheumatology"),
      icon: <Rheumatology />,
      selected: false,
    },
  ]

  const [specialities, setSpecialities] = useState(specialitiesData)

  const handleModal = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const onSelect = (val, index) => {
    const newValues = selected ? [...selected] : []
    const newSpecialities = [...specialities]

    const indexOf = findIndex(newValues, (e) => val === e)

    if (indexOf < 0) {
      newValues.push(val)
      newSpecialities[index].selected = true
    } else {
      newValues.splice(indexOf, 1)
      newSpecialities[index].selected = false
    }

    setSpecialities(newSpecialities)
    setSelected(newValues)
    onChange(newValues)
  }

  useEffect(() => {
    if (!selected || selected.length === 0) return

    const newSelected = specialities.map((e) => {
      if (selected.includes(e.value)) {
        e.selected = true
      }
      return e
    })
    setSpecialities(newSelected)
  }, [])

  return (
    <StyledSpecialityField>
      {value && value.length > 0 ? (
        <ButtonFormModal onClick={handleModal}>
          {value.length > 1 ? `${value.length} specialities selected` : t(`common.specialities.${value[0]}`)}
        </ButtonFormModal>
      ) : (
        <Button secondary onClick={handleModal} className="empty-button">
          {t("pages.doctors.speciality")}*
        </Button>
      )}
      {error && <span className="error">{error}</span>}
      <Modal open={open} handleClose={handleClose}>
        <StyledModal>
          <div className="modal-content">
            <Text bold color="blueHealth" size="xxxl" as="h3">
              {t("pages.doctors.speciality")}
            </Text>
            <Text bold size="xl" className="subtitle">
              {t("pages.doctors.chooseSpeciality")}
            </Text>
            <div className="specialities">
              {specialities.map((item, index) => (
                <ItemCard key={item.value} item={item} handleClick={(val) => onSelect(val, index)} isSelected={item.selected} />
              ))}
            </div>
            <Button className="ok-button" onClick={handleClose}>
              {t("common.buttons.ok")}
            </Button>
          </div>
        </StyledModal>
      </Modal>
    </StyledSpecialityField>
  )
}
