import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { ReactComponent as Logo } from "../../../resources/svg/logo-white.svg"
import { ReactComponent as RequestIcon } from "../../../resources/svg/request-nav.svg"
import { ReactComponent as DoctorIcon } from "../../../resources/svg/doctor-nav.svg"
import { ReactComponent as NotificationIcon } from "../../../resources/svg/notification-nav.svg"
import { ReactComponent as LogoutIcon } from "../../../resources/svg/log-out.svg"
import { ReactComponent as NoClinic } from "../../../resources/svg/no-clinic.svg"

import { NavItem, Text, Modal, Button } from "../../atoms"
import StyledNav, { ProfileImage, StyledLogoutModal } from "./Nav.Style"
import { useAuth, useDispatchAuth } from "../../../providers/UserProvider"
import ClinicService from "../../../services/Clinic"
import paths from "../../../utils/routePaths"

export default function Nav() {
  const { user, selectedClinic, newRequest } = useAuth()
  const dispatch = useDispatchAuth()
  const { t } = useTranslation()
  const [clinicInfo, setClinicInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [stateNewRequest, setStateNewRequest] = useState(false)
  const [stateNewRequestAll, setStateNewRequestAll] = useState(false)

  const handleLogoutButton = () => {
    setShowModal(true)
  }

  const handleLogout = () => dispatch({ type: "LOGOUT" })

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(async () => {
    const clinicService = new ClinicService()

    if (selectedClinic) {
      setIsLoading(true)
      const clinicData = await clinicService.getClinic(selectedClinic)
      setClinicInfo({
        image: clinicData.avatar_url,
        name: clinicData.company_name,
      })
      setIsLoading(false)
    } else {
      setClinicInfo({})
    }
  }, [selectedClinic])

  useEffect(() => {
    setStateNewRequest(newRequest.includes(selectedClinic))
  }, [newRequest.length, selectedClinic])

  useEffect(() => {
    setStateNewRequestAll(newRequest.length > 0)
  }, [newRequest.length])

  return (
    <StyledNav newRequest={stateNewRequestAll}>
      <div className="nav-items">
        <Logo className="logo" />
        <NavItem
          title={t("common.nav.requests")}
          icon={<RequestIcon />}
          to={paths.REQUESTS}
          disabled={!selectedClinic}
          newRequest={stateNewRequest}
        />
        <NavItem title={t("common.nav.doctors")} icon={<DoctorIcon />} to={paths.DOCTORS} disabled={!selectedClinic} />
        <NavItem title={t("common.nav.notifications")} icon={<NotificationIcon />} to={paths.NOTIFICATIONS} disabled={!selectedClinic} />
      </div>
      <div className="profile-section">
        <Link to={paths.CENTERS} className="clinics-list">
          {clinicInfo.image ? <Text>the photo</Text> : <NoClinic />}
          <Text as="span" color="blueHealth" bold>
            {isLoading ? t("common.nav.loading") : clinicInfo.name || t("common.nav.selectClinic")}
          </Text>
        </Link>
        <Link to={paths.PROFILE}>
          <div className="profile">
            <ProfileImage image={user?.avatar_url} />
            <Text color="white" bold>
              Hi, {user?.fullname || t("common.nav.user")}!
            </Text>
          </div>
        </Link>
        <LogoutIcon className="logout" onClick={handleLogoutButton} onKeyPress={handleLogoutButton} role="button" tabIndex="-1" />
      </div>
      <Modal open={showModal} showClose={false} handleClose={handleCloseModal}>
        <StyledLogoutModal>
          <Text color="blueHealth" bold size="xxxl" as="h2">
            {t("common.nav.logOut")}
          </Text>
          <Text color="blueHealth">{t("common.nav.logoutMessage")}</Text>
          <div className="modal-buttons">
            <Button secondary onClick={handleLogout}>
              {t("common.buttons.yes")}
            </Button>
            <Button onClick={handleCloseModal}>{t("common.buttons.no")}</Button>
          </div>
        </StyledLogoutModal>
      </Modal>
    </StyledNav>
  )
}
