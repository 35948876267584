import styled from "styled-components"
import { centered } from "../../../styles/utils/mixins"

const StyledButtonFormModal = styled.div`
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: ${({ theme }) => theme.fonts.m.size};
  border-radius: 6px;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
  color: ${({ theme }) => theme.colors.black};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blueHealth};
  font-weight: ${({ theme }) => theme.fonts.regular};
  cursor: pointer;
  padding: 0.75rem 1rem;
  padding-right: 52px;
  width: 100%;
  transition: all 0.3s;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .plus-circle {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 14px;
    ${centered()};
    border-left: 1px solid ${({ theme }) => theme.colors.brey};
    svg {
      fill: ${({ theme }) => theme.colors.blueHealth};
    }
  }
  &:hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.blueSkyOpacity};
  }
  &:disabled {
    cursor: default;
    opacity: 0.2;
    &:hover {
      opacity: 0.2;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blueHealth};
    }
  }
`

export default StyledButtonFormModal
