import styled, { css } from "styled-components"

import { baseInput, centered } from "../../../styles/utils/mixins"

const disabledStyle = css`
  opacity: 0.2;
`

const PasswordInputWrapper = styled.div`
  ${baseInput}
  .show-password {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3rem;
    ${centered}
    border-left: 1px solid ${({ theme }) => theme.colors.text};
    &:focus {
      outline: none;
    }
    ${({ disabled }) => disabled && disabledStyle}
  }
`

export default PasswordInputWrapper
