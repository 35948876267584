import { rgba } from "polished"
import { Calendar } from "react-big-calendar"
import styled, { css } from "styled-components"

const StyledDoctorSchedules = styled.div`
  display: flex;
  margin-bottom: 6rem;
  position: relative;
  .calendar-container {
    width: 20rem;
    margin-right: 1.5rem;
    height: 20.5rem;
  }
  .details-container {
    flex: 1;
    .dates-container {
      background-color: ${({ theme }) => rgba(theme.colors.blueSkyOpacity, 0.26)};
      max-height: 36rem;
      max-width: 40rem;
      border-radius: 0 2.25rem 2.25rem 0;
      overflow: scroll;
      .container-header {
        background-color: ${({ theme }) => rgba(theme.colors.blueSky, 0.26)};
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .header-date {
          text-align: center;
        }
      }
      .container-rows {
        .hour-row {
          height: 6rem;
          display: flex;
          align-items: center;
          overflow: scroll;
          padding: 0 1rem;
          border-bottom: 1px solid ${({ theme }) => theme.colors.blueSky};
        }
      }
    }
  }
`

export const StyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .arrow {
    cursor: pointer;
    svg {
      width: 0.5rem;
      fill: ${({ theme }) => theme.colors.blueHealth};
    }
    &.next {
      svg {
        transform: scaleX(-1);
      }
    }
  }
`

export const StyledDateHeader = styled.span`
  width: 2;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  &.has-event {
    background-color: ${({ theme }) => theme.colors.blueHealth};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`

export const StyledCalendar = styled(Calendar)`
  background-color: ${({ theme }) => rgba(theme.colors.blueSkyOpacity, 0.26)};
  border-radius: 2rem;
  padding: 2rem;
  .rbc-month-view {
    border: none;
    .rbc-month-header {
      margin-bottom: 1rem;
      .rbc-header {
        border: none;
        padding: 0;
        text-align: center;
        span {
          color: ${({ theme }) => rgba(theme.colors.blueHealth, 0.4)};
          font-weight: ${({ theme }) => theme.fonts.light};
          font-size: ${({ theme }) => theme.fonts.s.size};
        }
      }
    }
    .rbc-month-row {
      border: none;
      overflow: visible;
      .rbc-row-bg {
        .rbc-day-bg {
          border: none;
          background-color: transparent;
        }
      }
      .rbc-row-content {
        position: relative;
        .rbc-row {
          .rbc-date-cell {
            font-size: ${({ theme }) => theme.fonts.m.size};
            text-align: left;
            &.rbc-now {
              font-weight: ${({ theme }) => theme.fonts.regular};
            }
          }
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
`

const pastStyles = css`
  opacity: 0.2;
`

const currentStyles = css`
  background-color: ${({ theme }) => theme.colors.blueHealth};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.regular};
`

export const StyledDateItem = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.blueHealth};
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  min-width: 11.5rem;
  font-size: ${({ theme }) => theme.fonts.m.size};
  .speciality {
    margin-left: 1.5rem;
  }
  ${({ isPast }) => isPast && pastStyles};
  ${({ isCurrent }) => isCurrent && currentStyles};
`

export const StyledModal = styled.div`
  width: 46.5rem;
  padding: 0.5rem 2rem 1rem 2rem;
  .title {
    text-align: center;
    margin-bottom: 2.5rem;
  }
  .patient-avatar {
    height: 8.25rem;
    width: 8.25rem;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  .modal-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    h4 {
      margin-bottom: 1.5rem;
    }
    .personal-information,
    .necessity {
      flex: 1;
    }
    .notify-delay {
      width: 7.5rem;
      .delay-type {
        border: 1px solid ${({ theme }) => theme.colors.brey};
        color: ${({ theme }) => theme.colors.brey};
        margin-bottom: 1rem;
        border-radius: 6px;
        padding: 0.75rem;
        padding-left: 2.5rem;
        cursor: pointer;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0.75rem;
          top: 50%;
          transform: translateY(-50%);
          width: 1rem;
          height: 1rem;
          border: 1px solid ${({ theme }) => theme.colors.brey};
          border-radius: 50%;
        }
        &.selected {
          border: 1px solid ${({ theme }) => theme.colors.blueHealth};
          color: ${({ theme }) => theme.colors.blueHealth};
          font-weight: ${({ theme }) => theme.fonts.regular};
          &:before {
            border: 1px solid ${({ theme }) => theme.colors.blueHealth};
            background-color: ${({ theme }) => theme.colors.blueHealth};
          }
        }
        &:focus {
          outline: none;
        }
      }
      .notify-button {
        margin-top: 2rem;
      }
    }
  }
  .cancel-date {
    margin: auto;
    width: 19.5rem;
  }
`

export default StyledDoctorSchedules
