import styled from "styled-components"

const StyledDataField = styled.div`
  margin-bottom: 1.5rem;
  .field-title {
    margin-bottom: 0.5rem;
  }
`

export default StyledDataField
