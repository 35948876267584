import { pxToRem } from "./utils/functions"

const theme = {
  colors: {
    blueHealth: "#0058FF",
    white: "#FFFFFF",
    black: "#000000",
    blueSky: "#92C0E9",
    brownPerson: "#EFC2B3",
    blueSkyOpacity: "#D4E2EE",
    brownPersonOpacity: "#F7E0D9",
    brey: "#6F767C",
    red: "#F32735",
    decline: "#EE817B",
    past: "#CCCCCC",
  },
  fonts: {
    xxxl: {
      size: pxToRem(35),
      line: "110%",
    },
    xxl: {
      size: pxToRem(25),
      line: "116%",
    },
    xl: {
      size: pxToRem(20),
      line: "116%",
    },
    l: {
      size: pxToRem(18),
      line: "116%",
    },
    m: {
      size: pxToRem(15),
      line: "116%",
    },
    mRegular: {
      size: pxToRem(14),
      line: "116%",
    },
    s: {
      size: pxToRem(12),
      line: "116%",
    },
    xs: {
      size: pxToRem(10),
      line: "116%",
    },
    light: 300,
    regular: 500,
  },
}

export default theme
