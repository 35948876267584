import { rgba } from "polished"
import styled from "styled-components"
import loginBackground from "../../resources/img/login-bg.jpg"
import { centered } from "../../styles/utils/mixins"

const StyledLogin = styled.div`
  display: flex;
  min-height: 100vh;
  position: relative;
  .circular-text {
    width: 12rem;
    position: absolute;
    left: 50%;
    top: 2.5rem;
    transform: translateX(-50%);
  }
  .login-form-area {
    width: 50%;
    ${centered("column")}
    .login-form-container {
      width: 100%;
      max-width: 19.5rem;
      margin-top: 7.75rem;
      .form-title {
        margin-left: 1rem;
        margin-bottom: 1.5rem;
        .user-options {
          margin-top: 2rem;
          span:first-child {
            margin-right: 2rem;
          }
          .disabled {
            opacity: 0.3;
            font-size: ${({ theme }) => theme.fonts.xl.size};
          }
        }
      }
      form {
        position: relative;
        button {
          margin-top: 2.5rem;
        }
        .forgot-password {
          margin-top: 0.5rem;
          text-align: center;
        }
      }
    }
  }
  .login-image {
    width: 50%;
    ${centered}
    background: linear-gradient(
        ${({ theme }) => rgba(theme.colors.blueHealth, 0.6)},
        ${({ theme }) => rgba(theme.colors.blueHealth, 0.6)}
      ),
      url(${loginBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .slogan {
      max-width: 21rem;
      text-align: center;
    }
  }
`

export const StyledConfirmationModal = styled.div`
  padding: 0.5rem;
  max-width: 20rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    text-align: center;
    max-width: 11rem;
    margin: auto;
    margin-bottom: 2.5rem;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }
`

export default StyledLogin
