import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

import FoundersLight from "../resources/fonts/FoundersGrotesk-Light.otf"
import FoundersMedium from "../resources/fonts/FoundersGrotesk-Medium.otf"

const GlobalStyle = createGlobalStyle`
 ${normalize}
 @font-face {
    font-family: "Founders Grotesk";
    src: url('${FoundersMedium}');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Founders Grotesk";
    src: url('${FoundersLight}');
    font-weight: 300;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  html {
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Founders Grotesk', sans-serif;
    font-weight: ${({ theme }) => theme.fonts.light};
    line-height: ${({ theme }) => theme.fonts.m.line}
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
    }
  }

  h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-weight: ${({ theme }) => theme.fonts.medium};
  }

  h1 {
    font-size: ${({ theme }) => theme.fonts.xxxl.size};
    line-height: ${({ theme }) => theme.fonts.xxxl.line}
  }

  h2 {
    font-size: ${({ theme }) => theme.fonts.xxl.size};
    line-height: ${({ theme }) => theme.fonts.xxl.line}
  }

  h3 {
    font-size: ${({ theme }) => theme.fonts.xxl.size};
    line-height: ${({ theme }) => theme.fonts.xxl.line}
  }

  h4 {
    font-size: ${({ theme }) => theme.fonts.xxl.size};
    line-height: ${({ theme }) => theme.fonts.xxl.line}
  }

  p {
    margin: 0;
  }

  a{
    color: inherit;
    text-decoration: inherit;
  }

  button{
    all: unset
  }

`

export default GlobalStyle
