import styled from "styled-components"

const Layout = styled.div`
  height: 100vh;
  display: flex;
  & > div:nth-child(2) {
    flex: 1;
    padding: 4rem;
    overflow-y: auto;
  }
`

export default Layout
