import styled from "styled-components"
import { centered } from "../../styles/utils/mixins"

const StyledRequestDetail = styled.div`
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 5rem;
  }
  .name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
    a {
      margin-right: 4rem;
      display: flex;
      svg {
        width: 12px;
      }
    }
  }
  .profile-image {
    width: 8.25rem;
    height: 8.25rem;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  .request-buttons {
    width: 9.25rem;
    margin: auto;
    margin-bottom: 2.5rem;
    display: flex;
    &.preferred {
      width: 19.5rem;
      .delete {
        margin-right: 1rem;
      }
    }
  }
  .appointment-dates {
    margin-bottom: 2.5rem;
    p {
      text-align: center;
    }
  }
  .request-data {
    display: flex;
    justify-content: space-between;
    width: 32rem;
    margin: auto;
    & > div {
      width: 12.5rem;
    }
    h4 {
      margin-bottom: 1rem;
    }
  }
  .card-button {
    display: block;
    width: 100%;
    height: calc(2.5rem - 2px);
    border: 1px solid ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
    border-radius: 6px;
    ${centered}
    color: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
    font-size: ${({ theme }) => theme.fonts.l.size};
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
      color: ${({ theme }) => theme.colors.white};
    }
    &.delete {
      border: 1px solid ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.red)};
      color: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.red)};
      &:hover {
        background-color: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.red)};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

export default StyledRequestDetail
