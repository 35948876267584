import styled from "styled-components"

const StyledText = styled.p`
  font-size: ${({ theme, size }) => (theme.fonts[size] ? theme.fonts[size].size : theme.fonts.m.size)};
  line-height: ${({ theme, size }) => (theme.fonts[size] ? theme.fonts[size].line : theme.fonts.m.line)};
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.black};
  font-weight: ${({ theme, bold }) => (bold ? theme.fonts.regular : theme.fonts.light)};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
`

export default StyledText
