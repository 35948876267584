import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useAuth } from "../../providers/UserProvider"
import { Button, Input, Text, Loader, Modal } from "../../components/atoms"
import { ReactComponent as BlueLogo } from "../../resources/svg/logo-blue.svg"
import paths from "../../utils/routePaths"
import StyledForgotPassword, { StyledPasswordModal } from "./ForgotPassword.style"
import firebase from "../../services/firebase"

export default function ForgotPassWord() {
  const { t } = useTranslation()
  const history = useHistory()
  const { user, loading } = useAuth()
  const [isLoading, setLoading] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)
  const [errorModal, setErrorModal] = useState(undefined)
  const { register, handleSubmit, errors, watch } = useForm()

  useEffect(() => {
    if (!loading && user) {
      history.push("/")
    }
  }, [loading])

  const onSubmit = async (data) => {
    setLoading(true)
    const { email } = data
    firebase.auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setPasswordModal(true)
      })
      .catch((error) => {
        const { code } = error
        if (code === "auth/user-not-found") {
          setErrorModal(t("pages.forgotPassword.userNotFound"))
        } else {
          setErrorModal(t("pages.forgotPassword.errorMessage"))
        }
      })
    setLoading(false)
  }

  const handleAcceptModal = () => {
    setPasswordModal(false)
    history.push(paths.LOGIN)
  }

  const handleErrorModal = () => {
    setErrorModal(undefined)
  }

  return (
    <StyledForgotPassword>
      <div className="back-link-container">
        <Link to={paths.LOGIN}>
          <Text uppercase size="s" className="back-link">
            {t("pages.forgotPassword.back")}
          </Text>
        </Link>
      </div>
      <div className="form-container">
        <BlueLogo />
        <Text bold size="xxl" as="h2">
          {t("pages.forgotPassword.forgotPassword")}
        </Text>
        <Text size="m">{t("pages.forgotPassword.writeEmail")}</Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={t("forms.fields.email")}
            name="email"
            inputRef={register({
              required: t("forms.errors.emailRequired"),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t("forms.errors.emailInvalid"),
              },
            })}
            watch={watch}
            error={errors.email && errors.email.message}
          />
          <Button type="submit">{t("pages.forgotPassword.send")}</Button>
          <Loader show={isLoading} />
        </form>
      </div>
      <Modal open={passwordModal} showClose={false}>
        <StyledPasswordModal>
          <Text color="blueHealth" className="modal-message">
            {t("pages.forgotPassword.emailSent")}
          </Text>
          <Button onClick={handleAcceptModal}>{t("pages.forgotPassword.ok")}</Button>
        </StyledPasswordModal>
      </Modal>
      <Modal open={!!errorModal} showClose={false}>
        <StyledPasswordModal>
          <Text color="blueHealth" className="modal-message">
            {errorModal || ""}
          </Text>
          <Button onClick={handleErrorModal}>{t("pages.forgotPassword.ok")}</Button>
        </StyledPasswordModal>
      </Modal>
    </StyledForgotPassword>
  )
}
