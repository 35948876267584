import styled from "styled-components"

const StyledEditProfile = styled.div`
  .top-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    h1 {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: -5rem;
        width: 12px;
        transform: translateY(-50%);
      }
    }
  }
  .user-information {
    width: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 3rem;
  }
  .edit-profile {
    display: flex;
    justify-content: space-between;
    width: 44rem;
    margin: auto;
    margin-bottom: 2.5rem;
    & > div {
      width: 19.5rem;
    }
  }
  .save-button {
    width: 19.5rem;
    margin: auto;
  }
`

export default StyledEditProfile
