import React from "react"
import PropTypes from "prop-types"

import StyledButton from "./Button.style"

export default function Button({ children, onClick, disabled, secondary, className, type = "button" }) {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      secondary={secondary}
      className={className}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  /**
   The button text
   */
  children: PropTypes.string.isRequired,
  /**
   Set the button disabled
   */
  disabled: PropTypes.bool,
  /**
   Button secondary style
   */
  secondary: PropTypes.bool,
}
