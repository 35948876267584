import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import StyledWorkingDaysField, { StyledModal } from "./WorkingDaysField.style"
import { Modal, Text, Button, AddButton, Switch, ButtonFormModal, TimeInput } from "../../../components/atoms"
import { ReactComponent as Trash } from "../../../resources/svg/trash.svg"
import { weekdays, getInputMinutes } from "../../../utils/functions"

export default function WorkingDaysField({ formWorkingDays = {}, setFormWorkingDays, name, setValue, error }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [activeDays, setActiveDays] = useState({})
  const [workingDays, setWorkingDays] = useState({})
  const [hasError, setHasError] = useState(false)
  const handleModal = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }
  const handleAcceptButton = () => {
    if (!hasError) {
      const updatedWeek = {}
      weekdays.forEach((day) => {
        if (activeDays[day]) {
          updatedWeek[day] = workingDays[day].filter((item) => item.to && item.from)
        } else {
          updatedWeek[day] = []
        }
      })
      setFormWorkingDays({ ...updatedWeek })
      setValue(name, { ...updatedWeek })
      setOpen(false)
    }
  }
  const handleTimeChange = (key, value) => {
    let errorFlag = false
    const [day, i, time] = key.split(".")
    const updatedWeek = { ...workingDays }
    const currentDay = updatedWeek[day]
    const range = { ...updatedWeek[day][Number(i)], error: false }
    range[time] = value
    const fromMinutes = getInputMinutes(range.from)
    const toMinutes = getInputMinutes(range.to)
    if (fromMinutes >= toMinutes) {
      errorFlag = "fromAfterTo"
      range.error = "fromAfterTo"
    } else {
      currentDay.forEach((item, idx) => {
        const fromItemMinutes = getInputMinutes(item.from)
        const toItemMinutes = getInputMinutes(item.to)
        if (idx !== Number(i)) {
          if (
            (fromMinutes >= fromItemMinutes && fromMinutes <= toItemMinutes) ||
            (toMinutes >= fromItemMinutes && toMinutes <= toItemMinutes)
          ) {
            errorFlag = "timeInRange"
            range.error = "timeInRange"
          }
        }
      })
    }
    updatedWeek[day][Number(i)] = range
    setWorkingDays(updatedWeek)
    setHasError(errorFlag)
  }

  const handleAddHour = (day) => {
    const updatedWeek = { ...workingDays }
    const updatedDay = [...workingDays[day], { from: "", to: "" }]
    updatedWeek[day] = updatedDay
    setWorkingDays(updatedWeek)
  }

  const handleRemoveHour = (day, i) => {
    const updatedWeek = { ...workingDays }
    const updatedDay = workingDays[day].filter((_item, idx) => i !== idx)
    updatedWeek[day] = updatedDay
    setWorkingDays(updatedWeek)
  }

  const handleDaySwitch = (e) => {
    const { name: day, checked } = e.target
    const newActiveDays = { ...activeDays }
    newActiveDays[day] = checked
    if (workingDays[day]?.length === 0) {
      const updatedWeek = { ...workingDays }
      const updatedDay = [...workingDays[day], { from: "", to: "" }]
      updatedWeek[day] = updatedDay
      setWorkingDays(updatedWeek)
    }
    setActiveDays(newActiveDays)
  }

  const buttonLabel = () => {
    const daysList = []
    weekdays.forEach((day) => {
      formWorkingDays[day]?.forEach((item) => {
        daysList.push(`${t(`common.weekdays.${day}`)} ${item.from} ${t("pages.doctors.to")} ${item.to}`)
      })
    })
    return daysList && daysList.join(", ")
  }

  useEffect(() => {
    if (open) {
      setWorkingDays({ ...formWorkingDays })
      const days = {}
      weekdays.forEach((day) => {
        if (formWorkingDays[day]?.length > 0) {
          days[day] = true
        }
      })
      setActiveDays({ ...days })
    }
  }, [open])

  return (
    <StyledWorkingDaysField>
      {!buttonLabel() ? (
        <Button secondary onClick={handleModal} className="empty-button">
          {t("pages.doctors.workingTime")}*
        </Button>
      ) : (
        <ButtonFormModal onClick={handleModal}>{buttonLabel()}</ButtonFormModal>
      )}
      {error && <span className="error">{error}</span>}
      <Modal open={open} handleClose={handleClose}>
        <StyledModal>
          <div className="modal-content">
            <Text bold color="blueHealth" size="xxxl" as="h3">
              {t("pages.doctors.workingTime")}
            </Text>
            <Text bold size="xl" className="subtitle">
              {t("pages.doctors.chooseWorkingTime")}
            </Text>
            <div className="working-days">
              {weekdays.map((day, index) => (
                <div key={`${day}-${index}`} className={`weekday ${activeDays[day] && "active"}`}>
                  <div className="label-switch">
                    <Text className="day-label" color="brey">
                      {t(`common.weekdays.${day}`)}
                    </Text>
                    <div className="day-switch">
                      <Switch checked={activeDays[day]} handleChange={handleDaySwitch} name={day} />
                    </div>
                  </div>
                  {workingDays[day]?.length > 0 ? (
                    <div className="ranges-list">
                      {workingDays[day].map((range, idx) => (
                        <div className="time-range" key={`${day}-${idx}`}>
                          <TimeInput
                            defaultValue={range.from}
                            onChange={handleTimeChange}
                            name={`${day}.${idx}.from`}
                            disabled={!activeDays[day]}
                            error={range.error}
                          />
                          <Text className="to" bold>
                            {t("pages.doctors.to")}
                          </Text>
                          <TimeInput
                            defaultValue={range.to}
                            onChange={handleTimeChange}
                            name={`${day}.${idx}.to`}
                            disabled={!activeDays[day]}
                            error={range.error}
                          />
                          <div className="delete-container">
                            {activeDays[day] && idx > 0 && range.from && range.to && (
                              <Trash className="trash-icon" onClick={() => handleRemoveHour(day, idx)} />
                            )}
                          </div>
                        </div>
                      ))}
                      {activeDays[day] &&
                        workingDays[day][workingDays[day].length - 1]?.from &&
                        workingDays[day][workingDays[day].length - 1]?.to && (
                          <AddButton label={t("pages.doctors.addHour")} onClick={() => handleAddHour(day)} className="add-hour" />
                        )}
                    </div>
                  ) : (
                    <div className="time-range">
                      <TimeInput defaultValue="00:00" disabled />
                      <Text className="to" bold>
                        {t("pages.doctors.to")}
                      </Text>
                      <TimeInput defaultValue="00:00" disabled />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {hasError === "fromAfterTo" && (
              <Text color="red" className="error-message">
                {t("pages.doctors.fromAfterTo")}
              </Text>
            )}
            {hasError === "timeInRange" && (
              <Text color="red" className="error-message">
                {t("pages.doctors.timeInRange")}
              </Text>
            )}
            <Button className="ok-button" onClick={handleAcceptButton} disabled={!!hasError}>
              {t("common.buttons.ok")}
            </Button>
          </div>
        </StyledModal>
      </Modal>
    </StyledWorkingDaysField>
  )
}
