import styled from "styled-components"
import { centered } from "../../../styles/utils/mixins"

const StyledRequestCard = styled.div`
  height: 9.75rem;
  width: 19.5rem;
  box-shadow: 4px 1px 16px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0.75rem 2.5rem 0.75rem;
  .card-header {
    display: flex;
    justify-content: space-between;
    .patient-info {
      display: flex;
      align-items: center;
      h4 {
        margin-bottom: 0.25rem;
      }
      .profile-image {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 1rem;
        opacity: ${({ isPast }) => (isPast ? "0.4" : "1")};
      }
      .doctor-name {
        margin-top: 2px;
      }
    }
    .request-type {
      svg {
        fill: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
      }
    }
  }
  .card-body {
    .request-info {
      margin-left: 2.5rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      .date {
        display: flex;
        align-items: center;
        .view-schedules {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .languages {
        display: flex;
        align-items: center;
        ul {
          li {
            display: inline;
            &:not(:last-child) {
              margin-right: 3px;
              padding-right: 4px;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 3px;
                bottom: 3px;
                width: 1px;
                border-right: 1px solid ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
              }
            }
          }
        }
      }
      svg {
        width: 12px;
        height: 13.33px;
        fill: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
        margin-right: 0.25rem;
      }
    }
    .preferred-buttons {
      display: flex;
      .delete {
        margin-right: 1rem;
      }
    }
    .card-button {
      display: block;
      width: 100%;
      height: calc(2.5rem - 2px);
      border: 1px solid ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
      border-radius: 6px;
      ${centered}
      color: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
      font-size: ${({ theme }) => theme.fonts.l.size};
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background-color: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.blueHealth)};
        color: ${({ theme }) => theme.colors.white};
      }
      &.delete {
        border: 1px solid ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.red)};
        color: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.red)};
        &:hover {
          background-color: ${({ theme, isPast }) => (isPast ? theme.colors.past : theme.colors.red)};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
  &.disabled {
    pointer-events: none;
    .card-header {
      .patient-info {
        .info-text {
          opacity: 0.5;
        }
      }
      .request-type {
        opacity: 0.5;
      }
    }
    .card-body {
      opacity: 0.5;
    }
  }
`

export default StyledRequestCard
