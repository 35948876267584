import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { centered } from "../styles/utils/mixins"

const StyledNotfound = styled.div`
  ${centered("column")};
  height: 100vh;
  p {
    font-size: ${({ theme }) => theme.fonts.xxxl.size};
    margin-bottom: 2rem;
  }
`

export default function NotFound() {
  const { t } = useTranslation()
  return (
    <StyledNotfound>
      <p>404</p>
      <h1>{t("pages.notFound")}</h1>
    </StyledNotfound>
  )
}
