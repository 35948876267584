import React from "react"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"

import StyledOptions from "./Options.style"

export default function Options({ options, name, defaultValue = null, control, rules, disabled, className, error }) {
  const handleChange = (onChange, event) => {
    onChange(event, event.target.value)
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange }) => (
        <StyledOptions className={`${className} ${disabled && "disabled"}`}>
          {options.map((option) => (
            <div key={option.value} className="option">
              <input
                type="radio"
                name={name}
                id={`${name}${option.value}`}
                onChange={(e) => handleChange(onChange, e)}
                value={option.value}
                defaultChecked={option.value === defaultValue}
                disabled={disabled}
              />
              <label htmlFor={`${name}${option.value}`}>
                <span>{option.label}</span>
              </label>
            </div>
          ))}
          {error && <span className="error">{error}</span>}
        </StyledOptions>
      )}
    />
  )
}

Options.propTypes = {
  /**
   Options to display
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
}
