import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import StyledEditProfile from "./EditProfile.style"
import { Text, Button, Loader, Input, PasswordInput, AvatarUpload } from "../../components/atoms"
import { ReactComponent as Back } from "../../resources/svg/back.svg"
import paths from "../../utils/routePaths"
import { useAuth, useDispatchAuth } from "../../providers/UserProvider"
import ManagerService from "../../services/Manager"

export default function EditProfile() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const dispatchAuth = useDispatchAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)
  const managerService = new ManagerService()
  const history = useHistory()

  const handleSetImage = (imageData) => {
    setImage(imageData)
  }

  const schema = yup.object().shape({
    fullname: yup.string().required(t("forms.errors.valueRequired")),
    phone: yup.string().required(t("forms.errors.valueRequired")),
    email: yup.string().required(t("forms.errors.valueRequired")).email(t("forms.errors.emailInvalid")),
    password: yup.string(),
  })

  const { handleSubmit, errors, register, watch } = useForm({
    shouldUnregister: false,
    user,
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values) => {
    const formValues = {
      fullname: values.fullname,
      phone: values.phone,
      email: values.email,
    }

    if (image) {
      formValues.avatar = image
    }

    if (values.password) {
      formValues.password = values.password
    }
    setIsLoading(true)
    try {
      const response = await managerService.editProfile(formValues)
      dispatchAuth({
        type: "SET_USER",
        payload: response,
      })
      history.replace(paths.PROFILE)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <StyledEditProfile>
      <Loader show={isLoading} />
      <div className="top-section">
        <Text as="h1" className="title" size="xxxl" bold>
          <Link to={paths.PROFILE}>
            <Back />
          </Link>
          {t("pages.profile.profile")}
        </Text>
      </div>
      <div className="user-information">
        <AvatarUpload avatarURL={user.avatar_url} isLoading={setIsLoading} setImage={handleSetImage} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="edit-profile">
          <div>
            <Input
              label={`${t("pages.doctors.fullName")}*`}
              name="fullname"
              inputRef={register}
              error={errors.fullname && errors.fullname.message}
              defaultValue={user.fullname}
              watch={watch}
            />
            <Input
              label={`${t("pages.doctors.mobile")}*`}
              name="phone"
              inputRef={register}
              error={errors.phone && errors.phone.message}
              defaultValue={user.phone}
              watch={watch}
            />
          </div>
          <div>
            <Input
              label={`${t("pages.doctors.email")}*`}
              name="email"
              inputRef={register}
              error={errors.email && errors.email.message}
              defaultValue={user.email}
              watch={watch}
            />
            <PasswordInput
              label={`${t("forms.fields.password")}*`}
              name="password"
              inputRef={register}
              error={errors.password && errors.password.message}
              watch={watch}
            />
          </div>
        </div>
        <Button className="save-button" type="submit">
          {t("common.buttons.save")}
        </Button>
      </form>
    </StyledEditProfile>
  )
}
